<template>
  <div id="home">
    <div class="searchArea">
      <div class="searchBar">
        <SearchBar/>
      </div>
    </div>
    <div class="mainContent">
      <span class="textTitle">
        {{$t('index.tagline')}}
      </span>
      <p>
        {{$t('index.slogan')}}
        <!-- 你不懂的，我們都有。 -->
        <!-- 透過甘珠爾、丹珠爾及其他藏傳傑作的數位化，我們建立了佛法搜尋引擎供大眾使用 -->
      </p>
      <br/><hr/><br/>
      <div class="appIntro">
        <div class="appLogo">
        <br/><br/>
        <img src="@/../public/img/logo/samb-icon-app.png"/>
        </div>
        <div class="appLinks">
          <a href="https://apps.apple.com/tw/app/%E6%A1%91%E6%B3%8A%E8%A9%9E%E5%85%B8%E6%90%9C%E5%B0%8B/id1588355945" target="_blank">
            iOS App {{$t('index.download')}}
          </a>
          <a href="https://play.google.com/store/apps/details?id=org.dharma_treasure.sambhota" target="_blank">
            Android App {{$t('index.download')}}
          </a>
          <a href="https://files.dharma-treasure.org/dict/download/sambhota-dic.apk">
            apk {{$t('index.download')}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'Home',
  components: {
    SearchBar,
  },
};
</script>

<style lang="scss" scoped>
  #home {
    // background-image: url("./../assets/img/picture/thom.png");
    // background-size: 40%;
    // background-position-y: bottom;
    // background-position-x: right;
    // background-repeat: no-repeat;
    min-height: 100vh;
    @media screen and (max-width: $mobileLandscape) {
      background-size: 180%;
      background-position-x: left;
    }
    .searchArea {
      min-height: 20em;
      display: flex;
      justify-content: center;
      background-image: url("./../assets/img/searchBarBG02.png");
      background-size: 100%;
      background-position: 0px 40px;
      background-repeat: no-repeat;
      background-color: $searchBarColor;
      @media screen and (max-width: $mobileLandscape) {
        background-image: url("./../assets/img/searchBarBG01Mobile.png");
        min-height: auto;
        padding: calc( 2.5 * #{$contentWrap}) 0px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px;
        background-size: cover;
        background-position: 0px 0px;
      }
      .searchBar {
        flex: 1;
        align-self: center;
        max-width: 70%;
        @media screen and (max-width: $mobileLandscape) {
          max-width: 90%;
        }
      }
    }
    .mainContent {
      margin: calc(#{$margin * 2});
      text-align: center;
      padding: $contentWrap;
      min-height: 16em;
      @media screen and (max-width: $mobileLandscape) {
        margin: $contentWrap;
      }
      .textTitle {
        font-weight: bolder;
      }
      .appIntro {
        display: flex;
        justify-content: center;
        @media screen and (max-width: $mobileLandscape) {
          flex-direction: column;
        }
        .appLogo {
          flex: 1;
          text-align: right;
          img {
            max-width: 200px;
          }
          @media screen and (max-width: $mobileLandscape) {
            text-align: center;
          }
        }
        .appLinks {
          flex: 1.2;
          text-align: left;
          display: flex;
          flex-direction: row;
          justify-content: left;
          align-self: center;
          @media screen and (max-width: $mobileLandscape) {
            justify-content: center;
            flex-direction: column;
          }
          a {
            font-size: 1.3em;
            padding-left: 1.5em;
            text-decoration: underline;
            @media screen and (max-width: $mobileLandscape) {
              padding-top: 1.5em;
              text-align: center;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
</style>
