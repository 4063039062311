export default {
  header: {
    websiteName: '桑泊詞典搜尋網站',
  },
  footer: {
    eMail: '電子郵件：support@dharma-treasure.org',
    tel: '電話：+886-2-27586828',
    address: '地址：111046 台北市士林區德行西路七號二樓之2B室',
    copyRight: 'Copyright © 2021 Dharma Treasure Corp. All rights reserved.',
    webTreasure: '正法寶藏官網',
    webAdarsha: '明鏡',
    webToolBox: '澄水寶珠',
    webEBook: '正法電子書',
    webSupport: '支援網站',
    nowOnlineNumber: '目前在線人數',
    visitedNumber: '網站總瀏覽人次',
  },
  menu: {
    menu: '選單',
    language: '語系',
    category: '詞條分類',
    alphabet: '字母排序',
    advSearch: '進階搜尋',
    about: '關於',
    login: '登入',
    logout: '登出',
    theme: '主題',
    support: '支援',
  },
  button: {
    preLevelPage: '上一層',
    prePage: '上一頁',
    nextPage: '下一頁',
  },
  lang: {
    language: '語系',
    bo: '藏文',
    en: '英文',
    'zh-TW': '繁中',
    'zh-CN': '簡中',
    sa: '梵文',
  },
  index: {
    index: '首頁',
    theme: '主題',
    tagline: '藏典明義',
    slogan: '桑泊詞典搜尋網站之名稱取自藏文之創始人「圖彌·桑泊札」以彰其功。由《正法寶藏藏經數位團隊》以現代網路科技整合具有參考價值之古今藏文詞典、藏漢詞典、漢藏詞典等二十餘萬詞條及解釋，並進行詞條翻譯及釋義分類，為學習佛典及從事佛學研究工作者提供一個優質的參考工具。',
    download: '下載',
    bgBlack: '黑底',
    bgWhite: '白底',
  },
  dictionary: {
    dictionary: '詞典',
    selectDic: '選擇詞典',
    selectDicRange: '選擇詞典',
    dicTypeA: '藏文詞典',
    dicTypeB: '藏漢詞典',
    dicTypeC: '漢藏詞典',
    dicTypeD: '藏英詞典',
    dicTypeE: '漢文詞典',
  },
  category: {
    category: '分類',
    normaVocabulary: '一般詞',
    civilization: '文明',
    terminology: '專業術語',
    religion: '宗教',
    miscellaneous: '雜',
    noThisCategory: '無此分類，請確認您輸入的關鍵字',
    selectCatRange: '選擇分類範圍',
    nowCatRange: '目前選取範圍',
    allCategory: '全部分類',
    noRelativeCat: '無相關類別',

  },
  alphabet: {
    alphabet: '字母排序',
    bo: '藏文 - ཀ་ཁ་ག་ང་',
    en: '英文 - ABCD',
    zhTW: '繁中 - ㄅㄆㄇㄈ',
    zhCN: '簡中 - abcd',
    tibetan: '藏文',
    boAlphabet: 'ཀ་ཁ་ག་ང་ཅ་ཆ་ཇ་ཉ',
    english: '英文',
    enAlphabet: 'ABCDEFGH',
    traditionalChinese: '繁體中文',
    zhTWAlphabet: 'ㄅㄆㄇㄈㄉㄊㄋㄌ',
    simpleChinese: '簡體中文',
    zhCNAlphabet: 'abcdefgh',
    noResult: '無相關結果',
    plzChangeAlphabet: '請至其他字母查詢，或點選右上角「選擇詞典」更換您的搜尋範圍',
  },
  search: {
    search: '搜尋',
    keywordPls: '請輸入關鍵字',
    catRange: '選擇分類',
    selectCatRange: '選擇分類範圍',
    keyword: '關鍵字',
  },
  advancedSearch: {
    advancedSearch: '進階搜尋',
    noResult: '無相關搜尋結果',
    result: '搜尋結果',
    goForSearch: '進行搜尋',
    keyword: '關鍵字',
  },
  about: {
    mainTitle: '《桑泊詞典》搜尋網站',
    mainIntro: '桑泊詞典搜尋藉著現代網路科技整合現有已出版之藏文詞典、藏漢詞典、漢藏詞典等二十餘萬詞條及解釋，並進行詞條翻譯及釋義分類，以為未來噶舉大百科的編撰奠立基礎。',
    area01Title: '現階段我們提供已完成數位化的詞典有三本：',
    area01Content: '',
    area02Title: '《藏漢大詞典》',
    area02Content: '於1984年由張怡蓀主編，中國民族出版社發行。收錄藏語詞彙約五萬三千餘條，是近代使用較廣的藏漢詞典。',
    area03Title: '《翻譯名義集》',
    area03Content: '原典來自藏文丹珠爾中的典藉《བྱེ་བྲག་ཏུ་རྟོགས་པར་བྱེད་པ།》（漢意：分別通達）。原典編纂自西元九世紀，是過去翻譯佛經的主要參考書。到了十九世紀中葉傳到西方，某些學者對原書的詞彙加譯漢文翻譯，直到上世紀1950年代由日本學者荻原雲來與榊亮三郞編輯印刷成書。',
    area04Title: '《御製五體清文鑑》',
    area04Content: '這本典藉編於十八世紀，清乾隆年間，這是一部滿、藏、蒙、維、漢五種文字的分類詞典。收詞約一萬八千餘條，具有歷史與實用價值。於1956年根據故宮重華宮的珍藏抄本重新勘印而成。',
    dicsIntro: '本網站爲了搜尋方便，列出每個藏語詞條的漢文翻，並列於網頁詞條下方，方便使用者閱讀查詢。',
    dicRangeA: '藏/藏詞典類',
    bookNameA01: '《古藏文詞典》',
    introA01: '古藏文詞典，藏語，編者：贊拉·阿旺措成，中國北京民族出版社1992年出版，本詞典收錄近八千古藏文用詞，具有參考及研究的價值。',
    bookNameA02: '《藏語新舊字辨異‧丁香寶帳》',
    introA02: '十五世紀時西藏譯師覺頓蒙珠著。書中收有新舊詞語一千多條，皆摘自藏文字形釐訂前的部分佛書。',
    bookNameA03: '《東噶藏學大辭典》',
    introA03: '本詞典主要收錄了以下幾個方面的藏學詞彙：<br/>1)西藏歷史人物與歷史事件<br/>2)西藏歷代中央王朝與西藏地方政府之間的關係<br/>3)西藏古代法律<br/>4)西藏地方政府機構及專用公文<br/>5)西藏ེ各地名勝古蹟和重點寺廟;關於藏族民俗與宗教<br/>6)關於藏學基礎知識等方面的詞條。<br/>編選詞彙豐富、內容解釋詳實、分類科學合理、便於檢索使用，是一部較全 面的藏學百科性質的辭書。',
    bookNameA04: '《貢迪藏學大辭典》',
    introA04: '貢迪．拉仁巴編著<br/>色拉傑大知寶藏出版社於2016年出版<br/>色拉傑格西圖滇桑竹是位貢迪拉仁巴，1958年生於西元藏，1959年抵達印度。<br/>1972年開始在色拉寺學習佛學經論，1993年獲得拉仁巴學位，隨後至上密院學習密續教理。畢業之後回到色拉寺擔任敎師，這時也開始整理教法形成本部詞典的基礎，最終於2016年完成本部詞典的編撰。',
    bookNameA05: '《數稱辭釋義大全》',
    introA05: '諾章·吳堅編撰，中國藏學出版社2008年出版，本詞典收編三藏法數名相共一萬餘條，並列出出處，內容浩瀚，是佛學研究的好幫手。',
    bookNameA06: '《丹珠爾_翻譯名義集梵藏對照詞彙集》',
    introA06: '這部梵藏對照詞彙集是依據德格版丹珠爾D4348整理而成，這部文獻建立於公元九世紀赤松德贊時期，為規範梵藏佛經翻譯而作，後收錄於西藏丹珠爾中。因現今坊間出版的藏梵英中對照詞典歷經多方重編修訂，已經與原典出入甚多，今由正法寶藏桑泊字典編輯組依據原經文重新對比校正後，放置於桑泊詞典搜尋網站，期待提供更完整的研究參考。',
    bookNameA07: '《藏文大辭典》',
    introA07: '主編：昂仁羅桑丹達。<br/>編輯：索南巴尊<br/>出版日期：2010<br/>出版社：謝利文明出版社',
    dicRangeB: '藏/漢詞典類',
    bookNameB01: '《藏漢大詞典》',
    introB01: '藏語/漢語，於1984年由張怡蓀主編，中國民族出版社發行。收錄藏語詞彙約五萬三千餘條，是近代使用較廣的藏漢詞典。本網站爲了搜尋方便，列出每個藏語詞條的漢文翻，並列於網頁詞條下方，方便使用者閱讀查詢。',
    bookNameB02: '《翻譯名義集》（藏文版）',
    introB02: '藏語/梵語/英語/漢語，原典來自藏文丹珠爾中的典藉《བྱེ་བྲག་ཏུ་རྟོགས་པར་བྱེད་པ།》（漢意：分別通達）。原典編纂自西元九世紀，是過去翻譯佛經的主要參考書。到了十九世紀中葉傳到西方，某些學者對原書的詞彙加譯漢文翻譯，直到上世紀1950年代由日本學者荻原雲來與榊亮三郞編輯印刷成書。',
    bookNameB03: '《藏漢常用合稱詞典》',
    introB03: '藏語/漢語，賀文宣、竇存琦編，青海民族出版社1987年出版。這是一部釋譯藏文典籍中所有專詞的藏漢對照工具書。全書收錄了古典藏文著作中包括政治、經濟、文化、教育、文學、藝術、語言、文字、歷史、宗教、醫學、天文學等在內的詞條共2113條。',
    bookNameB04: '《佛學詞典》',
    introB04: '藏語/漢語，王沂暖主編，青海民族出版社出版，詞條取自「藏漢大詞典」、「青史」、「紅史」、「土官宗教源流鏡史」、「賢者喜筵」、「格西曲札藏文詞典」、「印度佛教史」、「菩提道次第」、「密宗道次第」、「瑜珈師地論、「集論」及「俱舍論」等論典中匯集而成，約一萬二千個詞條。本網站爲了搜尋方便，列出每個藏語詞條的漢文翻，並列於網頁詞條下方，方便使用者閱讀查詢。',
    bookNameB05: '《藏漢佛學詞典》',
    introB05: '本詞典由四川民族出版社於1992年由圖登尼瑪先生編撰而成。爲藏漢佛學術語的對照詞典。',
    bookNameB06: '《藏漢詞典》',
    introB06: '本詞典由西北民族學院藏文教研組於1979年由甘肅民族出版社出版。全書共計25400餘詞。',
    bookNameB07: '《梵藏漢對照詞典》',
    introB07: '本詞典編著為：安世興先生，由民族出版社於1991年出版，主要依據「朵喀-次仁旺傑」所編的『常用藏梵詞典—神奇寶珠項鍊』一書編撰而成。本詞典的梵文主詞條是以”藏文轉寫梵文對音詞”的書寫方式並對照各詞條的藏文義譯與漢文義譯。共收錄詞條一萬九千餘。是一部提供藏學工作者學習、翻譯和研究古藏文典籍的工具書。',
    bookNameB08: '《藏漢集論詞彙》',
    introB08: '西陲文化院印<br/>張煦著<br/>張煦先生為二十世紀初人物（藏學家）,他將毘婆沙門集論（梵語：Mahāyānābhidharma-samuccaya）的藏文本與玄奘法師的漢譯本對勘，以單字一義、數字一義及一句或數句者一義之藏文詞條對比漢譯本相應之詞彙編而成，全書共列有4830餘詞。',
    bookNameB09: '《藏漢曆算學詞典》',
    introB09: '責任編輯：湯池安、央青拉姆。<br/>出版日期：1985<br/>出版社：四川民族出版社<br/>本書是閱讀藏文曆算典籍的輔助工具，其目的在於介紹藏曆的傳統知識，而不在於系統地介紹現代天文學知識。例句完全出自藏文文獻的原句。凡與現代天文學在原理、概念 上相同或近似，只是名詞術語不同之處，在漢文譯文中簡單 地註明現代天文學形設，放在括弧裡，不與傳統的解釋相混。原理概念不同之處本書不做評論。<br/>藏文註解主要是桑珠攝措寫的，由黃明信補充並譯為漢文，現代天文學的解釋是陳久金撰寫的。遺漏與錯誤一定不少,敬請讀者指正。',
    dicRangeC: '漢藏詞典類',
    bookNameC01: '《御製五體清文鑑》',
    introC01: '這本典藉編於十八世紀，清乾隆年間，這是一部滿、藏、蒙、維、漢五種文字的分類詞典。收詞約一萬八千餘條，具有歷史與實用價值。於1956年根據故宮重華宮的珍藏抄本重新勘印而成。',
    bookNameC02: '《漢藏對照詞典》',
    introC02: '本詞典收詞八萬餘條，以現代漢語爲主，詞條除一般詞語外，還收有一些常用的文言詞語、成語、諺語、名言警句、方言詞以及各學科的常用詞彙。上述詞語，相當一部分出自藏文的翻譯出版物，譯文具有一定的實踐基礎和群眾基礎。',
    bookNameC03: '《漢藏對照佛學詞典》',
    introC03: '《漢藏對照佛學詞典》由甘肅民族出版社出版，由馬學仁先生主編，本詞典詞條主要從《具捨論》、《菩提道次第廣論》、《青史》、《紅史》、《巴協》、《土觀宗教源流鏡史》、《藏漢對照丹珠 爾佛學分類詞典》、《藏傳佛畫度量經》、《甘青藏傳佛教寺 院》、《藏漢大詞典》、《格西曲扎藏文辭典》以及其他歷史文 獻、寺志和佛典等漢藏文資料中摘選出來。內容包括佛典、 教派、教法、教義、佛、菩薩、諸天、鬼神、高僧、儀軌、制度、節日、古蹟、寺院等。由於藏傳佛教吸收了不少原始苯教的內 容,因而本詞典同時還收錄了少量的苯教及相關宗教的基本詞彙，共計二萬三千餘條。',
    dicRangeD: '藏英詞典類',
    bookNameD01: 'The Combined English Dictionary',
    introD01: '這本詞典是以藏漢大詞典詞條爲基礎，引用由Rangjung Yeshe, Richard Barron, Ives Waldo等人的解釋並加上桑泊詞典編輯小組對部分詞條的解釋彙編而成。',
    bookNameD02: 'Tibetan-English Dictionary of Buddhist Terminology',
    introD02: '這本詞典是Tsepak Rigzin基於翻譯名義大集編寫而成，其包含了6,000個主詞條及超過8,000個子詞條。並在1986年由西藏檔案文獻館出版。',
    bookNameD03: '《英藏漢對照詞典》',
    introD03: '甘肅民族出版社於2005年首次出版《英藏漢對照詞典》。該詞典包含了將近4,000個詞條，其主編爲Yeshi Lamutso。',
    bookNameD04: '《藏語敬語 藏漢英對照手冊》',
    introD04: '由多袞桑達多吉與丹津拉宗編撰，由西藏人民出版社出版。本詞典收錄了藏語常用口語及敬語的對照詞彙並且加上英、漢語翻譯以供使用者參考及應用。',
    dicRangeE: '漢文詞典類',
    bookNameE01: '《丁福保佛學大詞典》',
    introE01: '《丁福保佛學大詞典》，是丁福保居士耗時八年編著而成的佛學詞典，譯自日本人織田德能的《佛學大詞典》，是刪除了日本佛教相關資料及文獻出處之後所成的節譯本。於1922年正式出版，收錄條目三萬多個，360多萬字。',
    bookNameE02: '《一切經音義》',
    introE02: '又稱《慧琳音義》，唐朝惠霖法師編撰，共100卷。是諸多佛經的音義集大成之作，由於釋慧琳精通印度語言和漢語、音韻、訓詁，故在爲翻譯佛經辨析字形、審定字音、解釋字義時均備有詳細引據。其可貴之處是所引用的書籍極爲豐富，音韻以元庭堅的《韻英》、《韻詮》、張戩的《考聲切韻》爲準則，字義以《說文》、《爾雅》、《方言》、《釋名》、《玉篇》、《文字集略》、《集訓》、《古今正字》、《字統》、《文詞典說》爲準則，保存了《開元文字音義》等最古的傳世詞書，不少更是亡佚的古籍。無論從學術上還是從史料上看，都是非常有價值的寶藏。',
    bookNameE03: '《翻譯名義集》(宋版)',
    introE03: '佛教詞書，南宋平江（治所在今江蘇蘇州）景德寺僧法雲編。7卷，64篇。系將散見於各經論中的梵文名字分類解釋、編集而成，另有別行本在《閱藏知津》中作14卷，明代藏經作20卷。該書共收音譯梵文2040餘條。',
    bookNameE04: '《三藏法數》',
    introE04: '共五十卷。明代一如奉敕編纂。略稱大明法數、三藏法數。本書係輯自大藏經典中諸法數之名詞，依增數法類編撰而成。名目起自「一心」，終於「八萬四千法門」計收詞條一五五五條，每條並附有略解，凡出於某經某論或某某撰述者，皆詳注於各條之下；若遇各家或各宗派說法不同，則徵引諸家之異說，內容極爲詳備。民初黃理齋據該書另編通檢一冊，將一五五五條中所引之各類名詞，一一析出，令初學者方便檢閱。',
    bookNameE05: '慧琳《一切經音義》',
    introE05: '唐代比丘慧琳所著《一切經音義》（A.D. 807）是玄應所著《一切經音義》（A.D.649）的增補本。<br/>本書整合彙編了唐朝當時的所有經文詞彙的音韻及意義，是「漢梵雙語詞典的原型」。<br/>此書也是佛經音義訓詁的集成。',
    thanks: '感謝',
    thanksContent1: '《桑泊詞典搜尋網站》在多年的努力下，承蒙各方的護持贊助，也包括編撰組義工們的文字輸入、整理及校對，還有數位工程師們對詞典編輯工具與網頁的開發製作。現在免費提供給大眾使用，在此致上誠敬的感謝！',
    thanksContent2: '',
    thanksContent3: '正法寳藏辦公室  敬上',
  },
  login: {
    sign: '登入',
    register: '註冊',
    account: '帳號',
    name: '名字',
    member: '會員',
    email: '電子郵件地址',
    password: '密碼',
    confirmPsw: '確認密碼',
  },
  result: {
    noResult: '無相關結果，請回上頁或重新查詢',
    noInputResult: '無相關結果，請重新輸入關鍵字',
    orTryOtherWord: '或者您可試試以下其他詞條',
    result: '搜尋結果',
    explanation: '解釋',
    bookPage: '原書頁碼',
    pageNoF: '第',
    pageNoL: '頁',
    entryNoF: '第',
    entryNoL: '個詞條',
    partOfSpeech: '詞性',
    expCategory: '類別',
    expSource: '解釋來源',
    ps: '註解',
    combination: '組合字',
    word: '一般字',
    variant: '異體字',
    pinyin: '拼音',
    phonetic: '注音',
    note: '備註',
    originalImg: '原書圖像',
  },
  pos: {
    pos1Aid: '成語',
    pos1Ano: '名詞',
    pos1Ave: '動詞',
    pos1Aad: '形容詞',
    pos1Aadv: '副詞',
    pos1Apr: '介詞',
    pos1Aco: '連接詞',
    pos1Apro: '代詞',
    pos1Asy: '異詞',
    pos1Aho: '敬語',
    pos1Asl: '俚語',
    pos1Adi: '方言',
    pos1Aques: '疑問句',
    pos1Aab: '縮寫',
  },
  elements: {
    enterPage: '輸入頁碼',
    comfirm: '確定',
    totalNumber: '總筆數',
    mainEntry: '主詞條',
    transEntry: '關聯搜尋',
    reset: '重設',
    copysuccess: '複製成功!',
    copyfailed: '無法複製內容，瀏覽器不支援。',
  },
  errorReport: {
    feedback: '問題回報',
    problem: '詞條內容問題',
    unshownZh: '有些中文無法顯示？',
    otherProblem: '其他問題',
    name: '姓名',
    contactWay: '聯絡方式',
    submitProblem: '請在此輸入您想回報的問題',
    send: '傳送',
    fixUnshownZh1: '如果您的網頁無法顯示罕見字，可',
    fixUnshownZh2: '點選此連結',
    fixUnshownZh3: '安裝字體。',
    returnForm: '回到問題列表',
  },
  donate: {
    donateUs: '功德林',
    introTitle: '「桑泊佛學詞典」數位化工作',
    introContent: '桑泊佛學電子詞典：歷時六年與化育漢藏佛學院義工，匯整20多部古今中外，藏、中、英佛學詞典，經過分類及整理，其中有多部稀有珍貴的古籍詞典(日後仍會陸續增加)，法友們可以在古今字詞解析中，相互比較、對照、進而瞭解。<br/><br/>祈：法友在閱讀經典時，遇到艱深字詞與佛學名相，能夠方便的隨時隨地搜尋查閱，進而瞭解佛經義理。<br/><br/>願：大眾閱讀佛經時，深入經藏，得到佛的加持與利益。<br/><br/>～願以此功德，普及於一切，我等與眾生，皆共成佛道。',
    imgPS: '圖爲: 吞彌·桑布扎 大師,藏文的主要創制者,曾翻譯許多佛經..',
    creditPay: '線上護持',
    aliPay: '支付寶護持',
    paypalPay: 'paypal護持',
    taiwanCurrency: '台幣護持',
    foreignCurrency: '外幣護持',
  },
  supportPage: {
    questions: '常見問題',
  },
};
