<template>
  <div id="logout">
    <h1>This is a logout page</h1>
  </div>
</template>

<script>
export default {
  name: 'Logout',
};
</script>
