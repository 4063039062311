<template>
  <div id="cagoryRange" class="el-tree-select" :class="selectClass">
    <!-- <div class="determine">
      <el-button @click="determineBtn()">確定</el-button>
      <el-button @click="cancelBtn()">取消</el-button>
    </div> -->
    <div class="title">
      <span>{{$t('search.selectCatRange')}}</span>
      <i class="el-icon-refresh" @click="handleReset()"></i>
    </div>
    <div class="itemArea">
      <div class="options">
      <div class="title">{{$t('category.nowCatRange')}}：</div>
      <div class="optionArea" v-if="!labels.length">
        <span>{{$t('category.allCategory')}}</span>
      </div>
      <div class="optionArea" v-if="labels.length">
        <div v-for="(labels, index) in labels" :key="index">
          <el-button
            @click="selectRemoveTag(labels.path)"
          >
            {{labels.name}}
            <i class="el-icon-error"></i>
          </el-button>
        </div>
      </div>
      </div>

      <div class="treeArea">
        <div class="searchArea">
          <el-input
            v-if="treeParams.filterable"
            v-model="keywords"
            size="mini"
            class="input-with-select mb10"
            @change="searchFun"
          >
            <i
              v-if="keywords !== ''"
              slot="suffix"
              @click="handleClear"
              class="el-input__icon el-icon-circle-close el-input__clear"
            >
            </i>
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <el-tree
          node-key="path"
          accordion
          :empty-text="$t('category.noThisCategory')"
          v-if="data"
          class="filter-tree"
          :data="data"
          ref="tree"
          highlight-current
          :draggable="false"
          :current-node-key="propsValue"
          show-checkbox
          :filter-node-method="filterFun"
          @check="treeCheckFun"
          updateKeyChildren
        >
        <span slot-scope="{data}">
          {{data.name}}
        </span>
      </el-tree>
        <div v-if="data.length === 0" class="no-data">{{$t('category.noRelativeCat')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
// import { each } from '../utils/utils';
export default {
  name: 'ElTreeSelect',
  props: {
    value: {
      type: [String, Array, Number],
      default() {
        return '';
      },
    },
    styles: {
      type: Object,
      default() {
        return {};
      },
    },
    selectClass: {
      type: String,
      default() {
        return '';
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    placement: {
      type: String,
      default() {
        return 'bottom';
      },
    },
    treeRenderFun: Function,
    selectParams: {
      type: Object,
      default() {
        return {
          clearable: true,
          disabled: false,
          placeholder: '請選擇',
        };
      },
    },
    treeParams: {
      type: Object,
      default() {
        return {
          clickParent: false,
          filterable: false,
          accordion: true,
          data: [],
          props: {
          },
        };
      },
    },
  },
  data() {
    return {
      freshIt: 0,
      treeUpdateKey: 0,
      propsValue: 'flowId',
      propsLabel: 'name',
      propsCode: null,
      propsDisabled: 'disabled',
      propsChildren: 'children',
      keywords: '',
      labels: '',
      clickFlag: 'L',
      ids: [],
      selIds: [],
      visible: true,
    };
  },
  computed: {
    data: {
      get() {
        return this.$store.state.category.arrCatList;
      },
      set() {
      },
    },
  },
  watch: {
    ids(val) {
      if (val !== undefined) {
        this.$nextTick(() => {
          this.setSelectNodeFun(val);
        });
      }
      this.$store.dispatch('category/setNowCatLabels', val);
      this.$store.dispatch('category/setNowCatRange', val);
    },
    keywords(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    const { props, data } = this.treeParams;
    this.setMultipleFun();
    this.propsValue = props.value;
    this.propsLabel = props.label;
    this.propsSelPath = props.path;
    this.propsCode = props.code || null;
    this.propsDisabled = props.disabled;
    this.propsChildren = props.children;
    this.data = data.length > 0 ? [...data] : [];
    if (this.selectParams.multiple) {
      this.labels = this.$store.state.category.nowCatLabels;
      this.ids = this.$store.state.category.nowCatLabels;
    } else {
      this.labels = '';
      this.ids = this.value instanceof Array ? this.value : [this.value];
    }
  },
  methods: {
    determineBtn() {
      this.$store.dispatch('category/setNowCatLabels', this.ids);
      this.$store.dispatch('category/setNowCatRange', this.ids);
      this.$emit('closeThisCatRange');
    },
    cancelBtn() {
      this.labels = this.$store.state.category.nowCatLabels;
      this.ids = this.$store.state.category.nowCatLabels;
    },
    handleReset() {
      const array = {
        checkedNodes: Array(0),
        checkedKeys: Array(0),
        halfCheckedNodes: Array(0),
        halfCheckedKeys: Array(0),
      };
      this.treeCheckFun(null, array, null);
    },
    handleClear() {
      this.keywords = '';
      this.treeUpdateKey += 1;
    },
    setMultipleFun() {
      let multiple = false;
      if (this.value instanceof Array) {
        multiple = true;
      }
      this.$set(this.selectParams, 'multiple', multiple);
    },
    searchFun() {
      this.$refs.tree.setCheckedKeys(this.ids);
      this.$emit('searchFun', this.keywords);
    },
    setSelectNodeFun(ids) {
      const el = this.$refs.tree;
      if (!el) {
        throw new Error('找不到tree dom');
      }

      const { multiple } = this.selectParams;
      if (ids.length === 0 || this.data.length === 0) {
        this.labels = multiple ? [] : '';
        if (multiple) {
          el.setCheckedKeys([]);
        } else {
          el.setCurrentKey(null);
        }
        return;
      }
      if (multiple) {
        el.getCheckedNodes().forEach((item) => {
          el.setChecked(item, false);
        });
        ids.forEach((id) => {
          el.setChecked(id, true);
        });

        const nodes = el.getCheckedNodes();
        if (this.propsCode) {
          this.labels = nodes.map((item) => (
            item[this.propsCode]
              ? `${item[this.propsValue]}(${item[this.propsCode]})`
              : item[this.propsLabel]
          )) || [];
        } else {
          this.labels = nodes.map((item) => {
            const newItem = {
              path: item.path,
              name: item.name,
            };
            return newItem;
          }) || [];
          this.selPath = nodes.map((item) => item[this.propsValue]) || [];
        }
      } else {
        el.setCurrentKey(ids[0]);
        const node = el.getCurrentNode();
        if (node) {
          if (this.propsCode) {
            this.labels = node[this.propsCode]
              ? `${node[this.propsValue]}(${node[this.propsCode]})`
              : node[this.propsValue];
          } else {
            this.labels = node[this.propsValue];
          }
        } else {
          this.labels = '';
        }
      }
    },
    getEventPath(evt) {
      const path = (evt.composedPath && evt.composedPath()) || evt.path;
      const { target } = evt;
      if (path != null) {
        return path.indexOf(window) < 0 ? path.concat(window) : path;
      }
      if (target === window) {
        return [window];
      }
      function getParents(node, oriMemo) {
        const memo = oriMemo || [];
        const { parentNode } = node;
        if (!parentNode) {
          return memo;
        }
        return getParents(parentNode, memo.concat(parentNode));
      }
      return [target].concat(getParents(target), window);
    },
    filterFun(value, data) {
      if (!value) return true;
      return data[this.propsLabel].indexOf(value) !== -1;
    },
    treeCheckFun(data, node, vm) {
      this.clickFlag = 'L';
      this.ids = [];
      const { propsValue } = this;
      node.checkedNodes.forEach((item) => {
        this.ids.push(item[propsValue]);
      });
      this.$emit('check', data, node, vm);
    },
    selectRemoveTag(tag) {
      this.clickFlag = 'R';
      const checkedNodes = this.$refs.tree.getCheckedNodes();
      const nowNode = checkedNodes.filter((item) => item.path === tag);
      const newTestObj = JSON.parse(JSON.stringify(nowNode));
      const flatten = (obj) => {
        const array = Array.isArray(obj) ? obj : [obj];
        return array.reduce((acc, value) => {
          let newAcc = acc;
          const newValue = value;
          newAcc.push(newValue);
          if (newValue.children) {
            newAcc = newAcc.concat(flatten(newValue.children));
            delete newValue.children;
          }
          return newAcc;
        }, []);
      };
      const flattenNode = flatten(newTestObj);

      let uncheckPaths = [];
      flattenNode.forEach((item) => {
        uncheckPaths.push(item.path);
      });

      const newUncheckPaths = new Set(uncheckPaths);
      const originIds = this.labels.map((item) => item.path);
      const testNewIDS = [];
      const repeat = [];
      originIds.forEach((item) => {
        const newItem = item;
        if (newUncheckPaths.has(newItem)) {
          repeat.push(newItem);
        } else {
          testNewIDS.push(newItem);
        }
      }, repeat, testNewIDS, uncheckPaths);
      uncheckPaths = [];
      this.ids = testNewIDS;
      this.$emit('removeTag', this.ids, tag);
    },
  },
};
</script>
<style scoped lang="scss">
#cagoryRange {
  .determine {
    padding: $contentWrap;
    display: flex;
    .el-button {
      flex: 1;
      color: $mainTextColor;
      font-weight: normal;
      border-color: $buttonTransparent02;
      background-color: $buttonHover02;
    }
    .el-button:hover {
      border-color: $buttonTransparent02;
      background-color: $buttonHover02;
    }
  }
  > .title {
    padding: $padding;
    margin: $lineHeight;
    font-size: $pageTitle;
    i {
      padding-left: 0.5em;
      cursor: pointer;
      color: $buttonTransparent01;
    }
    i:hover {
      color: $buttonTransparent02;
    }
  }
  ::v-deep .el-button:hover {
    border-color: $buttonTransparent02;
    background-color: $buttonHover02;
  }
  .itemArea {
    padding: $contentWrap;
    display: flex;
    flex-direction: row-reverse;
    @media screen and (max-width: $mobileLandscape) {
      flex-direction: column-reverse;
    }
    .options {
      flex: 2;
      padding: $padding;
      min-height: 40vh;
      border-left: 1px solid $shadow;
      @media screen and (max-width: $mobileLandscape) {
        visibility: collapse;
        max-height: 0px;
      }
      .title {
        font-size: $pageTitle;
      }
      .optionArea {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        > span {
          font-size: $pageTitle;
        }
        > div {
          margin: 0.5em;
        }
        .el-tree-select-input {
          width: 100%;
          height: 100%;
          ::v-deep input {
            display: none;
          }
          .el-select__tags {
          }
        }
        ::v-deep .el-input__suffix {
          display: none;
        }
      }
    }
    .treeArea {
      flex: 1;
      padding: $padding;
      @media screen and (max-width: $mobileLandscape) {
        padding: 0px;
      }
      ::v-deep .el-tree-node__content:hover {
        background: $buttonHover02;
      }
      ::v-deep .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background: transparent;
      }
    }
  }

  .el-tree-select .select-option {
    display: none !important;
  }

  [aria-disabled="true"] > .el-tree-node_content {
    color: inherit !important;
    background: transparent !important;
    cursor: no-drop !important;
  }

  .el-tree-select-popper {
    max-height: 400px;
    overflow: auto;
  }
  .el-tree-select-popper.disabled {
    display: none !important;
  }
  .el-tree-select-popper .el-button--small {
    width: 25px !important;
    min-width: 25px !important;
  }

  .el-tree-select-popper[x-placement^="bottom"] {
    margin-top: 5px;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .no-data {
    height: 32px;
    // line-height: 32px;
    font-size: 14px;
    color: #cccccc;
    text-align: center;
  }
}
</style>
