<template>
  <div id="textToSpeech" >
    <el-button :class="{'noShow' : entryWord && !entryWord.length}"
      @click="playPronunciation()" :disabled="playingDisable"
      v-if="!playing"
    >
      <i class="fa fa-volume-up"></i>
    </el-button>
    <el-button :class="{'noShow' : entryWord && !entryWord.length}"
      @click="stopPronunciation()"
      v-if="playing"
    >
      <i class="material-icons">stop</i>
    </el-button>
    <div v-for="(audioID) in audioIDList" :key="audioID">
    <audio
      :src="`https://api.adarshah.org/plugins/adarshaplugin/file_servlet/downloadAudio?id=${audioID}&apiKey=MTczMDgyMzE2NGMwNjU2YjIyOTM5ZTliNjllMDExNDc=`"
      controls
      :ref="audioID"
      hidden
    >
    </audio>
    </div>
  </div>
</template>

<script>
const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
export default {
  name: 'TextToSpeech',
  props: {
    audioIDList: {
      type: Array,
      default() {
        return [];
      },
    },
    entryWord: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      audioSpeed: true,
      nowPlayOrder: 1,
      playing: false,
      playingDisable: false,
    };
  },
  computed: {
    noAudioIDList() {
      if (this.audioIDList.length) {
        return false;
      }
      return true;
    },
  },
  methods: {
    stopPronunciation() {
      const newID = this.audioIDList[0];
      this.$refs[newID][0].pause();
      this.$refs[newID][0].currentTime = 0;
      this.playingDisable = false;
      this.playing = false;
      synth.cancel();
    },
    playPronunciation() {
      const firstWrod = this.entryWord.substr(0, 1);
      const tibetanPattern = new RegExp('[\u0F00-\u0FFF]+');
      if (tibetanPattern.test(firstWrod)) {
        if (!this.noAudioIDList) {
          const newID = this.audioIDList[0];
          this.$refs[newID][0].play();
          this.$refs[newID][0].playbackRate = this.audioSpeed ? 1 : 0.7;
          this.playing = true;
          this.playingDisable = true;
          this.$refs[newID][0].addEventListener('ended', () => {
            this.playingDisable = false;
            this.playing = false;
          }, false);
          // this.$refs[newID][0].removeEventListener('ended');
        }
      } else {
        this.playVoice(this.entryWord);
      }
    },
    playPronunciationForCombined() {
      const firstWrod = this.entryWord.substr(0, 1);
      const tibetanPattern = new RegExp('[\u0F00-\u0FFF]+');
      if (tibetanPattern.test(firstWrod)) {
        if (!this.noAudioIDList) {
          const newID = this.audioIDList[0];
          this.$refs[newID][0].play();
          this.$refs[newID][0].playbackRate = this.audioSpeed ? 1 : 0.7;
          this.playing = true;
          this.playingDisable = true;
        }
      } else {
        this.playVoice(this.entryWord);
      }
    },
    playVoice(text) {
      this.handleSpeak(text);
    },
    handleSpeak(text) {
      this.playing = true;
      msg.text = text;
      msg.lang = 'zh-TW';
      msg.volume = 1;
      msg.rate = this.audioSpeed ? 1.2 : 1;
      synth.speak(msg);
      this.audioSpeed = !this.audioSpeed;
      msg.addEventListener('end', () => {
        this.playing = false;
      });
    },
    handleNext() {
      this.playingDisable = false;
      this.playing = false;
    },
    handleNextForCombined() {
      if (this.playing && this.audioIDList.length !== 1) {
        const newID = this.audioIDList[this.nowPlayOrder];
        this.$refs[newID][0].playbackRate = this.audioSpeed ? 1 : 0.7;
        this.$refs[newID][0].play();
        if (this.audioIDList.length === (this.nowPlayOrder + 1)) {
          this.nowPlayOrder = 1;
          this.playing = false;
          this.audioSpeed = !this.audioSpeed;
          this.$refs[newID][0].addEventListener('ended', () => {
            this.playingDisable = false;
          }, false);
        } else {
          this.nowPlayOrder += 1;
        }
      } else if (this.audioIDList.length === 1) {
        this.playing = false;
        this.playingDisable = false;
        this.audioSpeed = !this.audioSpeed;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#textToSpeech {
  background: transparent;
  text-align: left;
  .noShow {
    display: none;
  }
  .el-button {
    background: transparent;
    border: none;
    font-size: 1em;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-button.el-button--default:hover {
    background: transparent;
  }
}
</style>
