import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

// category
import Category from '../views/Category.vue';
import CategoryResult from '../views/CategoryResult.vue';

// alphabet links
import Alphabet from '../views/Alphabet.vue';
import AlphabetTable from '../views/AlphabetTable.vue';
import AlphabetTableLv2 from '../views/AlphabetTableLv2.vue';
import AlphabetResult from '../views/AlphabetResult.vue';

// syllable
import Syllable from '../views/Syllable.vue';

// about links
import About from '../views/About.vue';

// support links
import Support from '../views/Support.vue';

// membership system
import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import MemberAdmin from '../views/MemberAdmin.vue';
import MemberInner from '../views/MemberInner.vue';
import MemberReader from '../views/MemberReader.vue';

// result
import Result from '../views/Result.vue';
import Entry from '../views/Entry.vue';
import EntryAdv from '../views/EntryAdv.vue';
import EntryNo from '../views/EntryNo.vue';

// advanced search
import AdvancedSearch from '../views/AdvancedSearch.vue';

// other
import Policy from '../views/Policy.vue';
import CNDonate from '../views/CNDonate.vue';
import Donate from '../views/Donate.vue';
import ForeignCurrency from '../views/ForeignCurrency.vue';
import TaiwanCurrency from '../views/TaiwanCurrency.vue';

// for Test (need to delete)
// import ZTEST from '../views/ZTEST.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requireAuth: false,
    },
  },
  // category
  {
    path: '/category',
    name: 'Category',
    component: Category,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/category/:path(.*)',
    name: 'CategoryResult',
    component: CategoryResult,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/categoryEntry/:entryFolder/:entryText/:path(.*)',
    name: 'CategoryResult',
    component: CategoryResult,
    meta: {
      requireAuth: false,
    },
  },
  // alphabet links
  {
    path: '/alphabet',
    name: 'Alphabet',
    component: Alphabet,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/alphabet/:alphabetLang',
    name: 'AlphabetTable',
    component: AlphabetTable,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/alphabet/:alphabetLang/:alphabetLangLv2',
    name: 'AlphabetTableLv2',
    component: AlphabetTableLv2,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/alphabet/:alphabetLang/:alphabet/result/:folder/:text',
    name: 'AlphabetResult',
    component: AlphabetResult,
    meta: {
      requireAuth: false,
    },
  },
  // syllable links
  {
    path: '/syllable',
    name: 'Syllable',
    component: Syllable,
    meta: {
      requireAuth: false,
    },
  },
  // about links
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      requireAuth: false,
    },
  },
  // support links
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      requireAuth: false,
    },
  },
  // membership system
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/admin',
    name: 'MemberAdmin',
    component: MemberAdmin,
    meta: {
      requireAuth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/inner-staff',
    name: 'MemberInner',
    component: MemberInner,
    meta: {
      requireAuth: true,
      roles: ['inner'],
    },
  },
  {
    path: '/reader',
    name: 'MemberReader',
    component: MemberReader,
    meta: {
      requireAuth: true,
      roles: ['reader'],
    },
  },
  // result
  {
    path: '/result',
    name: 'Result',
    component: Result,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/entry/:entryFolder/:entryText/:searchKWord?',
    name: 'Entry',
    component: Entry,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/entry/:entryFolder/:entryText/:searchKWord?',
    name: 'EntryAdv',
    component: EntryAdv,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/no-entry/:searchKWord?',
    name: 'EntryNo',
    component: EntryNo,
    meta: {
      requireAuth: false,
    },
  },
  // advanced search
  {
    path: '/advanced-search',
    name: 'AdvancedSearch',
    component: AdvancedSearch,
    meta: {
      requireAuth: false,
    },
  },
  // Policy
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
    meta: {
      requireAuth: false,
    },
  },
  // CNDonate
  {
    path: '/donateSupport',
    name: 'CNDonate',
    component: CNDonate,
    meta: {
      requireAuth: false,
    },
  },
  // Donate
  {
    path: '/donate/:lang?',
    name: 'Donate',
    component: Donate,
    meta: {
      requireAuth: false,
    },
  },
  // ForeignCurrency
  {
    path: '/foreignCurrencyAccount',
    name: 'ForeignCurrency',
    component: ForeignCurrency,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: '/taiwanAccount',
    name: 'TaiwanCurrency',
    component: TaiwanCurrency,
    meta: {
      requireAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // console.log('from', from);
    if (to.hash) {
      return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

const role = store.state.userSettings.userRole;

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (store.state.token) {
      if (to.meta.roles.includes(role)) {
        next();
      } else {
        const newState = 'wrong id or password';
        store.dispatch('userSettings/setLoginState', newState);
        next({
          name: 'login',
          query: { backUrl: to.fullPath },
        });
      }
    }
  } else {
    next();
  }
});

export default router;
