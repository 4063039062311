<template>
  <div id="about">
    <div class="mainContent">
      <div>
        <div class="title">{{$t('about.mainTitle')}}</div>
        <!-- <div class="subTitle">{{$t('index.slogan')}}SLOGAN HERE</div> -->
        <div class="text">
          <p>
            {{$t('about.mainIntro')}}
          </p>
        </div>
      </div>
    </div>
    <div class="subContent">
      <!-- <div class="title">{{$t('about.area01Title')}}</div>
      <div class="content">
        <div class="intro">
          <div class="title">1、{{$t('about.area02Title')}}</div>
          <div class="text">
            <p>
              {{$t('about.area02Content')}}
            </p>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="intro">
          <div class="title">2、{{$t('about.area03Title')}}</div>
          <div class="text">
            <p>
              {{$t('about.area03Content')}}
            </p>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="intro">
          <div class="title">3、{{$t('about.area04Title')}}</div>
          <div class="text">
            <p>
              {{$t('about.area04Content')}}
            </p>
          </div>
        </div>
      </div> -->

      <div class="content">
        <div class="intro">
      <div class="title">{{$t('about.dicRangeA')}}Ａ</div>
      <div class="text">
      <ol type="1">
        <li>
          <div class="title">{{$t('about.bookNameA01')}}</div>
          <p>
            {{$t('about.introA01')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameA02')}}</div>
          <p>
            {{$t('about.introA02')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameA03')}}</div>
          <p v-html="$t('about.introA03')"></p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameA04')}}</div>
          <p v-html="$t('about.introA04')"></p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameA05')}}</div>
          <p>
            {{$t('about.introA05')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameA06')}}</div>
          <p>
            {{$t('about.introA06')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameA07')}}</div>
            <p v-html="$t('about.introA07')"></p>
        </li>
      </ol>
      </div>

      <div class="title">{{$t('about.dicRangeB')}}</div>
      <div class="text">
      <ol type="1">
        <li>
          <div class="title">{{$t('about.bookNameB01')}}</div>
          <p v-html="$t('about.introB01')">
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameB02')}}</div>
          <p>
            {{$t('about.introB02')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameB03')}}</div>
          <p>
            {{$t('about.introB03')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameB04')}}</div>
          <p>
            {{$t('about.introB04')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameB05')}}</div>
          <p>
            {{$t('about.introB05')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameB06')}}</div>
          <p>
            {{$t('about.introB06')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameB07')}}</div>
          <p v-html="$t('about.introB07')">
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameB08')}}</div>
          <p v-html="$t('about.introB08')">
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameB09')}}</div>
          <p v-html="$t('about.introB09')">
          </p>
        </li>
      </ol>
      </div>

      <div class="title">{{$t('about.dicRangeC')}}</div>
      <div class="text">
      <ol type="1">
        <li>
          <div class="title">{{$t('about.bookNameC01')}}</div>
          <p>
            {{$t('about.introC01')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameC02')}}</div>
          <p>
            {{$t('about.introC02')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameC03')}}</div>
          <p>
            {{$t('about.introC03')}}
          </p>
        </li>
      </ol>
      </div>

      <div class="title">{{$t('about.dicRangeD')}}</div>
      <div class="text">
      <ol type="1">
        <li>
          <div class="title">{{$t('about.bookNameD01')}}</div>
          <p>
            {{$t('about.introD01')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameD02')}}</div>
          <p>
            {{$t('about.introD02')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameD03')}}</div>
          <p>
            {{$t('about.introD03')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameD04')}}</div>
          <p>
            {{$t('about.introD04')}}
          </p>
        </li>
      </ol>
      </div>

      <div class="title">{{$t('about.dicRangeE')}}</div>
      <div class="text">
      <ol type="1">
        <li>
          <div class="title">{{$t('about.bookNameE01')}}</div>
          <p>
            {{$t('about.introE01')}}
          </p>
        </li>
        <!-- <li>
          <div class="title">{{$t('about.bookNameE02')}}</div>
          <p>
            {{$t('about.introE02')}}
          </p>
        </li> -->
        <li>
          <div class="title">{{$t('about.bookNameE03')}}</div>
          <p>
            {{$t('about.introE03')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameE04')}}</div>
          <p>
            {{$t('about.introE04')}}
          </p>
        </li>
        <li>
          <div class="title">{{$t('about.bookNameE05')}}</div>
            <p v-html="$t('about.introE05')"></p>
        </li>
      </ol>
      </div>

      <div class="title">{{$t('about.thanks')}}</div>
      <div class="text">
          <p>
            {{$t('about.thanksContent1')}}
            {{$t('about.thanksContent2')}}
          </p>
          <p v-html="$t('about.thanksContent3')">
          </p>
      </div>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  components: {
  },
};
</script>

<style scoped lang="scss">
  #about {
    line-height: 1em;
    .title {
      font-size: $contentCaption;
      font-weight: bold;
      @media screen and (max-width: $mobileLandscape) {
        line-height: 1.2em;
      }
    }
    .mainContent {
      padding: $contentWrap;
      background: rgba(0, 0, 0, 0.3);
      background-blend-mode: multiply;
      background-image: url("./../assets/img/photo/13.jpg");
      background-position: 0px -100px;
      background-size: cover;
      background-repeat: no-repeat;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $mobileLandscape) {
        background-position: 0px 0px;
        background-size: cover;
      }
      > div {
        // background: rgba(255, 255, 255, 0.6);
        background: rgba(0, 0, 0, 0.6);
        border-radius: $radius;
        // box-shadow: $boxShadow;
        width: 50%;
        padding: $margin;
        margin: $margin;
        text-align: center;
        @media screen and (max-width: $mobileLandscape) {
          margin: 0px;
          padding: $margin;
          width: 90%;
        }
        .subTitle {
          color: $mainThemeColor;
          font-weight: bolder;
        }
      }
    }
    .subContent {
        margin: $padding;
        padding: $contentWrap;
        margin-top: $gapHeight;
      .content {
        margin-top: $gapHeight;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: $mobileLandscape) {
          flex-direction: column;
          border-bottom: 1px solid $border-color;
          margin-bottom: calc(#{$gapHeight * 2});
          padding-bottom: calc(#{$gapHeight * 2});
        }
        .pic {
          flex: 1;
          padding: $padding;
          margin: calc(#{$psPadding} * 2);
          margin-top: 0px;
          margin-bottom: 0px;
          @media screen and (max-width: $mobileLandscape) {
            margin-bottom: $gapHeight;
          }
          img {
            width: 100%;
            border: 1px solid $border-color;
            border-radius: $radius;
            box-shadow: $boxShadow;
          }
        }
        .intro {
          flex: 1.3;
          padding: $padding;

          >  .title {
            font-size: $contentCaption;
            font-weight: normal;
            line-height: $contentCaption;
            font-weight: bolder;
            padding: 0.3em 0em;
          }
          .text {
            .title {
              font-size: 1.2em;
              font-weight: bolder;
              line-height: $contentCaption;
            }
          }
        }
      }
      .content:nth-child(odd) {
        flex-direction: row-reverse;
        @media screen and (max-width: $mobileLandscape) {
          flex-direction: column;
        }
      }
      .content:last-child {
        @media screen and (max-width: $mobileLandscape) {
          border-bottom: none;
        }
      }
    }
  }
</style>
