<template>
  <div id="syllable">
    <div class="syllableTable">
      {{audioID}}
      {{audioResBack}}
      <audio controls ref='audio'>
          Your browser does not support the audio element.
      </audio>
            <!-- <audio
              :src="`http://admin.dharma-treasure.org/plugins/adarshaplugin/file_servlet/downloadAudio?id=${audioID}&apiKey=MTczMDgyMzE2NGMwNjU2YjIyOTM5ZTliNjllMDExNDc=`"
              hidden=false
            >
            </audio> -->
      <div v-for="(alphabet, index) in alphabetTable" :key="index">
        <button @click="nowClickWord = alphabet.key">{{alphabet.key}}</button><hr/>
        <div v-if="nowClickWord === alphabet.key">
          <div v-for="(syllable, index) in alphabet.list" :key="index">
            <el-button>
              <button @click="getAudioInfoList(syllable)">{{syllable}}</button>
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import entryAPI from '@/assets/api/entry';

export default {
  name: 'AlphabetTable',
  data() {
    return {
      nowClickWord: 'null',
      alphabetTable: null,
      alphabetLang: 'bo',
      audioID: null,
      audioResBack: null,
    };
  },
  mounted() {
    let lang = this.alphabetLang;

    lang = `../alphabet/${lang}.json`;

    axios.get(lang)
      .then((res) => {
        this.alphabetTable = res.data;
      }, (error) => {
        console.log('get-error', error);
      });
  },
  methods: {
    getAudioInfoList(strings) {
      const string = [strings];
      entryAPI.getListAudioID((res) => {
        this.$refs.audio.src = `http://admin.dharma-treasure.org/plugins/adarshaplugin/file_servlet/downloadAudio?id=${res[0].id}&apiKey=MTczMDgyMzE2NGMwNjU2YjIyOTM5ZTliNjllMDExNDc=`;
        this.$refs.audio.play();
        this.audioID = res[0].id;
      }, string);
    },
  },
};
</script>

<style scoped lang="scss">
  #syllable {
    padding: $wrapWidth;
    ::v-deep .el-button:hover {
      border-color: $buttonTransparent02;
      background-color: $buttonHover02;
    }
    .syllableTable {
      background: red;
      display: flex;
      flex-wrap: wrap;
      margin: $margin;
      max-width: 100%;
      justify-content: center;
      flex-direction: row;
      @media screen and (max-width: $mobileLandscape) {
        margin: 0px;
        margin-top: $margin;
      }
      .el-button {
        margin: 0.5em;
        font-size: $logoTitle;
        @media screen and (max-width: $mobileLandscape) {
          margin: 0.3em;
        }
      }
    }
    .breadcrumb {
      background: $shadow;
      padding: $padding;
      margin: $margin;
      margin-top: 0px;
      margin-bottom: $gapHeight;
      display: flex;
      line-height: 1em;
      flex-direction: row;
      @media screen and (max-width: $mobileLandscape) {
        width: 100%;
        padding: 0.3em;
        margin: 0px;
      }
      li {
        list-style: none;
        padding-right: $contentWrap;
        :after {
          padding-left: $contentWrap;
          content: '/';
        }
      }
      li:last-child {
        :after {
          content: '';
        }
      }
    }
  }
</style>
