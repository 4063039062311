<template>
  <div id="advancedSearch">
    <div class="searchInput">
      <div class="title">
        <span>{{$t('advancedSearch.keyword')}}</span>
        <i class="el-icon-refresh" @click="resetSearchInput()"></i>
      </div>
      <div class="inputArea">
        <el-input
          :placeholder="$t('search.keywordPls')"
          v-model="userInput"
          class="input-with-select"
          @keypress.enter.native="searchIt()"
        >
      </el-input>
      <el-button
        @click="searchIt()" slot="append" class="searchBtn" icon="el-icon-search"
      >
        {{$t('advancedSearch.goForSearch')}}
      </el-button>
      </div>
    </div>
    <div class="dicSelect">
      <div class="title">
        <span>{{$t('dictionary.selectDicRange')}}</span>
        <i class="el-icon-refresh" @click="handleCheckReset()"></i>
      </div>
        <div class="dicMainContent">
      <div class="filterCate">

<div class="dicts" v-if="renderDict" >
  <div class="catDictionary" v-for="(dictType, index) in dictionaryType" :key="index">
        <div class="dicTypeSelect" :class="{'clicked': !noShow[dictType]}">
        <button class="checkbox" type="checkbox" @click="handleCheckAllChange(dictType)">
          <span v-show="checkAll[dictType]">✓</span>
        </button>
        <button class="showDict"  @click="noShow[dictType] = !noShow[dictType]">
          {{$t(`about.dicRange${dictType}`)}}
          <span v-if="noShow[dictType]"> ◂</span>
          <span v-if="!noShow[dictType]"> ▾</span>
        </button>
        </div>
        <div class="checkboxSelect">
          <el-checkbox-group
            v-model="checkedDictaionary"
          >
            <div class="dictCategory" :class="{'noShow': noShow[dictType]}"
              v-if="dictionaryList&&dictionaryList.length"
            >
              <el-checkbox
    v-for="(dictionary, index) in dictionaryList.filter((item) => item.lang['type'] === dictType)"
                :key="index"
                :label="dictionary"
              >
              <span
                v-if="
                  $i18n.locale === 'zh-TW'||$i18n.locale === 'zh-HK'||
                  $i18n.locale === 'zh-CN'||$i18n.locale === 'bo'||
                  $i18n.locale === 'zh-tw'||$i18n.locale === 'zh-hk'||
                  $i18n.locale === 'zh-cn'
                "
              >
                ── {{dictionary.otherName[$i18n.locale] || dictionary.name}}
              </span>
              <span v-else>
                ── {{dictionary.otherName['en'] || dictionary.name}}
              </span>
              </el-checkbox>
            </div>

          </el-checkbox-group>
        </div>
      </div>
</div></div>
  </div>
    </div>

    <div class="categorySelect">
      <div v-if="treeParams.data" class="categoryList">
        <CategoryRangeForAdv
          popoverClass="fas"
          v-model="values"
          :selectParams="selectParams"
          :treeParams="treeParams"
          ref="treeSelect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import searchAPI from '@/assets/api/search';
import CategoryRangeForAdv from '@/components/CategoryRangeForAdv.vue';

export default {
  name: 'DictionaryRange',
  data() {
    return {
      // search
      treeParamsExist: false,
      entryList: null,
      keyword: '',
      userInput: this.$route.params.searchKWord || '',
      select: '',
      values: [],
      selectParams: {
        clearable: true,
        placeholder: '',
      },
      // dictionary select
      resetAll: false,
      renderDict: (this.$store.state.dictionary.dicList ? 'true' : 'false'),
      state: '',
      timeout: null,
      dictionaryType: ['A', 'B', 'C', 'D', 'E'],
      checkAllType: false,
      checkAll: {
        A: false,
        B: false,
        C: false,
        D: false,
        E: false,
      },
      checkedDictaionary: [],
      noShow: {
        A: false,
        B: false,
        C: false,
        D: false,
        E: false,
      },
      // category select
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
      },
    };
  },
  components: {
    CategoryRangeForAdv,
  },
  computed: {
    // search
    treeParams() {
      return {
        clickParent: false,
        filterable: true,
        accordion: true,
        'check-strictly': false,
        'default-expand-all': true,
        'expand-on-click-node': false,
        'render-content': this.renderFun,
        data: this.$store.state.category.arrCatList,
        props: {
          children: 'children',
          label: 'name',
          path: 'path',
          disabled: 'disabled',
          value: 'path',
        },
      };
    },
    keyWordFiltered: {
      get() {
        return this.avoidSqlInjection(this.userInput);
      },
      set() {
      },
    },
    // dictionary select
    dictionaryList() {
      return this.$store.state.dictionary.dicList;
    },
    dictionaryListALength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'A').length;
      }
      return 0;
    },
    dictionaryListBLength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'B').length;
      }
      return 0;
    },
    dictionaryListCLength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'C').length;
      }
      return 0;
    },
    dictionaryListDLength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'D').length;
      }
      return 0;
    },
    dictionaryListELength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'E').length;
      }
      return 0;
    },
    nowDictionaryRange() {
      return this.$store.state.dictionary.nowDicRange;
    },
    // category select
    optionData: {
      get() {
        return this.$store.state.category.arrCatList;
      },
      set() {
      },
    },
  },
  watch: {
    // search
    userInput(val) {
      const str = this.avoidSqlInjection(val);
      this.keyWordFiltered = str;
    },
    // dictionary select
    checkedDictaionary: {
      handler(val) {
        this.updatedSelectDic();
        if (val) {
          this.checkAll.A = val.filter((item) => item.lang.type === 'A').length === this.dictionaryListALength;
          this.checkAll.B = val.filter((item) => item.lang.type === 'B').length === this.dictionaryListBLength;
          this.checkAll.C = val.filter((item) => item.lang.type === 'C').length === this.dictionaryListBLength;
          this.checkAll.D = val.filter((item) => item.lang.type === 'D').length === this.dictionaryListBLength;
          this.checkAll.E = val.filter((item) => item.lang.type === 'E').length === this.dictionaryListBLength;
          if (val.filter((item) => item.lang.type === 'C').length !== 0) {
            this.checkAll.C = val.filter((item) => item.lang.type === 'C').length === this.dictionaryListCLength;
          }
          if (val.filter((item) => item.lang.type === 'A').length !== 0) {
            this.checkAll.A = val.filter((item) => item.lang.type === 'A').length === this.dictionaryListALength;
          }
        }
      },
    },
    nowDictionaryRange: {
      handler() {
        this.renderSelectedDicCatList();
      },
    },
  },
  methods: {
    // search input
    resetSearchInput() {
      this.userInput = '';
    },
    avoidSqlInjection(str) {
      let newString = '';
      if (str !== '') {
        newString = str.replace(/[`~!@#$^&?￥*%()=|{}:;,<>~！……/_（）——|'；："。，、？\s]/ig, '');
      }
      return newString;
    },
    searchIt() {
      const changeBoText = this.keyWordFiltered.substr((this.keyWordFiltered.length - 1), 1);
      if (changeBoText === '་') {
        this.userInput = `${this.keyWordFiltered.substr(0, (this.keyWordFiltered.length - 1))}།`;
      }
      searchAPI.postEntrySearch((res) => {
        if (res.list.length !== 0) {
          this.entryList = res;
          const entryFolder = res.list[0].folder;
          const entryText = res.list[0].text;
          this.$router.push({
            name: 'EntryAdv',
            params: {
              entryFolder,
              entryText,
              searchKWord: this.keyWordFiltered,
            },
          });
        } else {
          this.$router.push({
            name: 'EntryNo',
            params: {
              searchKWord: this.keyWordFiltered,
            },
          });
        }
      }, 'Entry', this.keyWordFiltered, this.filterDictionaryID,
      this.$store.state.category.advCatRange, this.$i18n.locale);
    },
    // dictionary select
    handleCheckAllChange(val) {
      this[`checkAll[${val}]`] = !this[`checkAll[${val}]`];
      if (this[`checkAll[${val}]`]) {
        const otherCheckDict = this.checkedDictaionary.filter((item) => item.lang.type !== val);
        const valDict = this.dictionaryList.filter((item) => item.lang.type === val);
        this.checkedDictaionary = otherCheckDict.concat(valDict);
      } else {
        const newArray = this.checkedDictaionary.filter((item) => item.lang.type !== val);
        this.checkedDictaionary = newArray;
      }
    },
    updatedSelectDic() {
      // this.filterDictionaryID = this.checkedDictaionary.map((item) => item.id).toString();
    },
    renderSelectedDicCatList() {
      // this.$store.dispatch('category/setSelectedDicRangeCatList');
    },
    handleCheckReset() {
      this.checkedDictaionary = [];
    },
    handleCheckedDictaionary(value) {
      const checkedCount = value.length;
      this.checkAllType = checkedCount === this.dictionaryList.length;
    },
    handleClose(done) {
      done();
    },
    querySearchAsync(queryString, cb) {
      const { dictionaryList } = this;
      const results = queryString ? dictionaryList.filter(
        this.createStateFilter(queryString),
      ) : dictionaryList;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 300 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        const check = state.name.toLowerCase()
          .indexOf(queryString.toLowerCase()) >= 0;
        return check;
      };
    },
  },
};
</script>

<style scoped lang="scss">
#advancedSearch {
  text-align: left;
  padding: 0em 2em;
  color: #000;
  padding: $wrapWidth;
  @media screen and (max-width: $mobileLandscape) {
    paddding: 0px;
  }
  .noShow {
    display: none;
  }
  .dicMainContent {
   display: flex;
    flex-direction: row;
    .filterCate {
      display: block;
      width: 100%;
      flex: 3;
      padding: $padding;
    @media screen and (max-width: $mobileLandscape) {
      padding: 0px;
    }
    }
          .el-checkbox {
        display: flex;
        padding-bottom: 0.5em;
        @media screen and (max-width: $mobileLandscape) {
          display: flex;
          white-space: normal;
        }
      }
      .el-checkbox__label {
        @media screen and (max-width: $mobileLandscape) {
          padding-bottom: 1em;
        }
        span {
          font-size: 1.3em;
          font-weight: normal;
          line-height: 1.5em;
        }
        .dicLangs:after {
          content: '/',
        }
        .dicLangs:last-child:after {
          content: '',
        }
      }
    .dicts {
      flex: 7;
      display: flex;
      padding: $padding;
      flex-direction: column;
      @media screen and (max-width: $mobileLandscape) {
        padding: 0px;
      }
    .catDictionary:hover {
      border-left: 5px solid $psBgColor;
    }
      .catDictionary {
        border-left: 5px solid transparent;
        padding-left: 1em;
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-top: 2em;
        .dicTypeSelect {
        margin-bottom: 0.5em;
        margin-right: 1em;
        display: flex;
        padding-bottom: 8px;
        margin-bottom: 1em;
        border-bottom: 3px solid $buttonTransparent02;
        padding-bottom: 0px;
        @media screen and (max-width: $mobileLandscape) {
          margin-top: 1em;
        }
        .checkbox {
          width: 2em;
          border: none;
          font-size: 0.9em;
          background: $buttonTransparent02;
          @media screen and (max-width: $mobileLandscape) {
            display: block;
            min-width: 2em;
          }
        }
        .showDict {
          border: none;
          font-size: 0.9em;
          padding: 0.1em 1em;
          background: none;
          width: 100%;
          text-align: left;
          font-size: 1.1em;
          font-weight: 500;
        }
      }
      .checkboxSelect {
        ::v-deep .el-checkbox__input {
          display: flex;
          .el-checkbox__inner {
            width: 1em;
            height: 1em;
            border: 1px solid #929192;
            font-size: 2em;
          }
          .el-checkbox__inner::after {
            height: 0.5em;
            width: 0.3em;
            margin-left: 0.15em;
          }
        }
      }
      }
    }
  }
  .title {
    padding: $padding;
    margin: $lineHeight;
    font-size: $pageTitle;
    i {
      padding-left: 0.5em;
      cursor: pointer;
      color: $buttonTransparent01;
    }
    i:hover {
      color: $buttonTransparent02;
    }
  }
  .el-button {
    color: $mainTextColor;
    font-weight: normal;
    border-color: $buttonTransparent02;
    background-color: $buttonHover02;
  }
  .el-button:hover {
    border-color: $buttonTransparent02;
    background-color: $buttonHover02;
  }

  .searchBtn {
    width: 100%;
    font-size: 1em;
    margin-top: $contentWrap;
  }

  .searchInput {
    text-align: left;
    padding: $padding;
    margin-top: $gapHeight;
    .inputArea {
      padding: $padding;
    }
  }

  .dicSelect {
    border-top: 1px dashed $buttonTransparent02;
    padding: $padding;
    margin-top: $gapHeight;
    .selector {
      padding: $contentWrap;
      ::v-deep .el-checkbox-group {
        .el-checkbox {
          display: flex;
          padding-bottom: 0.5em;
          @media screen and (max-width: $mobileLandscape) {
            display: flex;
            white-space: normal;
          }
          .el-checkbox__label {
            font-size: $logoTitle;
            word-wrap: break-word;
            @media screen and (max-width: $mobileLandscape) {
              padding-bottom: 1em;
            }
             > span:after {
              content: '/',
            }
            > span:last-child:after {
              content: '',
            }
          }
        }
      }
    }
  }

  .categorySelect {
    border-top: 1px dashed $buttonTransparent02;
    padding: $padding;
    margin-top: $gapHeight;
  }

}
</style>
