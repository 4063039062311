<template>
  <div id="categoryResultDetail">
    <el-input
      placeholder=""
      v-model="filterText"
    >
      <i
        v-if="filterText !== ''"
        slot="suffix"
        @click="handleClear()"
        class="el-input__icon el-icon-circle-close el-input__clear"
      >
      </i>
    </el-input>
    <div class="lineStyle"/>
    <el-tree
      :key="treeUpdateKey"
      accordion
      :empty-text="$t('category.noThisCategory')"
      v-if="catTreeData"
      class="treeArea"
      :data="catTreeData"
      :props="defaultProps"
      :filter-node-method="filterNode"
      ref="tree"
      node-key="path"
      @node-click="handleNodeClick"
      :default-expanded-keys="selectBranch"
      :highlight-current="true"
    >
      <span slot-scope="{data}">
        {{data.name}}
      </span>
    </el-tree>
  </div>
</template>

<script>

export default {
  name: 'CategoryTree',
  data() {
    return {
      treeUpdateKey: 0,
      filterText: '',
      treeResult: null,
      nowKey: 'didnotchange',
      defaultProps: {
        id: 'id',
        children: 'children',
        label: 'name',
        path: 'path',
      },
    };
  },
  computed: {
    catTreeData() {
      return this.$store.state.category.arrCatList;
    },
    selectBranch: {
      get() {
        const newVal = this.$route.params.path.replace(/%2F/gi, '/') || null;
        return [newVal];
      },
      set() {
      },
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    selectBranch(val) {
      const newVal = val[0].replace(/%2F/gi, '/') || null;
      this.$refs.tree.setCurrentKey(newVal);
      this.setTreeBranch();
    },
  },
  mounted() {
    this.setselectBranch(this.$route.params.path);
  },
  updated() {
    this.setselectBranch(this.$route.params.path);
  },
  methods: {
    handleClear() {
      this.filterText = '';
      this.treeUpdateKey += 1;
    },
    setTreeBranch() {
      this.$nextTick(() => {
        const nowNode = this.$refs.tree.getCurrentNode();
        this.$store.dispatch('category/setTreeBranch', nowNode);
      });
    },
    setselectBranch(selectBranch) {
      if (this.$refs.tree) {
        this.nowKey = this.$refs.tree.getCurrentKey() || null;
        this.$nextTick(() => {
          const newVal = selectBranch.replace(/%2F/gi, '/') || null;
          this.$refs.tree.setCurrentKey(newVal);
          this.setTreeBranch();
        });
      }
    },
    countSpecificWord(str, char) {
      const newStr = str;
      const num = (newStr.split(char)).length - 1;
      return num;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.toUpperCase().indexOf(value.toUpperCase()) !== -1;
    },
    filterChange() {
      const filterText = '';
      this.$refs.tree2.filter(filterText);
    },
    handleNodeClick(data) {
      this.filterText = '';
      if (this.selectBranch[0].replace('%2F', '/') !== data.path) {
        this.$router.push({ name: 'CategoryResult', params: { path: data.path.replace(/\//g, '%2F') } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  #categoryResultDetail {
    ::v-deep .el-tree-node__content:hover {
      background: $buttonHover02;
    }
    ::v-deep .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
      background: $buttonTransparent02;
    }
    ::v-deep .el-input__suffix {
      .el-input__suffix-inner {
        border-color: none;
        .el-icon-circle-close:before {
          content: "\e79d" !important;
          font-size: 18px;
        }
      }
    }
    .lineStyle {
      height: $pageTitle;
    }
    .treeArea {
      margin-top: $padding;
    }
  }
</style>
