<template>
  <div id="alphabet">
    <div class="pageTitle">
      字母排序
    </div>
    <div class="mainContent">
      <el-row class="row">
        <el-col
          class="col"
          :span="6"
          v-for="(alphabet, index) in alphabetList"
          :key="index"
        >
          <el-card
            shadow="always"
            @mouseover.native="hovered = index"
            @mouseleave.native="hovered = null"
          >
            <div @click="nextLevel(alphabet.lang)">
              <div class="pic" :class="{'picHover': (hovered === index)}">
                <img :src="alphabet.imgSrc">
              </div>
              <div>
                <div class="title">
                  {{$t(alphabet.buttonTitle)}}
                </div>
                {{$t(alphabet.buttonContent)}}
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/img/picture/alphabet01.png';
import img2 from '@/assets/img/picture/alphabet04.png';
import img3 from '@/assets/img/picture/alphabet02.png';
import img4 from '@/assets/img/picture/alphabet03.png';

export default {
  name: 'Alphabet',
  data() {
    return {
      hovered: false,
      alphabetList: [
        {
          lang: 'bo',
          buttonTitle: 'alphabet.tibetan',
          buttonContent: 'alphabet.boAlphabet',
          imgSrc: img1,
        },
        {
          lang: 'en',
          buttonTitle: 'alphabet.english',
          buttonContent: 'alphabet.enAlphabet',
          imgSrc: img2,
        },
        {
          lang: 'zh-TW',
          buttonTitle: 'alphabet.traditionalChinese',
          buttonContent: 'alphabet.zhTWAlphabet',
          imgSrc: img3,
        },
        {
          lang: 'zh-CN',
          buttonTitle: 'alphabet.simpleChinese',
          buttonContent: 'alphabet.zhCNAlphabet',
          imgSrc: img4,
        },
      ],
    };
  },
  methods: {
    nextLevel(lang) {
      this.$router.push({
        name: 'AlphabetTable',
        params: {
          alphabetLang: lang,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
  #alphabet {
    padding: $wrapWidth;
    .pageTitle {
      font-size: $pageTitle;
      padding-bottom: $pageTitle;
    }
    .mainContent {
      .row {
        display: flex;
        margin: $margin;
        @media screen and (max-width: $mobileLandscape) {
            flex-direction: column;
            margin: 0px;
         }
        .col {
          flex: 1;
          padding: $gutter;
          @media screen and (max-width: $mobileLandscape) {
            padding: 0px;
            width: 100%;
            margin-bottom: $contentWrap;
          }
          .el-card {
            padding: $cardSpace;
            border-bottom: 3px solid transparent;
            text-align: center;
            cursor: pointer;
            @media screen and (max-width: $mobileLandscape) {
              padding: 0px;
            }
            .title {
              padding-bottom: 0.5em;
              font-size: $contentCaption;
              font-weight: bold;
            }
            .pic {
              text-align: center;
              margin-bottom: $contentWrap;
              img {
                width: 70%;
              }
            }
            .picHover {
              img {
                transform: scale(1.4);
                -ms-transform: scale(1.4);
                -webkit-transform: scale(1.4);
              }
            }
          }
          .el-card:hover {
            border-bottom: 3px solid $buttonHover;
          }
        }
        .col:first-child {
          padding-left: 0px;
        }
        .col:last-child {
          padding-right: 0px;
        }
      }
    }
  }
</style>
