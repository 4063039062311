import categoryAPI from '@/assets/api/category';
import dictionaryState from './dictionary';

const category = {
  namespaced: true,
  state: {
    catList: null,
    catListContrast: null,
    arrCatList: null,
    allCategories: null,
    selectedDicRangeCatList: null,
    nowCatRange: [],
    advCatRange: null,
    treeBranch: null,
    nowCatLabels: [],
  },
  mutations: {
    TREEBRANCH: (state, data) => {
      state.treeBranch = data;
    },
    CATLIST: (state, data) => {
      state.catList = data;
    },
    CATLISTCONTRAST: (state, data) => {
      const newData = JSON.stringify(data);
      state.catListContrast = JSON.parse(newData);
    },
    ARRCATLIST: (state, data) => {
      state.arrCatList = data;
    },
    ALLCATGORIES: (state, data) => {
      state.allCategories = data;
    },
    SELECTEDDICRANGECATLIST: (state, data) => {
      state.selectedDicRangeCatList = data;
    },
    NOWCATRANGE: (state, data) => {
      if (data.length !== 0) {
        state.nowCatRange = data;
      } else {
        state.nowCatRange = [''];
      }
    },
    ADVCATRANGE: (state, data) => {
      if (data.length !== 0) {
        state.advCatRange = data;
      } else {
        state.advCatRange = [''];
      }
    },
    NOWCATLABELS: (state, data) => {
      state.nowCatLabels = data;
    },
  },
  actions: {
    setTreeBranch({ commit }, treeBranch) {
      const newTreeBranch = treeBranch.children || null;
      commit('TREEBRANCH', newTreeBranch);
    },
    setCatList({ commit }, lang) {
      categoryAPI.postCatList((res) => {
        commit('CATLIST', res);
        commit('CATLISTCONTRAST', res);
        function rearrange(arr) {
          const tree = [];
          const mappedArr = {};
          let arrElem;
          let mappedElem;
          for (let i = 0, len = arr.length; i < len; i += 1) {
            arrElem = arr[i];
            mappedArr[arrElem.id] = arrElem;
            mappedArr[arrElem.id].children = [];
          }
          Object.keys(mappedArr).forEach((id) => {
            if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
              mappedElem = mappedArr[id];
              if (mappedElem.parent) {
                mappedArr[mappedElem.parent].children.push(mappedElem);
              } else {
                tree.push(mappedElem);
              }
            }
          });
          return tree;
        }
        const newList = rearrange(res);
        commit('ARRCATLIST', newList);

        const idNameListArr = [];
        function filterIdName(list) {
          const idNameList = list;
          idNameList.forEach((e) => {
            if (e.children) filterIdName(e.children);
            idNameListArr.push(e.idName);
          });
        }
        filterIdName(res);
        commit('ALLCATGORIES', idNameListArr);
        // commit('NOWCATRANGE', idNameListArr);
      }, lang);
    },
    setSelectedDicRangeCatList({ commit }, lang) {
      categoryAPI.postSelectDicCatList((res) => {
        commit('SELECTEDDICRANGECATLIST', res, dictionaryState.state.nowDicRange, lang);
      });
    },
    setNowCatRange({ commit }, newRange) {
      commit('NOWCATRANGE', newRange);
    },
    setNowCatLabels({ commit }, labels) {
      commit('NOWCATLABELS', labels);
    },
    setAdvCatRange({ commit }, newRange) {
      commit('ADVCATRANGE', newRange);
    },
  },
};

export default category;
