<!-- eslint-disable -->
<template>
  <div id="foreignCurrency">
    <h3>Foreign currency account：</h3>
    <u>TAISHIN INTERNATIONAL BANK</u><br/>
    <strong>Branch:</strong> JIAN CIAO BRANCH<br/>
    <strong>SWIFT CODE:</strong> TSIBTWTP<br/>
    <strong>Bank Address:</strong> No. 408,Section 5,Zhongxiao East Road,Xinyi District,Taipei City,110<br/>
    <strong>Bank phone:</strong> 02 8789 5788<br/>
    <strong>ACCOUNT No.</strong> 097756061380<br/>
    <strong>Account Name:</strong> Taiwan Buddha Wish Association<br/>
    <strong>Beneficiary’s address:</strong> 1F.,No.7,Aly. 10,Ln. 236,Sec. 5,Zhongxiao E. Rd.,
    Xinyi Dist.,Taipei City 110,Taiwan (R.O.C.)<br/>
    <strong>Beneficiary’s phone no. :</strong> +886287860726<br/>
    karmapawish@gmail.com<br/>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'ForeignCurrency',
};
</script>

<style scoped lang="scss">
  #foreignCurrency {
    margin: 3em;
    font-size: 1.2em;
    line-height: 1.3em;
    @media screen and (max-width: $mobilePortrait) {
      margin: 1em;
    }    
  }
</style>
