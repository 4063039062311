<template>
  <div id="support">
    <div class="videoArea">
      <iframe
        ref="intro"
        :src="videoURL"
        frameborder="0"
        width="100%"
        allowfullscreen
        allow="accelerometer;"
      >
      </iframe>
    </div>
    <!-- <div class="tutorial">
      <div class="videoArea">
        <iframe
          ref="search"
          :src="videoURL"
          frameborder="0"
          allowfullscreen
        >
        </iframe>
      </div>
      <div class="textArea">
      </div>
    </div> -->
    <!-- <div class="problems">
      <div class="title">{{$t('supportPage.questions')}}</div>
      <ul>
        <li>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Support',
  mounted() {
    const introIframe = this.$refs.intro;
    const searchIframe = this.$refs.search;
    const deviceWidth = document.documentElement.clientWidth;
    if (deviceWidth > 768) {
      const width = `${Number(deviceWidth) - Number(deviceWidth) / 2}px`;
      const height = `${(Number(deviceWidth) - Number(deviceWidth) / 2) * 0.5625}px`;
      introIframe.style.width = width;
      searchIframe.style.width = width;
      introIframe.style.height = height;
      searchIframe.style.height = height;
    }
  },
  computed: {
    videoURL() {
      let url = '';
      switch (this.$i18n.locale) {
        case 'en':
          url = 'https://www.youtube.com/embed/ZAal8aks5_Y';
          break;
        case 'bo':
          url = 'https://www.youtube.com/embed/1HQPrKdBA9s';
          break;
        default:
          url += 'https://www.youtube.com/embed/RxG1MTfh-_A';
          break;
      }
      return url;
    },
  },
  methods: {
    goDownloadFonts() {
      let i18 = '';
      switch (this.$i18n.locale) {
        case 'zh-TW':
          i18 = 'zh';
          break;
        case 'zh-CN':
          i18 = 'zhs';
          break;
        default:
          i18 = 'ens';
          break;
      }
      const goUrl = `https://ctext.org/font-test-page/${i18}`;
      window.open(goUrl, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
  #support {
    .title {
      font-size: $pageTitle;
      font-weight: bold;
      padding: $space;
    }
    > div {
      padding: $contentWrap;
    }
    > .videoArea {
      padding: 2em;
      position: relative;
      padding-bottom: calc(56.25% - 4em);
      height: 0;
      @media screen and (max-width: $mobileLandscape) {
        min-height: 5em;
      }
      iframe {
        position: absolute;
        top: 2em;
        left: 2em;
        width: calc(100% - 4em) !important;
        height: calc( 100% - 4em);
      }
    }
    .tutorial {
      padding: $contentCaption;
      background: $buttonHover02;
      background-blend-mode: multiply;
      background-position: 0px -100px;
      background-size: cover;
      background-repeat: no-repeat;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $mobileLandscape) {
        flex-direction: column;
        padding: 1em;
      }
      .videoArea {
        padding: $padding;
        min-height: 800px;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .textArea {
        padding: $padding;
        background: rgba(0, 0, 0, 0.6);
        border-radius: $radius;
        // box-shadow: $boxShadow;
        width: 50%;
        padding: $margin;
        margin: $margin;
        text-align: center;
        .subTitle {
          color: $mainThemeColor;
          font-weight: bolder;
        }
      }
    }
    .tutorial:nth-last-child(even) {
      flex-direction: row-reverse;
      background: $buttonHover01;
      @media screen and (max-width: $mobileLandscape) {
        flex-direction: column;
        padding: 1em;
      }
    }
    .problems {
      text-align: center;
      padding: $contentCaption;
      ul {
        text-align: left;
        padding: $padding;
        li {
          text-indent: -1em;
          padding: $padding;
        }
        li:before {
          padding-left: 0px;
          content: '◍';
        }
      }
    }
  }
</style>
