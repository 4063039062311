<template>
  <div id="entryInfo">
    <Loading v-if="!entryList" marginTop="5em"/>
    <div v-if="entryList">
      <div class="dictionary">
        <span v-if="dictionaryName">
          {{dictionaryName.otherName[this.$i18n.locale]}}
        </span>
        <!-- <div class="originalDict">
          <span v-if="entryList.page.length">{{$t('result.bookPage')}}：
            {{$t('result.pageNoF')}}{{entryListPageNumber}}{{$t('result.pageNoL')}}</span>
          <span v-if="entryListEntryNumber">
            {{$t('result.entryNoF')}}{{entryListEntryNumber}}{{$t('result.entryNoL')}}
          </span>
        </div> -->
      </div>
      <div class="mainEntry">
        <span class="caption">
          <span v-html="contentFilterDoubleQuote(entryList.text)"></span>
        </span>
        <TextToSpeech :audioIDList="arrayAudioRes" :entryWord="nowText[0]"/>
        <div>
          <el-button>
            <i class="el-icon-document-copy" @click="copyContentWords()"></i>
          </el-button>
        </div>
      </div>
      <!-- <div class="notes">
        [註解4321]<a name="C4" />
        <div class="content">
          組合字：[士/同-口]  一般字：涉   異體字：𣻣  拼音：shè  注音：ㄕㄜˋ  備註：[說文解字]【卷十一】【沝部】徒行濿水也。
        </div>
        <div class="img">
          <ImageEnlarge imgLink="https://www.iphonehacks.com/wp-content/uploads/2019/11/Anamorphic-Pro-Visual-Effects-Mac-Bundle.jpg"/>
        </div>
      </div> -->
      <div class="entryDetail">
        <div class="entryContent">
          <!-- <div class="entries" v-if="entryList.entry">
            <div v-if="entryList.entry.bo" class="entry">
              <div class="langIcon">བོད</div>
              {{entryList.entry.bo}}
            </div>
             <div v-if="entryList.entry.en && entryList.folder === 3" class="entry">
              <div class="langIcon">S</div>
              {{entryList.entry.en}}
            </div>
            <div v-if="entryList.entry.en && entryList.folder !== 3" class="entry">
              <div class="langIcon">en</div>
              {{entryList.entry.en}}
            </div>
            <div v-if="entryList.entry.sa" class="entry">
              <div class="langIcon">संस्</div>
              {{entryList.entry.sa}}
            </div>
            <div v-if="entryList.entry['zh-TW'] && $i18n.locale === 'zh-TW'" class="entry">
              <div class="langIcon">漢</div>
              {{entryList.entry['zh-TW']}}
            </div>
            <div v-if="entryList.entry['zh-CN'] && $i18n.locale !== 'zh-TW'" class="entry">
              <div class="langIcon">汉</div>
              {{entryList.entry['zh-CN']}}
            </div>
            <div v-if="entryList.entry.jp" class="entry">
              <div class="langIcon">日</div>
              {{entryList.entry.jp}}
            </div>
            <div v-if="entryList.entry['sa-en']" class="entry">
              <div class="langIcon">S</div>
              {{entryList.entry['sa-en']}}
            </div>
            <div v-if="entryList.entry['sa-bo']" class="entry">
              <div class="langIcon">སཾ</div>
              {{entryList.entry['sa-bo']}}
            </div>
          </div> -->
          <div id="tagnote24"></div>
          <div class="explain" v-if="newEntryList">
            <div v-for="(item, index) in newEntryList" :key="index">
              <div class="entries">
                {{$t('result.explanation')}}{{index+1}}：
                <div v-if="item.bo" class="entry">
                  <div class="langIcon">བོད</div>
                  <span v-html="contentFilterDoubleQuote(item.bo)"></span>
                </div>
                <div v-if="item.en" class="entry">
                  <div class="langIcon">en</div>
                  <span v-html="contentFilterDoubleQuote(item.en)"></span>
                </div>
                <div v-if="item.sa" class="entry">
                  <div class="langIcon">संस्</div>
                  <span v-html="contentFilterDoubleQuote(item.sa)"></span>
                </div>
                <!-- <div v-if="item['zh-TW']" class="entry">
                  <div class="langIcon">漢</div>
                  <span v-html="contentFilterDoubleQuote(item['zh-TW'])"></span>
                </div>
                <div v-if="item['zh-CN']" class="entry">
                  <div class="langIcon">汉</div>
                  <span v-html="contentFilterDoubleQuote(item['zh-CN')"></span>
                </div> -->
                <div v-if="item['zh-TW'] && $i18n.locale === 'zh-TW'" class="entry">
                  <div class="langIcon">漢</div>
                  <span v-html="contentFilterDoubleQuote(item['zh-TW'])"></span>
                </div>
                <div v-if="item['zh-CN'] && $i18n.locale !== 'zh-TW'" class="entry">
                  <div class="langIcon">汉</div>
                  <span v-html="contentFilterDoubleQuote(item['zh-CN'])"></span>
                </div>
                <div v-if="item['sa-en']" class="entry">
                  <div class="langIcon">S</div>
                  <span v-html="contentFilterDoubleQuote(item['sa-en'])"></span>
                </div>
                <div v-if="item['sa-bo']" class="entry">
                  <div class="langIcon">སཾ</div>
                  <span v-html="contentFilterDoubleQuote(item['sa-bo'])"></span>
                </div>
                <div v-if="item.jp" class="entry">
                  <div class="langIcon">日</div>
                  <span v-html="contentFilterDoubleQuote(item.jp)"></span>
                </div>
              </div>
              <!-- <div class="notes" v-if="item.note"> -->
              <div class="notes">
                <div>
                  <!-- <a href="#C4">[{{item.note.id}}]</a><a name="#BC4"></a> -->
                </div>
              </div>
              <div class="source">
                <div v-if="item.pos">
                  ◊ {{$t('result.partOfSpeech')}}： {{$t(`pos.pos${item.pos}`)}}
                </div>
                <div class="cat" v-if="item.categorys">
                  ◊ {{$t('result.expCategory')}}：
                  <span
                    v-for="(item, index) in item.transCategory"
                    :key="index"
                    @click="goToCategoryPage(item.path)"
                  >
                    {{item.name}}
                  </span>
                </div>
                <div v-if="item.source">
                  ◊ {{$t('result.expSource')}}： {{item.source}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pic">
          <!-- <img src="https://i.pinimg.com/originals/24/9a/bc/249abcf9ed3423ff5a4b9d7c7397f310.jpg">ss -->
        </div>
      </div>
    <span v-if="entryList.note">{{$t('result.ps')}}：</span>
    <div class="showNotes" v-for="(note, index) in entryList.note" :key="index">
      <div class="content">
        <!-- <a :href="`#tag${note.id}`">[{{note.id}}]</a> -->
        <a :href="`#tag${note.id}`">[{{index+1}}]</a>
        <a class="anchor" :id="note.id" :name="noteFilter(note.id)"></a>
        <a class="anchor" :id="index+1"></a>
        <span v-if="note.combination">
          <b>【{{$t('result.combination')}}】：</b>{{note.combination}}
        </span>
        <span v-if="note.word"><b>【{{$t('result.word')}}】：</b>{{note.word}}</span>
        <span v-if="note.variant"><b>【{{$t('result.variant')}}】：</b>{{note.variant}}</span>
        <span v-if="note.pinyin"><b>【{{$t('result.pinyin')}}】：</b>{{note.pinyin}}</span>
        <span v-if="note.phonetic"><b>【{{$t('result.phonetic')}}】：</b>{{note.phonetic}}</span>
        <span v-if="note.note"><b>【{{$t('result.note')}}】：</b>{{note.note}}</span>
      </div>
      <div class="img" v-if="note.img">
        <!-- {{item.note.img}} -->
        <ImageEnlarge :imgLink="`https://files.dharma-treasure.org/${note.img}`"/>
      </div>
    </div>
    </div>
    <div v-if="entryList" id="copyContentWords">
      <p v-if="dictionaryName">

        {{dictionaryName.otherName[this.$i18n.locale]}}
      </p>
      <p class="caption">
        <strong>{{entryList.text}}</strong>
      </p>
      <div v-if="newEntryList">
        <div v-for="(item, index) in newEntryList" :key="index">
          <div class="entries">
            {{$t('result.explanation')}}{{index+1}}：
            <div v-if="item.bo" class="entry">
              [བོད]
              <span v-html="contentFilterDoubleQuote(item.bo)"></span>
            </div>
            <div v-if="item.en" class="entry">
              [en]
              <span v-html="contentFilterDoubleQuote(item.en)"></span>
            </div>
            <div v-if="item.sa" class="entry">
              [संस्]
              <span v-html="contentFilterDoubleQuote(item.sa)"></span>
            </div>
            <div v-if="item['zh-TW'] && $i18n.locale === 'zh-TW'" class="entry">
              [漢]
              <span v-html="contentFilterDoubleQuote(item['zh-TW'])"></span>
            </div>
            <div v-if="item['zh-CN'] && $i18n.locale !== 'zh-TW'" class="entry">
              [汉]
              <span v-html="contentFilterDoubleQuote(item['zh-CN'])"></span>
            </div>
            <div v-if="item['sa-en']" class="entry">
              [S]
              <span v-html="contentFilterDoubleQuote(item['sa-en'])"></span>
            </div>
            <div v-if="item['sa-bo']" class="entry">
              [སཾ]
              <span v-html="contentFilterDoubleQuote(item['sa-bo'])"></span>
            </div>
            <div v-if="item.jp" class="entry">
              [日]
              {{item.jp}}
              <span v-html="contentFilterDoubleQuote(item.jp)"></span>
            </div>
          </div>
          <div>
            <div v-if="item.pos">
              ◊ {{$t('result.partOfSpeech')}}： {{$t(`pos.pos${item.pos}`)}}
            </div>
            <div v-if="item.categorys">
              ◊ {{$t('result.expCategory')}}：
              <span
                v-for="(item, index) in item.transCategory"
                :key="index"
                @click="goToCategoryPage(item.path)"
              >
                {{item.name}}
              </span>
            </div>
            <div v-if="item.source">
              ◊ {{$t('result.expSource')}}： {{item.source}}
            </div>
          </div>
          <br/>
        </div>
      </div>
      <div v-for="(note, index) in entryList.note" :key="index">
        <span v-if="note.combination">組合字：{{note.combination}}<br/></span>
        <span v-if="note.word">一般字：{{note.word}}<br/></span>
        <span v-if="note.variant">異體字：{{note.variant}}<br/></span>
        <span v-if="note.pinyin">拼音：{{note.pinyin}}<br/></span>
        <span v-if="note.phonetic">注音：{{note.phonetic}}<br/></span>
        <span v-if="note.note">備註：{{note.note}}<br/></span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-eval: 0 */
import searchAPI from '@/assets/api/search';
import entryAPI from '@/assets/api/entry';
import Loading from './Loading.vue';
import TextToSpeech from './TextToSpeech.vue';
import ImageEnlarge from './ImageEnlarge.vue';

export default {
  name: 'EntryInfo',
  data() {
    return {
      entryList: null,
      entryListJson: 'null',
      audioIDs: null,
      audioResBack: [],
      noteArray: [],
      noteNumber: 1,
    };
  },
  components: {
    Loading,
    TextToSpeech,
    ImageEnlarge,
  },
  computed: {
    arrayAudioRes() {
      return this.audioResBack;
    },
    arrayAudioResForCombined() {
      // 第一版聲音combind方式
      if (this.audioResBack) {
        const resBack = JSON.parse(JSON.stringify(this.audioResBack));
        const resArray = resBack.filter((item) => item.id > 0);
        const wordArray = this.arrangeAudioInfo(this.entryList.text, true);
        const filterArray = [];
        for (let i = 0; i < wordArray.length; i += 1) {
          const theWord = wordArray[i];
          const idNumber = resArray.filter((item) => item.lyrics === theWord);
          if (idNumber.length) {
            filterArray[filterArray.length] = (idNumber[0].id);
          }
        }
        return filterArray;
      }
      return null;
    },
    dictionaryList() {
      if (this.entryList) {
        return this.$store.state.dictionary.dicList;
      }
      return this.$store.state.dictionary.dicList;
    },
    dictionaryName: {
      get() {
        if (this.entryList && this.dictionaryList) {
          return this.dictionaryList.find((dict) => (dict.id === this.entryList.folder));
        }
        return null;
      },
      set() {
      },
    },
    entryListPageNumber: {
      get() {
        const result = null;
        if (this.entryList.page && this.entryList.page.length) {
          const dot = /[.]/;
          const checkpoint = this.entryList.page.search(dot);
          return (checkpoint < 0)
            ? this.entryList.page : this.entryList.page.substr(0, checkpoint);
        }
        return result;
      },
      set() {
      },
    },
    entryListEntryNumber: {
      get() {
        const result = 'null';
        if (this.entryList.page && this.entryList.page.length) {
          const dot = /[.]/;
          const checkpoint = this.entryList.page.search(dot);
          return (checkpoint < 0)
            ? null : this.entryList.page.substr((checkpoint + 1), this.entryList.length);
        }
        return result;
      },
      set() {
      },
    },
    nowFolder() {
      return [this.$route.params.entryFolder];
    },
    nowText() {
      return [this.$route.params.entryText];
    },
    newEntryList: {
      get() {
        let result = null;
        if (this.catListContrast) {
          result = this.entryListRerrange(this.entryList.list, this.catListContrast);
        }
        return result;
      },
      set() {
      },
    },
    catListContrast: {
      get() {
        return this.$store.state.category.catListContrast;
      },
      set() {
      },
    },
  },
  watch: {
    entryList(val) {
      if (val.page && val.page.length) {
        const dot = /[.]/;
        const checkpoint = val.page.search(dot);
        this.entryListPageNumber = (checkpoint < 0)
          ? val : val.page.substr(0, checkpoint);
        this.entryListEntryNumber = (checkpoint < 0)
          ? null : val.page.substr((checkpoint + 1), val.length);
      }
    },
    nowFolder(val) {
      this.getEntryInfo(val, this.nowText);
    },
    nowText(val) {
      this.getEntryInfo(this.nowFolder, val);
    },
    catListContrast(val) {
      if (this.entryList && this.entryList.list) {
        this.newEntryList = this.entryListRerrange(this.entryList.list, val);
      }
    },
  },
  mounted() {
    this.getEntryInfo(this.nowFolder, this.nowText);
  },
  beforeUpdate() {
    this.noteNumber = 1;
  },
  methods: {
    contentFilterDoubleQuote(content) {
      let result = this.changeNoteFlag(content);
      // result = result.replace(/\[.*?\]/gi, '[<h1></h1>]');
      if (this.noteArray && this.noteArray.length > 0) {
        for (let i = 0; i < this.noteArray.length; i += 1) {
          const word = this.noteArray[i].item.substr(1, (this.noteArray[i].item.length - 2));
          const reg = `/${word}/gi`;
          result = result.replace(eval(reg), `${this.noteArray[i].number}`);
        }
      }
      return result;
    },
    changeNoteFlag(content) {
      let result = content.replace(/“|”/gi, '"');
      result = result.replace(/name=/gi, 'class="anchor" id=');
      const regex = (/\[.*?\]/gi);
      const newText = result.match(regex);
      if (newText && newText.length > 0) {
        for (let i = 0; i < newText.length; i += 1) {
          const checkNote = this.noteArray.find((note) => note.item === newText[i]);
          if (checkNote === undefined) {
            this.noteArray.push({ item: newText[i], number: this.noteNumber });
            this.noteNumber += 1;
          }
        }
      }
      // result = result.replace(/\[.*?\]/gi, '[<h1></h1>]');
      return result;
    },
    noteFilter(content) {
      let result = content.replace(/\[note_san_/gi, '[');
      result = result.replace(/\[note/gi, '[');
      return result;
    },
    copyContentWords() {
      const node = document.getElementById('copyContentWords');
      if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
        document.execCommand('copy');
        this.$message({
          message: this.$t('elements.copysuccess'),
          center: true,
        });
      } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        this.$message({
          message: this.$t('elements.copysuccess'),
          center: true,
        });
      } else {
        this.$message({
          message: this.$t('elements.copyfailed'),
          center: true,
        });
      }
    },
    goToCategoryPage(data) {
      if (data) {
        const goUrl = this.$router.resolve({ name: 'CategoryResult', params: { path: data.replace(/\//g, '%2F') } });
        window.open(goUrl.href, '_blank');
      }
    },
    entryListRerrange(listData, catListContrastData) {
      const data = [...listData];
      const catListContrast = catListContrastData;
      const checkHasCategory = data.find((item) => 'categorys' in item) || null;
      if (checkHasCategory) {
        const newList = data.map((item) => {
          const newItem = { ...item };
          if (item.categorys) {
            const transCategory = item.categorys.map((itemPath) => {
              if (itemPath !== null) {
                const contrastName = catListContrast.find((itemCat) => itemPath === itemCat.path);
                return contrastName || '';
              }
              return '';
            });
            newItem.transCategory = transCategory;
            return newItem;
          }
          return newItem;
        });
        return newList;
      }
      return data;
    },
    getEntryInfo(entryFolder, entryText) {
      searchAPI.postGetEntryInfo((res) => {
        this.entryList = res;
        this.arrangeAudioInfo(res.text, false);
        this.entryListJson = JSON.stringify(res, null, 2);
      }, entryFolder, entryText);
    },
    arrangeAudioInfo(inputWord, onlyArrange) {
      const strs = [];
      if (inputWord.substr((inputWord.length - 2), 2) === '་།') {
        strs.push(inputWord.replace(/(.*)་།/, '$1'));
      } else if (inputWord.substr((inputWord.length - 1), 1) === '།') {
        strs.push(inputWord.replace(/(.*)།/, '$1'));
      } else {
        strs.push(inputWord);
      }

      const words = inputWord.replace(/[་།]/ig, ' ');
      let result = words.split(/་| /);
      result = (result[(result.length - 1)]) === '' ? result.splice(0, (result.length - 1)) : result;
      if (onlyArrange) {
        return result;
      }
      this.getAudioInfoList(strs);
      return null;
    },
    arrangeAudioInfoForCombined(inputWord, onlyArrange) {
      // 第一版聲音combind方式
      const words = inputWord.replace(/[།]/ig, ' ');
      let result = words.split(/་| /);
      result = (result[(result.length - 1)]) === '' ? result.splice(0, (result.length - 1)) : result;
      if (onlyArrange) {
        return result;
      }
      this.getAudioInfoList(result);
      return null;
    },
    getAudioInfoList(strings) {
      entryAPI.getListAudioID((res) => {
        this.audioResBack = [];
        const id = JSON.parse(JSON.stringify(res[0].id));
        this.audioResBack.push(id.toString());
      }, strings);
    },
    getAudioInfoListForCombined(strings) {
      // 第一版聲音combind方式
      entryAPI.getListAudioID((res) => {
        this.audioResBack = res;
      }, strings);
    },
  },
};
</script>

<style lang="scss" scoped>
#entryInfo {
  // background-image: url("./../assets/img/picture/thom.png");
  // background-size: 100%;
  // background-position-y: bottom;
  // background-repeat: no-repeat;
  min-height: 70vh;
  @media screen and (max-width: $mobileLandscape) {
    background-size: 180%;
  }
  ::v-deep .anchor {
    position: relative;
    top: -15em;
    display: block;
    height: 0px;
    overflow: hidden;
  }
  line-height: 1em;
  font-size: 0.95em;
  .showNotes {
    .content {
      padding-top: 0.5em;
      span {
        padding-right: 1em;
      }
    }
  }
  #copyContentWords {
    opacity: 0;
    position: absolute;
    z-index: -9999;
    pointer-events: none;
  }
  .dictionary {
    padding-bottom: $gapHeight;
    .originalDict {
      font-size: $iconFontSize;
      color: $psTextColor;
    }
  }
  .mainEntry {
    border-bottom: 1px solid $border-color;
    margin-bottom: $gapHeight;
    display: flex;
    align-items: center;
    .caption {
      font-size: $contentCaption;
      font-weight: bolder;
      padding-right: 0.3em;
      flex: initial;
    }
    .material-icons {
      cursor: pointer;
    }
    div, span {
      flex: 1;
    }
    .el-button {
      flex: 1;
      float: right;
      background: transparent;
      border: none;
      font-size: 1em;
      padding: 0px;
      margin: 0px;
      @media screen and (max-width: $mobilePortrait) {
        clear: both;
      }
    }
    .el-button.el-button--default:hover {
      background: transparent;
    }
  }
  .entryDetail {
    display: flex;
    flex-direction: row;
    margin-bottom: $gapHeight;
    .pos {
      font-size: $psFontSize;
    }
    .entryContent{
      flex: 1;
      > .entries {
        margin-bottom: $gapHeight;
        .entry {
          display: flex;
          clear: both;
          padding: $lineHeight;
          line-height: 1.5em;
          .langIcon {
            line-height: 1.3em;
          }
        }
      }
      .explain {
        // border-top: 3px solid $buttonTransparent02;
        counter-reset: noteIt;
        .entries {
          padding-top: $gapHeight;
          .entry {
            display: flex;
            clear: both;
            padding: $lineHeight;
            line-height: 1.5em;
            .langIcon {
              line-height: 1.3em;
            }
            ::v-deep h1:before {
              content: counter(noteIt);
              clear: both;
            }
            ::v-deep h1 {
              counter-increment: noteIt 1;
              clear: both;
              line-height: 1em;
              font-size: 1em;
              display: inherit;
            }
          }
        }
        .source {
          background: $psBgColor;
          padding: $psPadding;
          margin: $lineHeight;
          font-size: $psFontSize;
          div {
            margin-bottom: 0.3em;
            padding-right: 1em;
          }
          > span {
            padding-right: 1em;
          }
          .cat {
            clear: both;
            span {
              cursor: pointer;
              margin-right: 0.2em;
            }
            span:first-child {
              margin-right: 0px;
            }
            span:after {
              cursor: auto;
              content: "/";
              margin-left: 0.2em;
            }
            span:first-child:after {
              margin-left: 0px;
              content: "";
            }
            span:last-child:after {
              content: "";
            }
          }
        }
      }
    }
    .pic {
      flex: init;
      max-width: calc(20% + #{$contentWrap});
      img {
        width: calc(100% - #{$contentWrap});
        padding-left: $contentWrap;
      }
    }
  }
}
</style>
