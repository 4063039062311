<template>
  <div id="alphabetTable">
    <div class="breadcrumb">
      <li><router-link to="/alphabet">{{$t('menu.alphabet')}}</router-link></li>
      <li>
        {{$t(`${nowAlphabetLang}`)}}
      </li>
    </div>
    <div v-if="alphabetLang !== 'en'" class="row">
      <div v-for="(alphabet, index) in alphabetTable" :key="index">
        <router-link :to="`/alphabet/${alphabetLang}/${alphabet.key}`">
          <el-button >
          {{alphabet.key}}
          </el-button>
        </router-link>
      </div>
    </div>
    <div v-if="alphabetLang === 'en'" class="row">
      <div v-for="(alphabet, index) in alphabetTable" :key="index">
        <router-link :to="`/alphabet/${alphabetLang}/${alphabet.key}/result/index/index`">
          <el-button >
          {{alphabet.key}}
          </el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AlphabetTable',
  data() {
    return {
      alphabetTable: null,
      alphabetLang: this.$route.params.alphabetLang,
    };
  },
  components: {
  },
  mounted() {
    let lang = this.alphabetLang;

    lang = `../alphabet/${lang}.json`;

    axios.get(lang)
      .then((res) => {
        this.alphabetTable = res.data;
      }, (error) => {
        console.log('get-error', error);
      });
  },
  computed: {
    nowAlphabetLang() {
      let nowAlphabetLang = '';
      switch (this.$route.params.alphabetLang) {
        case 'zh-TW':
          nowAlphabetLang = 'alphabet.traditionalChinese';
          break;
        case 'zh-CN':
          nowAlphabetLang = 'alphabet.simpleChinese';
          break;
        case 'bo':
          nowAlphabetLang = 'alphabet.tibetan';
          break;
        default:
          nowAlphabetLang = 'alphabet.english';
      }
      return nowAlphabetLang;
    },
  },
};
</script>

<style scoped lang="scss">
  #alphabetTable {
    padding: $wrapWidth;
    ::v-deep .el-button:hover {
      border-color: $buttonTransparent02;
      background-color: $buttonHover02;
    }
    .row {
      display: flex;
      flex-wrap: wrap;
      margin: $margin;
      max-width: 100%;
      justify-content: center;
      @media screen and (max-width: $mobileLandscape) {
        margin: 0px;
        margin-top: $margin;
      }
      .el-button {
        margin: 0.5em;
        font-size: $logoTitle;
        @media screen and (max-width: $mobileLandscape) {
          margin: 0.3em;
        }
      }
    }
    .breadcrumb {
      background: $shadow;
      padding: $padding;
      margin: $margin;
      margin-top: 0px;
      margin-bottom: $gapHeight;
      display: flex;
      line-height: 1em;
      flex-direction: row;
      @media screen and (max-width: $mobileLandscape) {
        width: 100%;
        padding: 0.3em;
        margin: 0px;
      }
      li {
        list-style: none;
        padding-right: $contentWrap;
        :after {
          padding-left: $contentWrap;
          content: '/';
        }
      }
      li:last-child {
        :after {
          content: '';
        }
      }
    }
  }
</style>
