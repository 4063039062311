<template>
  <div id="entryResult">
    <div v-if="resultList && !resultList.length">
      <div class="caption">
        {{$t('advancedSearch.noResult')}}
      </div>
    </div>
    <div class="mainContent" v-if="resultList && resultList.length">
      <div class="caption">
        {{$t('advancedSearch.result')}}
      </div>
      <li
        v-for="(result, index) in resultList"
        :key="index"
        :class="{
          'hightLight': nowRouterFolder.toString() === result.folder.toString()
            && nowRouterText.toString() === result.text.toString()
        }"
        @click="showResult(result.folder, result.text, result.id)"
      >
        <span class="caption">{{result.text}}</span>
        <div class="psTrans">
          <span
            v-if="
            $i18n.locale === 'zh-TW'||$i18n.locale === 'zh-HK'||
            $i18n.locale === 'zh-CN'||$i18n.locale === 'bo'||
            $i18n.locale === 'zh-tw'||$i18n.locale === 'zh-hk'||
            $i18n.locale === 'zh-cn'
            "
          >
            ── {{result.folderName18[$i18n.locale]}}
          </span>
          <span v-else>
            ── {{result.folderName18['en']}}
          </span>
          <!-- <span v-if="result.en">{{result.en}}</span>
          <span v-if="result.bo">{{result.bo}}</span>
          <span v-if="result.sa">{{result.sa}}</span>
          <span v-if="result['zh-CN'] && $i18n.locale === 'zh-CN'">{{result['zh-CN']}}</span>
          <span v-if="result['zh-TW'] || $i18n.locale !== 'zh-CN'">
            {{result['zh-TW']}}
          </span>
          <span v-if="result.ja">{{result.ja}}</span> -->
        </div>
      </li>
      <div class="pages">
        <Pagination :totalPage="totoalAmount" @goPage="changePagination"/>
      </div>
    </div>
  </div>
</template>

<script>
import searchAPI from '@/assets/api/search';
import Pagination from './Pagination.vue';

export default {
  name: 'EntryResult',
  data() {
    return {
      resultList: null,
      nowRouteFolder: null,
      nowRouteText: null,
      nowPageStart: 0,
      isLast: false,
      totoalAmount: null,
    };
  },
  components: {
    Pagination,
  },
  computed: {
    nowKeyword() {
      return [this.$route.params.searchKWord];
    },
    nowRouterFolder() {
      return [this.$route.params.entryFolder];
    },
    nowRouterText() {
      return [this.$route.params.entryText];
    },
    startNumber() {
      return this.nowPageStart * 15;
    },
    dictionaryList() {
      if (this.entryList) {
        return this.$store.state.dictionary.dicList;
      }
      return this.$store.state.dictionary.dicList;
    },
  },
  watch: {
    nowKeyword(val) {
      // this.changePagination(1);
      this.getEntrySearch(val);
    },
  },
  created() {
    this.nowRouteFolder = this.$route.params.entryFolder;
    this.nowRouteText = this.$route.params.entryText;
  },
  mounted() {
    this.getEntrySearch(this.nowKeyword);
  },
  methods: {
    changePagination(pageNo) {
      this.nowPageStart = pageNo - 1;
      this.getEntrySearch(this.nowKeyword);
    },
    changePage(jump) {
      if (jump === 'pre') {
        this.nowPageStart -= 1;
      } else {
        this.nowPageStart += 1;
      }
      this.getEntrySearch(this.nowKeyword);
    },
    showResult(entryFolder, entryText, entryID) {
      this.$emit('changeEntryMobile');
      this.nowRouteFolder = entryFolder;
      this.nowRouteText = entryText;
      searchAPI.postEntrySearch((res) => {
        this.entryList = res;
        if (entryText.toString() !== this.$route.params.entryText.toString()
        || entryFolder.toString() !== this.$route.params.entryFolder.toString()) {
          this.$router.push({
            name: 'EntryAdv',
            params: {
              entryText,
              entryFolder,
              searchKWord: this.nowKeyword[0],
            },
          });
        }
      }, 'Entry', entryID, this.$store.state.dictionary.nowDicRange,
      this.$store.state.category.advCatRange, this.$i18n.locale, this.startNumber);
    },
    getEntrySearch(nowKeyword) {
      searchAPI.postEntrySearch((res) => {
        if (res.list.length) {
          this.$emit('entryTotal', res.total);
        } else {
          this.$emit('entryTotal', 0);
        }
        if (res.total) {
          this.totoalAmount = res.total;
        }
        const resArr = JSON.parse(JSON.stringify(res.list));

        const newRessult = [];
        resArr.forEach((el) => {
          const folderName = this.findDictionaryName(el.folder);
          const newObj = el;
          newObj.folderName18 = folderName;
          newRessult.push(newObj);
        });

        this.resultList = newRessult;
      }, 'Entry', nowKeyword[0], this.$store.state.dictionary.nowDicRange,
      this.$store.state.category.nowCatRange, this.$i18n.locale, this.startNumber);
    },
    findDictionaryName(dictID) {
      if (dictID) {
        return this.dictionaryList.find((dict) => (dict.id === dictID)).otherName;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
  #entryResult {
    .caption {
      font-size: $contentCaption;
      padding: $padding;
      margin: $lineHeight;
      margin-bottom: 1em;
    }
    .total {
      font-size: $psFontSize;
      padding-left: calc(#{$contentWrap * 2})
    }
    .mainContent {
      li {
        list-style: none;
        cursor: pointer;
        padding: $padding;
        margin: $contentWrap;
        padding-top: 1em;
        .caption {
          font-size: $logoTitle;
          padding: 0px;
        }
        .psTrans {
          color: $psTextColor;
          padding-top: 0.7em;
          span::after {
            content: '/';
          }
          span:last-child::after {
            content: '';
          }
        }
      }
      li:hover {
        background: $buttonHover02;
      }
      .pages {
        display: flex;
        margin-top: $gapHeight;
        padding: $padding;
        .el-button {
          flex:  1;
        }
        .el-button:hover {
          border-color: $buttonTransparent02;
          background-color: $buttonHover02;
        }
      }
    }
  }
</style>
