<template>
  <div id="errorReport">
    <button class="openChatBtn" @click="openForm = true; nowClick = 0">
      <span class="mobileNoShow">
        {{$t('errorReport.feedback')}} ▲</span>
      <span class="deskNoShow">
        <i class="el-icon-question"></i>
      </span>
    </button>
    <div class="chatArea closeIt" :class="{'openIt': openForm}">
      <div class="content">
        <div class="topArea">
        <div class="closeIcon">
          <i class="el-icon-circle-close" @click="openForm = false"></i>
        </div>

        <div class="window" :class="{'closeIt': nowClick !== 0}">
          <ul>
            <li @click="nowClick = 3">{{$t('errorReport.problem')}}</li>
            <li @click="nowClick = 1">{{$t('errorReport.unshownZh')}}</li>
            <li @click="nowClick = 2">{{$t('errorReport.otherProblem')}}</li>
          </ul>
        </div>

        <div class="window closeIt" :class="{'openIt': nowClick === 1}">
          <p>
            {{$t('errorReport.fixUnshownZh1')}}
            <span class="link" @click="goDownloadFonts()">
              {{$t('errorReport.fixUnshownZh2')}}
            </span>{{$t('errorReport.fixUnshownZh3')}}
          <p/>
        </div>
        <div class="window closeIt" :class="{'openIt': nowClick === 2}">
          <el-form>
            <input :placeholder="$t('errorReport.name')" v-model="reportName"/>
            <input :placeholder="$t('errorReport.contactWay')" v-model="reportContact"/>
            <textarea :placeholder="$t('errorReport.submitProblem')"
              v-model="reportContent" required>
            </textarea>
            <el-button type="submit" @click="sendIt()"
              :loading="sending" class="btn effect effectAnime"
            >
              <span v-if="!sending">{{$t('errorReport.send')}}</span>
              <span v-if="sending">傳送中</span>
            </el-button>
          </el-form>
        </div>
        <div class="window closeIt" :class="{'openIt': nowClick === 3}">
          <el-form>
            <input :placeholder="$t('errorReport.name')" v-model="reportName"/>
            <input :placeholder="$t('errorReport.contactWay')" v-model="reportContact"/>
            <textarea :placeholder="$t('errorReport.submitProblem')"
              v-model="reportContent" required>
            </textarea>
            <el-button type="submit" class="btn effect effectAnime"
            :loading="sending" @click="sendIt()">
              <span v-if="!sending">{{$t('errorReport.send')}}</span>
              <span v-if="sending">傳送中</span>
            </el-button>
          </el-form>
        </div>

        </div>
        <div class="closeButtons">
          <button
            class="closeChatBtn backColor"
            :class="{'closeIt': nowClick === 0}"
            @click="nowClick = 0"
          >
            {{$t('errorReport.returnForm')}}
          </button>
          <button type="button" class="closeChatBtn"
            :class="{'closeIt': nowClick !== 0}" @click="openForm = false"
          >
          {{$t('errorReport.feedback')}} ▼
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import reportAPI from '@/assets/api/other';

export default {
  name: 'BackButton',
  props: {
    entryID: {
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      sending: false,
      openForm: false,
      nowClick: 0,
      reportName: this.$store.state.userSettings.reportName,
      reportContact: this.$store.state.userSettings.reportContact,
      reportContent: null,
      isDesktop: true,
      thedeviceHeight: '0',
    };
  },
  mounted() {
    const deviceWidth = document.documentElement.clientWidth;
    this.thedeviceHeight = deviceWidth;
    if (deviceWidth < 768) {
      this.isDesktop = false;
    }
  },
  methods: {
    sendIt() {
      if (this.sending === false) {
        this.sending = true;
        this.handleSubmit();
      }
      this.$store.dispatch('userSettings/setReportName', this.reportName);
      this.$store.dispatch('userSettings/setReportContact', this.reportContact);
    },
    handleSubmit() {
      reportAPI.errorReport((res) => {
        switch (res) {
          case -1:
            this.$notify.error({
              message: '回報發送失敗',
              position: 'bottom-right',
              offset: 0,
            });
            break;
          case -5:
            this.$notify({
              message: '回報時間過短，請稍後再試',
              type: 'warning',
              position: 'bottom-right',
              offset: 0,
            });
            break;
          default:
            if (this.isDesktop) {
              this.$notify({
                // message: this.$t('errorReport.feedback'),
                message: '回報發送成功',
                customClass: 'errorMessage',
                dangerouslyUseHTMLString: true,
                type: 'success',
                position: 'bottom-right',
                offset: 100,
              });
            } else {
              this.$message({
                message: '回報發送成功',
                center: true,
                offset: 200,
                duration: 1500,
              });
            }
            break;
        }
        this.nowClick = 0;
        this.reportContent = null;
        this.sending = false;
      }, this.reportName, this.reportContact, this.reportContent, this.entryID);
    },
    goDownloadFonts() {
      let i18 = '';
      switch (this.$i18n.locale) {
        case 'zh-TW':
          i18 = 'zh';
          break;
        case 'zh-CN':
          i18 = 'zhs';
          break;
        default:
          i18 = 'ens';
          break;
      }
      const goUrl = `https://ctext.org/font-test-page/${i18}`;
      window.open(goUrl, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
#errorReport {
  .el-notification.errorMessage.right {
    background: purple !important;
    color: white;
    span {
      background: green !important;
      color: yellow;
    }
  }
  .mobileNoShow {
    display: unset;
    @media screen and (max-width: $mobilePortrait) {
      display: none;
    }
  }
  .deskNoShow {
    display: none;
    @media screen and (max-width: $mobilePortrait) {
      display: unset;
    }
  }
  ul {
    margin: 1em;
    li {
      text-indent: calc(-1em - 5px);
    }
    li:before {
      padding-left: 0px;
      padding-right: 5px;
      content: '◍';
    }
  }
  .closeIt {
    display: none;
  }
  .openIt {
    display: block;
  }
  .openChatBtn {
    background-color: $mainThemeColor;
    color: white;
    font-weight: 500;
    cursor: pointer;
    position: fixed;
    border: none;
    bottom: 50px;
    right: 0px;
    width: 280px;
    padding: 1em;
    @media screen and (max-width: $mobilePortrait) {
      opacity: 0.8;
      width: 5em;
      bottom: 10%;
      background-color: transparent;
      color: $mainThemeColor;;
      i {
        font-size: 2em;
      }
    }
  }
  .chatArea {
    position: fixed;
    border: none;
    bottom: 3em;
    right: 0px;
    width: 280px;
    @media screen and (max-width: $mobilePortrait) {
      width: 100%;
      top: 0px;
    }
  }
  .content {
    // background-color: $psBgColor;
    background-color: #eae6e6;
    cursor: pointer;
    position: relative;
    border: none;
    bottom:0px;
    right: 0px;
    width: calc(280px - 2em);
    padding: 1em;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    height: 45vh;
    @media screen and (max-width: $mobilePortrait) {
      min-height: 100%;
      padding: 0px;
      width: 100%;
      font-size: 1.4em;
    }
    .closeIcon {
      text-align: right;
      margin-right: -0.5em;
      margin-top: -0.5em;
      padding-top: 0.5em;
      padding-right: 0.5em;
      font-size: 1.6em;
    }
    .window {
      overflow: scroll;
      font-size: 0.8em;
      ul {
        li {
          padding: 0.4em;
        }
        li:hover {
          background-color: $buttonHover01;
        }
      }
      .effectAnime:before {
        color: $buttonHover;
      }
    }
    .topArea {
      overflow: scroll;
      width: 100%;
      @media screen and (max-width: $mobilePortrait) {
        flex: 1;
        padding: 1em;
        width: calc(100% - 2em);
      }
    }
    .closeButtons {
      color: white;
      font-weight: 500;
      cursor: pointer;
      position: absolute;
      border: none;
      bottom:0px;
      right: 0px;
      width: 280px;
      background: $mainThemeColor;
      @media screen and (max-width: $mobilePortrait) {
        width: 100%;
        flex: initial;
      }
      .closeChatBtn {
        background-color: $mainThemeColor;
        color: white;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        border: none;
        bottom:0px;
        right: 0px;
        width: 280px;
        padding: 1em;
        @media screen and (max-width: $mobilePortrait) {
          width: 100%;
        font-size: 0.8em;
        }
      }
      .backColor {
        background-color: $buttonTransparent01;
      }
    }
    .btn.effect.effectAnime {
      border-radius: 0px;
      background-color: $mainBgColor;
      opacity: 0.7;
    }
    .effectAnime:before {
      content: ' ';
    }
    .btn.effect.effectAnime:hover {
      opacity: 1;
    }
    .btn.effect.effectAnime:hover:before {
      text-indent: -0.5em;
      display: inline-block;
      position: relative;
      content: '↳';
      color: $mainTextColor;
      font-weight: normal;
      padding-right: 0.5em;
    }
    form {
      display: flex;
      flex-direction: column;
      input {
        font-size: initial;
        margin-top: 0.5em;
        border: none;
        border-bottom: 1px solid $buttonHover01;
        padding: 10px;
        background-color: $mainBgColor;
        color: $mainTextColor;
        opacity: 0.7;
      }
      input:focus {
        outline: none;
        opacity: 1;
      }
      textarea {
        font-size: initial;
        padding: 15px;
        border: none;
        resize: none;
        min-height: 150px;
        margin-top: 1em;
        background-color: $mainBgColor;
        color: $mainTextColor;
        opacity: 0.7;
      }
      textarea:focus {
        outline: none;
        opacity: 1;
      }
      .btn {
        background-color: $mainBgColor;
        color: $psTextColor;
        font-weight: bolder;
        padding: 1em;
        border: none;
        cursor: pointer;
        border-top: $psBgColor 1px solid;
      }
      .btn.effect.effectAnime:hover {
        background: $buttonTransparent02;
        color: $mainTextColor;
      }
    }
  }
}
</style>
