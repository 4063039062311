<template>
  <div id="login">
    <div class="loginPanel">
      <el-form :model="userLoginForm" :rules="loginRules"
        ref="userLoginForm"
        label-position="left"
        label-width="0px"
        class="demo-ruleForm login-page"
      >
        <h3 class="title">員工登錄</h3>
        <el-form-item prop="userAccount">
          <el-input type="text"
            v-model="userLoginForm.userAccount"
            auto-complete="off"
            placeholder="Email"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password"
            v-model="userLoginForm.password"
            auto-complete="off"
            placeholder="密碼"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button
            type="primary"
            style="width:100%;"
            @click.native="handleSubmit" :loading="logining"
          >
            登錄
          </el-button>
        </el-form-item>
        <el-form-item style="width:30%;">
          <el-button
            type="primary"
            style="width:100%;"
            @click.native="resetData"
          >
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import qs from 'qs';

export default {
  name: 'Login',
  data() {
    return {
      logining: false,
      userLoginForm: {
        userAccount: '',
        password: '',
      },
      loginRules: {
        userAccount: [{ required: true, message: '帳號不能爲空', trigger: 'blur' }],
        password: [{ required: true, message: '密碼不能爲空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    handleSubmit() {
      const that = this;

      const urlData = 'api/login';
      const requestBody = {
        pa: `{"ac": ${this.userLoginForm.userAccount},"pw":${this.userLoginForm.password}`,
      };
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
        },
      };

      this.$axios.post(urlData, qs.stringify(requestBody), config)
        .then((res) => {
          // console.log(arg.data.token);
          if (res.data.code === 1000) {
            that.$store.commit('saveToken', {
              token: res.data.token,
              userAccount: that.userLoginForm.userAccount,
              nickname: res.data.nickname,
            });
            const url = that.$route.query.backUrl;
            if (url) {
              that.$router.push({ path: url });
            } else {
              that.$router.push({ path: '/Home' });
            }

            that.$message({
              message: '登陸成功',
              type: 'success',
              center: true,
            });
          } else {
            that.$message({
              message: '帳號或密碼錯誤',
              type: 'error',
              center: true,
            });
          }
        }).catch((arg) => {
          console.log('err', arg);
        });
    },
    resetData() {
      this.userLoginForm.userAccount = '';
      this.userLoginForm.password = '';
    },
  },
};
</script>

<style lang="scss" scoped>
  #login {
    .loginPanel {
      margin: auto 0;
      width: 80%;
      padding: 3em;
    }
  }
</style>
