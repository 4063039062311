import { getLanguage } from '@/i18n';

const userSettings = {
  namespaced: true,
  state: {
    language: getLanguage(),
    userId: 'Alice',
    userRole: 'inner',
    loginState: 'login state inin',
    setUserToken: 'NoSetUserToken',
    reportName: '',
    reportContact: '',
  },
  mutations: {
    SETLANGUAGE: (state, language) => {
      state.language = language;
      window.$cookies.set('language', language);
    },
    SETLOGINSTATE: (state, newState) => {
      state.loginState = newState;
    },
    SETUSERTOKEN: (state, newState) => {
      state.onlineCountToken = newState;
    },
    SETREPORTNAME: (state, newState) => {
      state.reportName = newState;
    },
    SETREPORTCONTACT: (state, newState) => {
      state.reportContact = newState;
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      commit('SETLANGUAGE', language);
    },
    setLoginState({ commit }, newState) {
      commit('SETLOGINSTATE', newState);
    },
    setUserToken({ commit }, token) {
      commit('SETUSERTOKEN', token);
    },
    setReportName({ commit }, newState) {
      commit('SETREPORTNAME', newState);
    },
    setReportContact({ commit }, newState) {
      commit('SETREPORTCONTACT', newState);
    },
  },
};

export default userSettings;
