<template>
  <div
    id="categoryResultDetail"
  >
    <div class="breadcrumb">
      <li><router-link to="/category">{{$t('menu.category')}}</router-link></li>
      <li v-for="(branch, index) in breadCrumb" :key="index">
        <router-link :to="`/category/${branch.path}`">{{branch.name}}</router-link>
      </li>
    </div>
    <Loading v-if="loadingIt" marginTop="5em"/>
    <div v-if="nowPage === 'category' && !loadingIt">
      <div v-if="!loadingIt && (categoryTreeBranch && !categoryTreeBranch.length)
        && (categoryList && !categoryList.length)"
      >
        <NoResult />
      </div>
      <div
        class="branchArea"
        v-if="categoryTreeBranch && categoryTreeBranch.length && nowPage === 'category'"
      >
        <div class="pageTitle">
          {{nowRouterName.name}}{{$t('category.category')}}
        </div>
        <div
          class="subTreeArea"
          v-for="(treeBranch, index) in categoryTreeBranch"
          :key="index"
        >
          <li
            @click="goLink(treeBranch.path)"
          >
            {{treeBranch.name}}
          </li>
          <span>➔</span>
        </div>
      </div>
      <div
        class="vocabularyArea"
        v-if="categoryList && categoryList.length && nowPage === 'category'"
        ref="vocArea"
      >
        <a name = "vocab"></a>
        <div class="pageTitle">
          <div class="title">{{nowRouterName.name}}{{$t('category.normaVocabulary')}}</div>
          <div class="total">{{$t('elements.totalNumber')}}：{{totalAmount}}</div>
        </div>
        <li
          v-for="(item, index) in categoryList"
          :key="index"
          @click="getEntryInfo(item.folder, item.text)"
        >
          <router-link :to="`/categoryEntry/${item.folder}/${item.text}/${nowRouter}`">
            {{item.text}}
          </router-link>
        </li>
        <div class="pages">
          <Pagination :totalPage="totalAmount" @goPage="changePagination"/>
        </div>
      </div>
    </div>
    <div class="entryResultArea" v-if="nowPage !== 'category'">
      <div v-if="entryList">
        <EntryResultInfo :postAPIData="postAPIData" :entryList="entryList"/>
      </div>
      <div v-if="entryList && entryList.code">
        <NoResult />
      </div>
    </div>
  </div>
</template>

<script>
import searchAPI from '@/assets/api/search';
import categoryAPI from '@/assets/api/category';
import NoResult from './NoResult.vue';
import EntryResultInfo from './EntryResultInfo.vue';
import Loading from './Loading.vue';
import Pagination from './Pagination.vue';

export default {
  name: 'CategoryResultDetail',
  data() {
    return {
      nowPageStart: 0,
      islast: false,
      categoryList: null,
      postAPIData: null,
      entryList: null,
      totalAmount: null,
    };
  },
  components: {
    NoResult,
    EntryResultInfo,
    Loading,
    Pagination,
  },
  created() {
  },
  mounted() {
    this.getCategoryList(this.nowRouter);
    if (this.nowPage === 'categoryEntry') {
      this.getEntryInfo(
        this.$route.params.entryFolder, this.$route.params.entryText,
      );
    }
  },
  computed: {
    loadingIt: {
      get() {
        if (this.nowPage === 'category') {
          return !(this.categoryTreeBranch && this.categoryList);
        }
        return !this.entryList;
      },
      set() {
      },
    },
    categoryListAPI() {
      return this.$store.state.category.arrCatList;
    },
    nowDicRange() {
      return this.$store.state.dictionary.nowDicRange;
    },
    categoryTreeBranch: {
      get() {
        return this.$store.state.category.treeBranch;
      },
      set() {
      },
    },
    nowRouter() {
      return this.$route.params.path;
    },
    nowPage() {
      const lastIndex = this.$route.path.substr(1, this.$route.path.length).search('/');
      return this.$route.path.substr(1, lastIndex);
    },
    nowRouterName: {
      get() {
        let result = null;
        if (this.catListContrast) {
          result = this.findContrastName(this.nowRouter, this.catListContrast, false);
        }
        return result;
      },
      set() {
      },
    },
    breadCrumb: {
      get() {
        let result = null;
        if (this.catListContrast) {
          result = this.findContrastName(this.nowRouter, this.catListContrast, true);
          // result = this.nowRouter;
        }
        return result;
      },
      set() {
      },
    },
    catListContrast: {
      get() {
        return this.$store.state.category.catListContrast;
      },
      set() {
      },
    },
  },
  watch: {
    nowDicRange() {
      this.getCategoryList(this.nowRouter);
    },
    nowRouter() {
      this.categoryList = null;
      this.getCategoryList(this.nowRouter);
      this.nowPageStart = 0;
      this.islast = false;
    },
    nowPage() {
      this.entryList = null;
    },
    categoryList(val) {
      if ((val && val.length) || (this.categoryTreeBranch && this.categoryTreeBranch.length)) {
        this.loadingIt = false;
      }
    },
    catListContrast(val) {
      if (val) {
        this.nowRouterName = this.findContrastName(this.nowRouter, val, false);
        this.breadCrumb = this.findContrastName(this.nowRouter, val, true);
      }
    },
  },
  methods: {
    changePage(jump) {
      if (jump === 'pre') {
        this.nowPageStart -= 1;
      } else {
        this.nowPageStart += 1;
      }
      this.getCategoryList(this.nowRouter, (this.nowPageStart * 15) + 1);
      window.location.href = '#vocab';
    },
    findContrastName(router, catListData, wholePath) {
      const nowRouter = router.replace(/%2F/gi, '/');
      if (nowRouter !== null && !wholePath) {
        const contrastName = catListData.find((itemCat) => nowRouter === itemCat.path);
        return contrastName;
      }
      const contrastPath = nowRouter.split('/');
      const contrastPathList = contrastPath.map((itemCat) => {
        const newItem = catListData.find((item) => itemCat === item.idName);
        return newItem;
      });

      return contrastPathList || '';
    },
    getEntryInfo(entryFolder, entryText) {
      searchAPI.postGetEntryInfo((res) => {
        this.entryList = res;
        this.loadingIt = false;
      }, entryFolder, entryText);
    },
    goLink(treePath) {
      this.$router.push({ name: 'CategoryResult', params: { path: treePath.replace(/\//g, '%2F') } });
    },
    getCategoryList(pathRouter, startNum) {
      const start = startNum < 0 ? 0 : startNum;

      const newPath = pathRouter ? pathRouter.replace(/%2F/gi, '/') : null;

      categoryAPI.postSelectDicCatList((res) => {
        if (res.total) {
          this.totalAmount = res.total;
        }
        this.categoryList = res.list;
      }, newPath, this.nowDicRange, start, this.$i18n.locale);
    },
    changePagination(pageNo) {
      this.nowPageStart = pageNo - 1;
      this.getCategoryList(this.nowRouter, this.nowPageStart * 15);
    },
  },
};
</script>

<style lang="scss" scoped>
  #categoryResultDetail {
    ::v-deep .pages {
      #pagination {
        display: flex;
        justify-content: center;
        margin-top: 1em;
        .pageArea {
          margin-right: 2em;
          min-width: 300px;
        }
        .inpuArea {
          margin-top: 0px;
        }
      }
      .el-button {
        flex:  1;
      }
      .el-button:hover {
        border-color: $buttonTransparent02;
        background-color: $buttonHover02;
      }
    }
    .breadcrumb {
      background: $shadow;
      padding: $padding;
      margin-bottom: $gapHeight;
      line-height: 1em;
      margin-left: -1em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media screen and (max-width: $mobileLandscape) {
        padding: 0.3em;
        margin: 0px;
        position: relative;
      }
      li {
        font-size: $psFontSize;
        padding-right: $contentWrap;
        list-style: none;
        :after {
          padding-left: $contentWrap;
          content: '/';
        }
      }
      li:last-child {
        :after {
          content: '';
        }
      }
    }
    .pageTitle {
      padding-bottom: $pageTitle;
      display: flex;
      align-items: flex-end;
      @media screen and (max-width: $mobileLandscape) {
        flex-direction: column;
        align-items: start;
      }
      .title {
        flex: 1;
        font-size: $pageTitle;
        line-height: 1em;
      }
      .total {
        flex: initial;
        height: auto;
        line-height: 1.3em;
        font-size: $psFontSizeM;
      }
    }
    .branchArea {
      margin: -1em;
      padding: 1em;
      margin-bottom: $gapHeight;
      border: 1px solid $border-color;
      @media screen and (max-width: $mobileLandscape) {
        position: relative;
        margin: 1em 0px;
      }
      .subTreeArea {
        display: flex;
        border-bottom: 1px solid $border-color;
        padding: $contentPadding;
        li {
          list-style: none;
          flex: 1;
          cursor: pointer;
        }
        span {
          flex: init;
          text-align: center;
          color: $buttonTransparent01;
        }
      }
      .subTreeArea:last-child {
        border: none;
      }
      .subTreeArea:hover {
        background-color: $buttonHover02;
      }
    }
    .vocabularyArea {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: $mobileLandscape) {
        position: relative;
        margin: 1em 0px;
      }
      li {
        list-style: none;
        padding: $contentPadding;
        border-bottom: 1px dashed $border-color;
      }
      li:hover {
        background-color: $buttonHover01;
      }
    }
  }
</style>
