<template>
  <div id="noResult">
    <i class="el-icon-document-delete"></i>
    <div>
      {{$t('result.noResult')}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'NoResult',
};
</script>

<style scoped lang="scss">
  #noResult {
    line-height: 1em;
    width: 100%;
    text-align: center;
    font-size: $contentCaption;
    i {
      font-size: $bigIconSize;
      color: $buttonHover;
      @media screen and (max-width: $mobileLandscape) {
        font-size: calc(#{$bigIconSize * 0.5});
      }
    }
    > div {
      @media screen and (max-width: $mobileLandscape) {
        margin-top: 1em;
      }
    }
  }
</style>
