export default {
  header: {
    websiteName: 'Sambhota Dictionary Search',
  },
  footer: {
    eMail: 'Email: support@dharma-treasure.org',
    tel: 'Phone: +886-2-27586828',
    address: 'Address: Rm. 2B, 2F., No. 7, Dexing W. Rd., Shilin Dist., Taipei City 111046 , Taiwan (R.O.C.)',
    copyRight: 'Copyright © 2021 Dharma Treasure Corp. All rights reserved.',
    webTreasure: 'Dharma Treasure',
    webAdarsha: 'Adarsha',
    webToolBox: 'KETAKA',
    webEBook: 'DHARMA EBOOKS',
    webSupport: 'Dharma Treasure Support',
    nowOnlineNumber: 'Number of people online',
    visitedNumber: 'Total number of visits to this website',
  },
  menu: {
    menu: 'Menu',
    language: 'Language',
    category: 'Categorized Entries',
    alphabet: 'Alphabetical Order',
    advSearch: 'Advanced Search',
    about: 'About',
    login: 'Login',
    logout: 'Logout',
    theme: 'Theme',
    support: 'Support',
  },
  button: {
    preLevelPage: 'Above',
    prePage: 'Previous Page',
    nextPage: 'Next Page',
  },
  lang: {
    language: 'Language',
    bo: 'Tibetan',
    en: 'English',
    'zh-TW': 'Traditional Chinese',
    'zh-CN': 'Simplified Chinese',
    sa: 'Sanskrit',
  },
  index: {
    index: 'Home Page',
    theme: 'Theme',
    tagline: 'Illuminating the Meaning of Tibetan Classics',
    slogan: 'Sambhota Dictionary Search was named after Thonmi Sambhota who was the creator of the Tibetan language. The Dharma Treasure team has used modern technology to create a database comprising of more than 20,000 categorized terms and explanations taken from ancient and modern Tibetan dictionaries, Tibetan Chinese dictionaries, Chinese Tibetan dictionaries and others. Sambhota Dictionary Search is a great reference tool for users learning and studying the Tibetan language.',
    download: 'download',
    bgBlack: 'Black Background',
    bgWhite: 'White Background',
  },
  dictionary: {
    dictionary: 'Dictionary',
    selectDic: 'Select Dictionary',
    selectDicRange: 'Select Dictionaries',
  },
  category: {
    category: 'Category',
    normaVocabulary: 'General Terms',
    civilization: 'Civilization',
    terminology: 'Professional Terminology',
    religion: 'Religion',
    miscellaneous: 'Miscellaneous',
    noThisCategory: 'There is no category with the key word(s) you entered. Please try searching using other key word(s).',
    selectCatRange: 'Select categories',
    nowCatRange: 'Currently selected categories',
    allCategory: 'All Categories',
    noRelativeCat: 'There are no related categories',
  },
  alphabet: {
    alphabet: 'Alphabetical Order',
    bo: 'Tibetan - ཀ་ཁ་ག་ང་།',
    en: 'English - ABCD ',
    zhTW: 'Traditional Chinese - ㄅㄆㄇㄈ',
    zhCN: 'Simplified Chinese - abcd',
    tibetan: 'Tibetan',
    boAlphabet: 'ཀ་ཁ་ག་ང་ཅ་ཆ་ཇ་ཉ',
    english: 'English',
    enAlphabet: 'ABCDEFGH',
    traditionalChinese: 'Traditional Chinese',
    zhTWAlphabet: 'ㄅㄆㄇㄈㄉㄊㄋㄌ',
    simpleChinese: 'Simplified Chinese',
    zhCNAlphabet: 'abcdefgh',
    noResult: 'There are no related results',
    plzChangeAlphabet: 'Please search under another letter or change your chosen dictionaries to conduct search by clicking on the "Select Dictionary" button on the top right hand corner of the screen.',
  },
  search: {
    search: 'Search',
    keywordPls: 'Please enter key terms to conduct search',
    catRange: 'Select Category',
    selectCatRange: 'Select Categories',
    keyword: 'Key word',
  },
  advancedSearch: {
    advancedSearch: 'Advanced Search',
    noResult: 'There are no related results',
    result: 'Advanced Search Results',
    goForSearch: 'Search',
    keyword: 'Advanced Search',
  },
  about: {
    mainTitle: 'Sambhota Dictionary Search',
    mainIntro: 'Sambhota Dictionary Search is a collection of published multi-language dictionaries in electronic format that allows ease of reference. Our database contains Tibetan-Chinese, Chinese-Tibetan, Tibetan only and other language dictionaries. There are currently more than 20,000 entries and the entries have each been categorized for ease of reference. Our goal is for this platform to act as a base to create a Kagyu Buddhist encyclopedia.',
    area01Title: 'We currently hold three eletronic dictionaries in our database:',
    area01Content: '',
    area02Title: 'The Great Chinese-Tibetan Dictionary',
    area02Content: 'This dictionary was published in 1984 by Publishing House of Minority Nationalities and authored by Chang Yi Sun. There are approximately 53,000 Tibetan entries in this dictionary and is currently considered the most widely used Tibetan-Chinese dictionary.',
    area03Title: 'Mahavyutpatti - Tibetan Sanskrit English Chinese Dictionary of Buddhism',
    area03Content: 'The original version of this dictionary originates from the Dredrag Tuthog Par Drepa of the Tengyur. The text was compiled in the 9th century and was commonly used to translate Buddhist texts.  In the mid-19th century, it spread to the West and some scholars added vocabulary to the original text  to include Chinese translation as well. It was not until the 1950s that the Japanese scholars Ogihara Yunlai and Saki Ryo Sanshin edited and reprinted the book.',
    area04Title: 'Yuzhi Zengding Qingwen jian',
    area04Content: 'This dictionary originates from the 18th century during the time of the Qing Qianlong period and contains categories entries in the five languages - Manchurian, Tibetan, Mongolian, Uyghurchen and Chinese.  There are approximately 18,000 entries in this dictionary. The dictionary has both historical significance and is also practically useful. This version of the dictionary was adapted from the precious hand-written manuscript kept at the Chong Hua Palace of the Imperial Palace in 1956.',
    dicsIntro: '',
    dicRangeA: 'Tibetan Dictionaries',
    bookNameA01: 'Brda dkrol gser gyi me long',
    introA01: 'This is an ancient Tibetan dictionary. The dictionary was edited by Tseng Lha Ngag Wang Tsultrim and published by the Publishing House of Minority Nationalities, Beijing, China. The dictionary has collected close to 8,000 ancient Tibetan terms.',
    bookNameA02: 'Le shing gur khang',
    introA02: 'This dictionary was written in the 15th century by the Tibetan translator Juedun Mengzhu and holds over 1,000 modern and ancient terms.',
    bookNameA03: 'Dungkar Tibetan Dictionary',
    introA03: 'This dictionary holds a collection of terms in the following categories: <br/>1. Tibetan historical events and people<br/>2. The relationship between the central dynasties and the local government of Tibet <br/>3. Ancient Tibetan laws <br/>4. Documents relating to the local government of Tibet<br/>5. Historical relics and important monasteries of various places within Tibet; Tibetan customs and religions<br/>6. Terms relating to Tibetan common knowledge<br/><br/>The terms in this dictionary have been carefully chosen and edited. The entries contain clear and concise explanations. It is a comprehensive encyclopedic work of all things related to TIbet.',
    bookNameA04: 'sgom sde tshig mdzod chenmo',
    introA04: 'Editor - sgom sde lha ramspa<br/>Publishing house - ser ges rig mdzod chenmo<br/>Serje dge bshes rhub bsten bsam drub was a sgom sde lharams pa. He was born in Tibet in 1958 and arrived in India in 1959. In 1972, he started learning to debate at Sera Monastery and achieved the Lharamspa title. He continued onto Gyuto Monastery to learn tantra. Upon graduation, he returned to Sera Monastery to become a teacher while preparing and collating teachings in written form. He completed this dictionary in 2016.',
    bookNameA05: 'chos rnam kun btus',
    introA05: 'This text was compiled by Nordrang Wugyen and published by the China Tibetology Publishing House in 2008. The dictionary contains more than 10,000 entries from the Compilation of the Tripiṭaka with comprehensive explanations and is a great tool to assist those studying Buddhist studies.',
    bookNameA06: 'Bye Brag Tu Rtogs Par Byed Pa (Sanskrit-Tibetan Dictionary of Comparative Tengyur)',
    introA06: 'This compilation is based on Dege Tengyur D4348, launched during the reign of the Tibetan king, Trisong Detsen in the ninth century. Aiming to standardize the translations of Sanskrit Buddhist scriptures, the book was subsequently incorporated into the Tengyur collection. Given the large discrepancies between the current edition and the original one after various recensions, the editorial team of Sambotha Dictionary has now released a new collated edition online in the hope of serving as more complete references for further studies.',
    bookNameA07: 'Bod Rgya Tshig Mdzod Chen Mo (Great Tibetan Dictionary)',
    introA07: 'Author: Ngam Ring Blo Bzang Bstan Dar<br/>Editor: Bsod Nams Dpal Sgron<br/>Publication date:2010<br/>Publisher: Bod-gzun Ses-rig Las-khuns',
    dicRangeB: 'Tibetan-Chinese Dictionaries ',
    bookNameB01: 'Bod rgya tshig mdzod chen mo',
    introB01: 'Zhang Yi Sun was the main editor to this great Tibetan and Chinese dictionary published by the Publishing House of Minority Nationalities in 1984. There are over 53,000 terms in this dictionary and it is considered the mostly widely used Tibetan-Chinese dictionary. In order to make searching terms easier for users, all the Tibetan terms have a Chinese translation featured which can be found under each entry.',
    bookNameB02: 'Sgra bye brag tu rtogs byed chen mo',
    introB02: 'This Tibetan-Sanskrit-English-Chinese dictionary originated from the Dredrag Tutogpar Depa of the Tibetan Tengyur. The dictionary was published in the 9th century and should be considered the main reference to illuminate terms in the Buddhist scriptures. Chinese and English translation was added in an edition created in the 19th century. The dictionary was published in 1950 by the Japanese scholars Diyuan Yunlai and Shenliang Sanlang.',
    bookNameB03: 'bod rgya shen sjar gyi shes jai rnam drangs kun btus tshig mdzod',
    introB03: 'This is a Tibetan-Chinese dictionary that has been edited by He Wen Xuan and Dou Cunqi and published by the Qing Hai Publishing House of Minority Nationalities in 1987. This text can be seen as a tool to match the vocabulary from the Tibetan Buddhist scriptures between the Tibetan and Chinese language. There are a total of 2113 terms in this dictionary and encompasses topics such as politics, economics, culture, education, civilization, arts, language, grammar, history, religion, medicine and astronomy.',
    bookNameB04: 'sngas rgyas chos gzhung tshig mdzod',
    introB04: 'This Tibetan-Chinese dictionary was published by the Qing Hai Publishing House of Minority Nationalities and Wang Yinuan was its main chief editor. There are a total of 13,000 terms in this dictionary that have been taken from many significant Buddhist texts. In order to make searching terms easier for users, all the Tibetan terms have a Chinese translation featured which can be found under each entry.',
    bookNameB05: 'bod rgya nang don rig pai tshig mdzod',
    introB05: 'This dictionary was published by the Szechuan Publishing House of Minority Nationalities in 1992 and edited by Mr Tuden Nyima. It is a text useful in comparing terms between the Tibetan and Chinese languages.',
    bookNameB06: 'rgya bod ming mdzod',
    introB06: 'This text was published by the Gansu Publishing House of Minority Nationalities in 1979 and prepared by the Tibetan teaching and research group of the Northwest University for Nationalities. There are 254000 entries collected in this text.',
    bookNameB07: 'Sanskrit-Tibetan-Chinese Dictionary',
    introB07: 'Written and edited by Mr. An Shih-sing, and published by the Publishing House of Minority Nationalities in 1991, this dictionary is based primarily on Tibetan-Sanskrit  Dictionary of Common Words : A Necklace of Wonder Jewels by Dorkha Tsering Wangye. The Sanskrit main entries are transliterated to Tibetan, with cross-references both in Tibetan and Chinese. The dictionary contains over 19,000 entries. This work is a reference for Tibetologists to learn, translate and study ancient Tibetan classical literature.',
    bookNameB08: 'Tibetan-Chinese Dictionary for Mahāyānābhidharma-samuccaya',
    introB08: 'This dictionary complied by Mr. Jang Shiu, a Tibetologist in the early 20th century, was published by West Frontiers Culture Institute Publication. The collation was done by contrasting Tibetan Mahāyānābhidharma-samuccaya with its Chinese translation by Master Syuan Zang, using the framework of monosemy, polysemy, and synonymous sentences for the Tibetan entries and their Chinese parallels. This dictionary contains over 4830 words.',
    bookNameB09: 'Bod Rgya Skar Rtsis Rig Pa’i Tshig Mdzod (Tibetan-Chinese Calendar Dictionary)',
    introB09: 'Editors-in-chief: Tang Chih-An, Yangchen Lhamo. <br/>Publication Date: 1985<br/>Publisher: Sichuan Nationalities Publishing House<br/>This dictionary serves as a reading aid to Tibetan astrology, with an aim to provide traditional knowledge of Tibetan calendar rather than systematic introduction to modern astronomy.<br/>All the example sentences directly originated from Tibetan literature.<br/>Despite the terminological distinctions, conceptual or theoretical similarities in astronomical definitions are noted in parentheses in concise Chinese, in lieu of traditional explications. This book made no comment on any conceptual or theoretical discrepancies. The Tibetan annotations are mostly written by Bsam Grub Rgya Mtsho, with Chinese translation and expansions from Huang Ming-Sin, and astronomical definitions provided by Chen,Jiou-Jin. Omissions and errors are inevitable. Readers’ comments and feedback would be greatly appreciated.',
    dicRangeC: 'Chinese-Tibetan Dictionaries',
    bookNameC01: 'Yù zhì wǔtǐ qīngwén jiàn',
    introC01: 'This dictionary was put together in the 18th century during the Qianlong time period. It contains the five languages of Man, Tibet, Mongolia, Uyghur and Han. It is a collection of 18,000 terms and has great historical and practical significance. The original manuscript was published in 1956 by the Imperial Palace.',
    bookNameC02: 'Bod rgya shan sbyar rgyun spyod tshig mdzod',
    introC02: 'This dictionary contains more than 80,000 terms with its main focus on modern Chinese vocabulary. Apart from everyday language, there are also ancient terms, idioms, proverbs, aphorisms, local dialect and specialist terms from various professions.',
    bookNameC03: '《Bod rgya shan sbyar sngas rgyal chos gzhung  tshig mdzod》',
    introC03: 'Bod Rgya Shan Sbyar Sngas Rgyal Chos Gzhung Tshig Mdzod The Bod Rgya Shan Sbyar Sngas Rgyal Chos Gzhung Tshig Mdzod was published by the Gansu Publishing House of Minority Nationalities and edited by Mr Maxue. The entries in this dictionary have mainly been taken from Buddhist philosophy articles, history articles, monastery periodicals and other Chinese-Tibetan materials. The topics covered by the entries include Buddhist scriptures, Buddhist schools, Buddhist practices, Buddhist doctrine, Buddha, bodhisattvas, the heavens, ghosts and spirits, eminent monks, ritual, system, special occasions, historical sites, monasteries etc. Tibetan Buddhism was historically influenced by the Bon religion and so this dictionary also contains quite a few Bon terms. There are a total of more than 23,000 entries in this dictionary.',
    dicRangeD: 'Tibetan-English dictionaries',
    bookNameD01: 'The Combined English Dictionary',
    introD01: 'This dictionary is a compilation of terms from various sources such as Rangjung Yeshe, Richard Barron, Ives Waldo, the Sambhota Dictionary Search editing  team and others.',
    bookNameD02: 'Tibetan-English Dictionary of Buddhist Terminology',
    introD02: 'This dictionary was compiled by Tsepak Rigzin and published by the Tibetan Works and Archives in 1986. It was based on the ‘Great Volume of Precise Understanding’ and contains 6,000 main entries and over 8,000 sub-entries.',
    bookNameD03: 'English-Tibetan-Chinese Dictionary',
    introD03: 'The English-Tibetan-Chinese Dictionary was first published by Gansu Publishing House of Minority Nationalities in 2005 with Yeshi Lamutso as the main editor. The dictionary contains close to 4,000 terms.',
    bookNameD04: '《Tibetan honorific speeches》',
    introD04: 'This text was published by the Tibetan Peoples’ Publishing House and jointly edited by Dogon Sangdag Dorje and Thunpar Tendzin Lhadzom. This dictionary holds a collection of colloquial Tibetan vocabulary and includes English and Chinese translation. ',
    dicRangeE: 'Chinese Dictionaries',
    bookNameE01: '《丁福保佛學大詞典》',
    introE01: 'This is a Buddhist dictionary compiled over eight years by Ding Fu Bao from a great Japanese Buddhist dictionary. The dictionary was published in 1922 and contains over 30,000 terms. ',
    bookNameE02: '《一切經音義》',
    introE02: 'This dictionary was compiled by Master Huilin of the Tang Dynasty and contains a total of 100 volumes. It is a masterpiece containing the sound and meaning of many of the words in Buddhist scriptures.',
    bookNameE03: '《翻譯名義集》(宋版)',
    introE03: 'This is a Buddhist dictionary compiled by Fayun who was a monk at Jingde Monastery in Pingjiang (in Suzhou, Jiangsu Province). The dictionary contains 7 volumes and 64 articles. The terms in this dictionary originate from Sanskrit words that can be found in various Buddhist scriptures. The dictionary has a total of more than 2,040 transliterations of Sanskrit.',
    bookNameE04: '《三藏法數》',
    introE04: 'This dictionary contains a total of 50 volumes. It was compiled by Feng Chi during the Ming Dynasty. There are a number of 1,555 terms in this dictionary which are ordered in numerical order, with the first term being “One Heart” and last term “84,000 ways”. ',
    bookNameE05: 'Yiqiejing yinyi  (Pronunciation and Meaning in the Complete Buddhist Canon) ',
    introE05: 'The AD 807 Yiqiejing yinyi (Pronunciation and Meaning in the Complete Buddhist Canon) edited by the Tang dynasty monk Huilin was an expansion and revision of the AD 649 Yiqiejing yinyi written by Syuan Ying. The dictionary has the most complete glossary both in sounds and meanings, containing all the words from Buddhist scriptures in and before the Tang dynasty, hence “ the archetype of Chinese-Sanskrit bilingual dictionary". This book can also be viewed as an extensive corpus of philology of Buddhist scriptures. ',
    thanks: 'Word of Appreciation',
    thanksContent1: 'The successful completion of Sambhota Dictionary Search has been the result of a great team, including volunteers who engaged in inputting, editing and proofreading text, as well as the software engineer team who developed the software and website to enable and display complex searches within a huge database of dictionary terms. We would like to extend a big thank you to all those that took part in this project and we are very happy to now be able to provide this tool to the public free of charge.',
    thanksContent2: '',
    thanksContent3: 'Yours Sincerely, The Dharma Treasure team',
  },
  login: {
    sign: 'Login',
    register: 'Register',
    account: 'Account',
    name: 'Name',
    member: 'Member',
    email: 'Email Address',
    password: 'Password',
    confirmPsw: 'Confirm password',
  },
  result: {
    noResult: 'No related results. Please return to previous page or conduct a new search.',
    noInputResult: 'No related results. Please try searching under other key words.',
    orTryOtherWord: 'Alternatively, please try one of these related entries.',
    result: 'Search Results',
    explanation: 'Explanation',
    bookPage: 'Original page number',
    pageNoF: 'Page',
    pageNoL: '',
    entryNoF: 'Entry',
    entryNoL: '',
    partOfSpeech: 'Part of Speech',
    expCategory: 'Category',
    expSource: 'Explanation Source',
    ps: 'annotation',
    combination: 'compound word',
    word: 'original word',
    variant: 'variant Chinese character',
    pinyin: 'pinyin',
    phonetic: 'zhuyin',
    note: 'note',
    originalImg: 'original image',
  },
  pos: {
    pos1Aid: 'idiom',
    pos1Ano: 'noun',
    pos1Ave: 'verb',
    pos1Aad: 'adjective',
    pos1Aadv: 'adverb',
    pos1Apr: 'preposition',
    pos1Aco: 'conjunction',
    pos1Apro: 'pronoun',
    pos1Asy: 'synonym',
    pos1Aho: 'honorifics',
    pos1Asl: 'slang',
    pos1Adi: 'dialects',
    pos1Aques: 'question particle',
    pos1Aab: 'abbreviation',
  },
  elements: {
    enterPage: 'Enter Page Number',
    comfirm: 'Submit',
    totalNumber: 'Number of results',
    mainEntry: 'Term',
    transEntry: 'Related Term',
    reset: 'Reset',
    copysuccess: 'Copy successful.',
    copyfailed: 'Unable to copy because your browser does not support it.',
  },
  errorReport: {
    feedback: 'Feedback',
    problem: 'Error with the dictionary entry',
    unshownZh: 'Some Chinese characters cannot be displayed',
    otherProblem: 'Other problem',
    name: 'Name',
    contactWay: 'Contact Details',
    submitProblem: 'Please submit your feedback here ',
    send: 'Send',
    fixUnshownZh1: 'If your computer cannot display rare Chinese characters, then ',
    fixUnshownZh2: 'you can click on this link',
    fixUnshownZh3: ' to install the relevant font software to help in displaying those characters.',
    returnForm: 'Return to feedback form',
  },
  donate: {
    donateUs: 'Donate Us',
    introTitle: 'Sambhota Dictionary Search',
    introContent: 'Sambhota Dictionary Search was developed in 6 years through collation of more than 20 ancient and modern Chinese and foreign texts including Chinese, Tibetan and English dictionaries with assistance from volunteers of the Hua Yue Chinese Tibetan Buddhist College. The dictionary entries included in the Sambhota Dictionary Search collection have been carefully organized and categorized, including entries from various precious ancient texts, and will continuously be updated and expanded in the future. Sambhota Dictionary Search is an invaluable tool for dharma practitioners and scholars in gaining a deeper understanding of ancient and modern terms.<br/><br/> Pray：Dharma practitioners and scholars can deepen their understanding of the dharma by looking up any difficult to understand or dharma terms at any place or time when they are reading buddhist scriptures.<br/><br/>Aspire：That those who read the buddhist scriptures will be able to understand its inner meaning and gain Buddha’s blessing.<br/><br/>～Aspiration that this merit will be spread everywhere so that I and all sentient beings will reach Buddhahood together.',
    imgPS: 'Picture: Thomi Sambhota - the main creator of the Tibetan language and translated a number of Buddhist texts',
    creditPay: 'Support by credit card',
    aliPay: 'Support by alipay',
    paypalPay: 'Support by paypal',
    taiwanCurrency: 'Support by taiwan account',
    foreignCurrency: 'Support by foreign account transfer',
  },
  supportPage: {
    questions: 'Frequently Asked Questions',
  },
};
