import Vue from 'vue';
import VueI18n from 'vue-i18n';
import boLocale from './bo';
import enLocale from './en';
import twLocale from './zh-TW';
import cnLocale from './zh-CN';

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
  },
  bo: {
    ...boLocale,
  },
  'zh-TW': {
    ...twLocale,
  },
  'zh-CN': {
    ...cnLocale,
  },
};

export function getLanguage() {
  const cookieLanguage = window.$cookies.get('language');
  if (cookieLanguage) return cookieLanguage;

  // const browerLanguage = (navigator.language || navigator.browserLanguage).toLowerCase();
  const navigLang = navigator.browerLanguage;
  let lang = navigator.browerLanguage ? navigLang : navigator.language || navigator.userLanguage;
  if (!lang) {
    lang = 'en';
  }
  const browerLanguage = lang;

  const locales = Object.keys(messages);

  locales.forEach((locale) => {
    if (browerLanguage.indexOf(locale) > -1) {
      return locale;
    }

    if (browerLanguage === 'zh-tw') {
      return 'zh-TW';
    }
    if (browerLanguage === 'zh-cn') {
      return 'zh-CN';
    }
    return browerLanguage;
  });

  if (browerLanguage === 'zh-tw') {
    return 'zh-TW';
  }
  if (browerLanguage === 'zh-cn') {
    return 'zh-CN';
  }
  return browerLanguage;
}

export function getBrowerLanguage() {
  const cookieLanguage = window.$cookies.get('language');
  if (cookieLanguage) return cookieLanguage;

  // const browerLanguage = (navigator.language || navigator.browserLanguage).toLowerCase();
  const navigLang = navigator.browerLanguage;
  let lang = navigator.browerLanguage ? navigLang : navigator.language || navigator.userLanguage;
  if (!lang) {
    lang = 'en';
  }
  const browerLanguage = lang;
  return browerLanguage;
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
  fallbackLocale: getBrowerLanguage(),
  silentTranslationWarn: true,
});

export default i18n;
