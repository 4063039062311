<template>
  <div
    id="categoryResult"
    :style="{backgroundImage:
      `linear-gradient(to right, rgba(255, 255, 255, 0.85) -100%, rgba(255, 255, 255, 1) 300%),
      url(${imgSrc[nowRouterMainCat-1]})`}"
  >
    <div class="content">
      <div class="treeArea">
        <CategoryTree />
      </div>
      <div class="itemArea">
        <CategoryResultDetail />
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/img/picture/category01.png';
import img2 from '@/assets/img/picture/category02.png';
import img3 from '@/assets/img/picture/category03.png';
import img4 from '@/assets/img/picture/category04.png';
import CategoryResultDetail from '../components/CategoryResultDetail.vue';
import CategoryTree from '../components/CategoryTree.vue';
// import BackButton from '../components/BackButton.vue';

export default {
  name: 'CategoryResult',
  data() {
    return {
      imgSrc: [
        img1,
        img2,
        img3,
        img4,
      ],
    };
  },
  components: {
    CategoryResultDetail,
    CategoryTree,
    // BackButton,
  },
  computed: {
    nowRouterMainCat: {
      get() {
        let result = null;
        if (this.$route.params.path) {
          result = this.findMainCat(this.$route.params.path);
        }
        return result;
      },
      set() {
      },
    },
  },
  methods: {
    findMainCat(router) {
      const nowRouter = router;
      if (router) {
        return nowRouter.substr(0, 1);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
  #categoryResult {
    padding: $wrapWidth;
    background-repeat: no-repeat;
    background-position: top 0 left 50vw;
    .content {
      padding: $wrapWidth;
      margin: $space;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: $mobileLandscape) {
        padding: 0px;
        margin: 0px;
      }
      .treeArea {
        background: $mainBgColor;
        min-width: 100px;
        flex: 1;
        padding: 3em;
        border: 1px solid $border-color;
        border-radius: $radius;
        box-shadow: $boxShadow;
        @media screen and (max-width: $mobileLandscape) {
          display: none;
        }
      }
      .itemArea {
        font-size: 1.4em;
        margin: $contentWrap;
        margin-top: 0px;
        padding: $contentWrap;
        flex: 3;
        text-align: left;
        @media screen and (max-width: $mobileLandscape) {
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }
</style>
