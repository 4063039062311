<template>
  <div id="imageEnlarge" >
    <span class="cursorPointer" @click="drawer = true">
      <i class="el-icon-picture"></i>
      {{$t('result.originalImg')}}
    </span>
    <el-drawer
      title=""
      :visible.sync="drawer"
      direction="btt"
      size="100%"
      :before-close="handleClose"
    >
    <div class="content">
      <!-- <img :src="imgLink" /> -->
      <!-- <button @click="magnifierActive(magniActive)">test</button> -->
      <div id="img-zoomer-box">
        <img :src="imgLink" id="img-1" />
        <!-- <div id="img-2" :style="`background:url(${imgLink}) no-repeat #FFF;`"></div> -->
      </div>
    </div>
  </el-drawer>
  </div>
</template>

<script>

export default {
  name: 'ImageEnlarge',
  props: {
    imgLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      drawer: false,
      magniActive: false,
    };
  },
  mounted() {
    // this.magnifier();
  },
  watch: {
  },
  methods: {
    magnifierActive() {
      if (this.magniActive) {
        // document.getElementById('img-zoomer-box').
        // addEventListener('mousemove', this.test(e), false);
        this.magnifier();
      } else {
        // document.getElementById('img-zoomer-box').removeEventListener('mousemove',);
      }
      this.magniActive = !this.magniActive;
    },
    handleClose(done) {
      done();
    },
    test() {
      const original = document.getElementById('img-1');
      const item = document.getElementById('img-zoomer-box');
      const e = document.getElementById('img-zoomer-box');
      const x = e.pageX - item.offsetLeft;
      const imgWidth = original.offsetWidth;
      const magnified = document.getElementById('img-2');
      const imgHeight = original.offsetHeight;
      const y = e.pageY - item.offsetTop;
      let xperc = ((x / imgWidth) * 100);
      const { style } = magnified;
      let yperc = ((y / imgHeight) * 100);

      if (x > (0.01 * imgWidth)) {
        xperc += (0.15 * xperc);
      }

      // lets user scroll past bottom edge of image
      if (y >= (0.01 * imgHeight)) {
        yperc += (0.15 * yperc);
      }

      style.backgroundPositionX = `${xperc - 9}%`;
      style.backgroundPositionY = `${yperc - 9}%`;

      style.left = `${x - 180}px`;
      style.top = `${y - 180}px`;
    },
    magnifier() {
      document.getElementById('img-zoomer-box').addEventListener('mousemove', (e) => {
        const original = document.getElementById('img-1');
        const item = document.getElementById('img-zoomer-box');
        const x = e.pageX - item.offsetLeft;
        const imgWidth = original.offsetWidth;
        const magnified = document.getElementById('img-2');
        const imgHeight = original.offsetHeight;
        const y = e.pageY - item.offsetTop;
        let xperc = ((x / imgWidth) * 100);
        const { style } = magnified;
        let yperc = ((y / imgHeight) * 100);

        // console.log(original, x, y, imgWidth, magnified, imgHeight, xperc, yperc);
        // lets user scroll past right edge of image
        if (x > (0.01 * imgWidth)) {
          xperc += (0.15 * xperc);
        }

        // lets user scroll past bottom edge of image
        if (y >= (0.01 * imgHeight)) {
          yperc += (0.15 * yperc);
        }

        style.backgroundPositionX = `${xperc - 9}%`;
        style.backgroundPositionY = `${yperc - 9}%`;

        style.left = `${x - 180}px`;
        style.top = `${y - 180}px`;
      }, false);
    },
  },
};
</script>

<style lang="scss" scoped>
#imageEnlarge {
  .cursorPointer {
    cursor: pointer;
  }
  ::v-deep .el-drawer {
    padding: 0em 2em;
    color: #000;
    padding: $wrapWidth;
    width: 100%;
    .el-button {
      color: $mainTextColor;
      font-weight: normal;
      border-color: $buttonTransparent02;
      background-color: $buttonHover02;
    }
    .el-button:hover {
      border-color: $buttonTransparent02;
      background-color: $buttonHover02;
    }
    .el-drawer__header {
      .el-drawer__close-btn {
        i {
          font-size: 2em;
          font-weight: bolder;
        }
      }
    }
    .el-drawer__body {
      overflow: scroll;
      padding: $padding;
      font-size: $pageTitle;
      height: 100%;
      .title {
        margin: $lineHeight;
        font-size: $pageTitle;
        padding-bottom: 1em;
        @media screen and (max-width: $mobileLandscape) {
        }
        i {
          padding-left: 0.5em;
          cursor: pointer;
          color: $buttonTransparent01;
        }
        i:hover {
          color: $buttonTransparent02;
        }
      }
      .content {
        overflow: scroll;
        img {
          width: 100%;
        }
      }
    }
  }
  #img-zoomer-box {
    width: 100%;
    height: auto;
    min-height: 100%;
    position: relative;
    margin-top: 10px;
    text-align: center;
    img {
      width: auto;
    }
  }

  #img-1 {
    width: 100%;
    height: auto;
  }

  #img-zoomer-box:hover, #img-zoomer-box:active {
    // cursor: zoom-in;
    display: block;
  }

  #img-zoomer-box:hover #img-2, #img-zoomer-box:active #img-2 {
    opacity: 1;
  }
  #img-2 {
    width: 340px;
    height: 340px;
    box-shadow: 0 5px 10px -2px rgba(0,0,0,0.3);
    pointer-events: none;
    position: absolute;
    opacity: 0;
    border: 4px solid whitesmoke;
    z-index: 99;
    border-radius: 100%;
    display: block;
    transition: opacity .2s;
  }
}
</style>
