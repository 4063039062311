<template>
  <div id="category">
    <div class="pageTitle">
      {{$t('menu.category')}}
    </div>
    <div class="mainContent">
      <el-row class="row">
        <el-col
          class="col"
          :span="6"
          v-for="(category, index) in categoryList"
          :key="index"
        >
          <el-card
            shadow="always"
            @mouseover.native="hovered = index"
            @mouseleave.native="hovered = null"
          >
            <div @click="nextLevel(category.category)">
              <div class="pic" :class="{'picHover': (hovered === index)}">
                <img :src="category.imgSrc">
              </div>
              <div>
                <div class="title">
                  {{$t(category.buttonContent)}}
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/img/picture/category01.png';
import img2 from '@/assets/img/picture/category02.png';
import img3 from '@/assets/img/picture/category03.png';
import img4 from '@/assets/img/picture/category04.png';

export default {
  name: 'Category',
  data() {
    return {
      hovered: false,
      categoryList: [
        {
          category: '1',
          buttonContent: 'category.civilization',
          imgSrc: img1,
        },
        {
          category: '2',
          buttonContent: 'category.terminology',
          imgSrc: img2,
        },
        {
          category: '3',
          buttonContent: 'category.religion',
          imgSrc: img3,
        },
        {
          category: '4',
          buttonContent: 'category.miscellaneous',
          imgSrc: img4,
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
    nextLevel(category) {
      this.$router.push({
        name: 'CategoryResult',
        params: {
          path: category,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  #category {
    padding: $wrapWidth;
    .pageTitle {
      font-size: $pageTitle;
      padding-bottom: $pageTitle;
    }
    .mainContent {
      .row {
        display: flex;
        margin: $margin;
        @media screen and (max-width: $mobileLandscape) {
          flex-direction: column;
          margin: 0px;
        }
        .col {
          flex: 1;
          padding: $gutter;
          @media screen and (max-width: $mobileLandscape) {
            padding: 0px;
            width: 100%;
            margin-bottom: $contentWrap;
          }
          .el-card {
            padding: $cardSpace;
            border-bottom: 3px solid transparent;
            text-align: center;
            cursor: pointer;
            @media screen and (max-width: $mobileLandscape) {
              padding: 0px;
            }
            .title {
              font-size: $contentCaption;
              // font-weight: bold;
            }
            .pic {
              text-align: center;
              margin-bottom: $contentWrap;
              img {
                width: 70%;
              }
            }
            .picHover {
              img {
                transform: scale(1.4);
                -ms-transform: scale(1.4);
                -webkit-transform: scale(1.4);
              }
            }
          }
          .el-card:hover {
            border-bottom: 3px solid $buttonHover;
          }
        }
        .col:first-child {
          padding-left: 0px;
        }
        .col:last-child {
          padding-right: 0px;
        }
      }
    }
  }
</style>
