var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"entryResult"}},[(!_vm.resultList)?_c('Loading',{attrs:{"marginTop":"5em"}}):_vm._e(),(_vm.resultList && !_vm.resultList.length)?_c('div',[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('result.noResult'))+" ")])]):_vm._e(),(_vm.resultList && _vm.resultList.length)?_c('div',{staticClass:"mainContent"},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('result.result'))+" ")]),_vm._l((_vm.resultList),function(result,index){return _c('li',{key:index,class:{
        'hightLight': _vm.nowRouterFolder.toString() === result.folder.toString()
          && _vm.nowRouterText.toString() === result.text.toString()
      },on:{"click":function($event){return _vm.showResult(result.folder, result.text, result.id)}}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(result.text))]),_c('div',{staticClass:"psTrans"},[(
          _vm.$i18n.locale === 'zh-TW'||_vm.$i18n.locale === 'zh-HK'||
          _vm.$i18n.locale === 'zh-CN'||_vm.$i18n.locale === 'bo'||
          _vm.$i18n.locale === 'zh-tw'||_vm.$i18n.locale === 'zh-hk'||
          _vm.$i18n.locale === 'zh-cn'
          )?_c('span',[_vm._v(" ── "+_vm._s(result.folderName18[_vm.$i18n.locale])+" ")]):_c('span',[_vm._v(" ── "+_vm._s(result.folderName18['en'])+" ")])])])}),_c('div',{staticClass:"pages"},[_c('Pagination',{attrs:{"totalPage":_vm.totoalAmount},on:{"goPage":_vm.changePagination}})],1)],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }