<template>
  <div id="donateLinks">
    <button class="openChatBtn" @click="goDonatePage()">
      <span class="mobileNoShow">
        {{$t('donate.donateUs')}}
      </span>
      <span class="deskNoShow">
        <i class="fa fa-tree"></i>
      </span>
    </button>
  </div>
</template>

<script>

export default {
  name: 'DonateLinks',
  data() {
    return {
      isDesktop: true,
      thedeviceHeight: '0',
    };
  },
  mounted() {
    const deviceWidth = document.documentElement.clientWidth;
    this.thedeviceHeight = deviceWidth;
    if (deviceWidth < 768) {
      this.isDesktop = false;
    }
  },
  methods: {
    goDonatePage() {
      this.$router.push({ path: '/donate' });
    },
  },
};
</script>

<style scoped lang="scss">
#donateLinks {
  .el-notification.errorMessage.right {
    background: purple !important;
    color: white;
    span {
      background: green !important;
      color: yellow;
    }
  }
  .mobileNoShow {
    display: unset;
    @media screen and (max-width: $mobilePortrait) {
      display: none;
    }
  }
  .deskNoShow {
    display: none;
    color: rgb(47, 121, 28);
    @media screen and (max-width: $mobilePortrait) {
      display: unset;
    }
  }
  ul {
    margin: 1em;
    li {
      text-indent: calc(-1em - 5px);
    }
    li:before {
      padding-left: 0px;
      padding-right: 5px;
      content: '◍';
    }
  }
  .closeIt {
    display: none;
  }
  .openIt {
    display: block;
  }
  .openChatBtn {
    background-color: rgb(47, 121, 28);
    color: white;
    font-weight: 500;
    cursor: pointer;
    position: fixed;
    border: none;
    bottom:0px;
    right: 0px;
    width: 280px;
    padding: 1em;
    @media screen and (max-width: $mobilePortrait) {
      opacity: 0.8;
      width: 5em;
      bottom: 3%;
      background-color: transparent;
      color: $mainThemeColor;;
      i {
        font-size: 2em;
      }
    }
  }
  .chatArea {
    position: fixed;
    border: none;
    bottom:0px;
    right: 0px;
    width: 280px;
    @media screen and (max-width: $mobilePortrait) {
      width: 100%;
      top: 0px;
    }
  }
  .content {
    background-color: $psBgColor;
    cursor: pointer;
    position: relative;
    border: none;
    bottom:0px;
    right: 0px;
    width: calc(280px - 2em);
    padding: 1em;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    height: 45vh;
    @media screen and (max-width: $mobilePortrait) {
      min-height: 100%;
      padding: 0px;
      width: 100%;
      font-size: 1.4em;
    }
    .closeIcon {
      text-align: right;
      margin-right: -0.5em;
      margin-top: -0.5em;
      padding-top: 0.5em;
      padding-right: 0.5em;
      font-size: 1.6em;
    }
    .window {
      overflow: scroll;
      font-size: 0.8em;
      ul {
        li {
          padding: 0.4em;
        }
        li:hover {
          background-color: $buttonHover01;
        }
      }
      .effectAnime:before {
        color: $buttonHover;
      }
    }
    .topArea {
      overflow: scroll;
      width: 100%;
      @media screen and (max-width: $mobilePortrait) {
        flex: 1;
        padding: 1em;
        width: calc(100% - 2em);
      }
    }
    .closeButtons {
      color: white;
      font-weight: 500;
      cursor: pointer;
      position: absolute;
      border: none;
      bottom:0px;
      right: 0px;
      width: 280px;
      background: $mainThemeColor;
      @media screen and (max-width: $mobilePortrait) {
        width: 100%;
        flex: initial;
      }
      .closeChatBtn {
        background-color: $mainThemeColor;
        color: white;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        border: none;
        bottom:0px;
        right: 0px;
        width: 280px;
        padding: 1em;
        @media screen and (max-width: $mobilePortrait) {
          width: 100%;
        font-size: 0.8em;
        }
      }
      .backColor {
        background-color: $buttonTransparent01;
      }
    }
    .btn.effect.effectAnime {
      border-radius: 0px;
      background-color: $mainBgColor;
      opacity: 0.7;
    }
    .effectAnime:before {
      content: ' ';
    }
    .btn.effect.effectAnime:hover {
      opacity: 1;
    }
    .btn.effect.effectAnime:hover:before {
      text-indent: -0.5em;
      display: inline-block;
      position: relative;
      content: '↳';
      color: $mainTextColor;
      font-weight: normal;
      padding-right: 0.5em;
    }
    form {
      display: flex;
      flex-direction: column;
      input {
        font-size: initial;
        margin-top: 0.5em;
        border: none;
        border-bottom: 1px solid $buttonHover01;
        padding: 10px;
        background-color: $mainBgColor;
        color: $mainTextColor;
        opacity: 0.7;
      }
      input:focus {
        outline: none;
        opacity: 1;
      }
      textarea {
        font-size: initial;
        padding: 15px;
        border: none;
        resize: none;
        min-height: 150px;
        margin-top: 1em;
        background-color: $mainBgColor;
        color: $mainTextColor;
        opacity: 0.7;
      }
      textarea:focus {
        outline: none;
        opacity: 1;
      }
      .btn {
        background-color: $mainBgColor;
        color: $psTextColor;
        font-weight: bolder;
        padding: 1em;
        border: none;
        cursor: pointer;
        border-top: $psBgColor 1px solid;
      }
      .btn.effect.effectAnime:hover {
        background: $buttonTransparent02;
        color: $mainTextColor;
      }
    }
  }
}
</style>
