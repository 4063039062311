<template>
  <div id="app">
    <div :style="{ 'fontSize': fontSize + 'em', 'lineHeight': lineHeight + 'em' }">
      <div class="menuWrap">
        <Menu @resizefont="refontsize"/>
      </div>
      <div class="contentWrap">
        <router-view/>
      </div>
      <div class="footerWrap">
        <Footer/>
      </div>
    </div>
    <ErrorReport :entryID="pathEntryID" />
    <DonateLinks />
  </div>
</template>

<script>
import Menu from './components/Menu.vue';
import Footer from './components/Footer.vue';
import ErrorReport from './components/ErrorReport.vue';
import DonateLinks from './components/DonateLinks.vue';

export default {
  name: 'app',
  data() {
    return {
      fontSize: 1,
      lineHeight: 1,
    };
  },
  components: {
    Menu,
    Footer,
    ErrorReport,
    DonateLinks,
  },
  computed: {
    pathEntryID() {
      let result = this.$route.params.entryID || this.$route.params.id;
      result = (result === 'index' ? 'null' : result);
      return result;
    },
  },
  mounted() {
    this.$store.dispatch('category/setCatList', this.$i18n.locale);
    this.$store.dispatch('dictionary/setDicList');
    // 預備token的
    // if (localStorage.getItem('userToken')) {
    //   this.$store.dispatch('userSettings/setUserToken');
    // } else {
    //   localStorage.setItem('userToken', TOKEN值);
    // }
  },
  methods: {
    refontsize(plus) {
      if (plus === '+') {
        this.fontSize += 0.25;
        this.lineHeight += 0.25;
      } else if (plus === '-') {
        this.fontSize = this.fontSize < 0.5 ? 0.25 : (this.fontSize - 0.25);
        this.lineHeight = this.lineHeight < 0.5 ? 0.25 : (this.lineHeight - 0.25);
      } else {
        this.fontSize = 1;
        this.lineHeight = 1;
      }
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'zhTW';
  font-style: normal;
  font-weight: 400;
  src: local('Heiti TC'),
       local('黑體-繁'),
       local('NotoSerifTC-Regular'),
       local('NotoSerifSC-Regular'),
       local('標楷體');
  unicode-range: U+4E00-9FFF;
}
@font-face {
  font-family: 'bo';
  font-style: normal;
  font-weight: 400;
  src: local('Microsoft Himalaya'),
       local('Kailasa'),
       local('Himalaya');
  unicode-range: U+0F00-0FFF;
}
@font-face {
  font-family: 'en';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/OldNewspaperTypes-1.ttf') format('truetype'),
      //  local('OldNewspaperTypes'),
      //  url('High-Tide-Demo-2.ttf') format('truetype'),
      //  local('Entyposs'),
       local('sans-serif'),
       local('Times'),
       local('Microsoft Sans Serif');
  unicode-range: U+00-024F;
}
#app {
  font-family: PingFangTC-Regular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-style: normal;
  // font-family: 'bo', 'en', sans-serif, Tahoma, Arial;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: $wordLineHeight;
  color: #111111;
  word-wrap: break-word;
  @media screen and (max-width: $mobilePortrait) {
    font-size: 18px;
    line-height: $wordLineHeight;
  }
  :focus {
    outline: none;
  }
  a {
    text-decoration: none;
    color: #111111;
  }
  .link {
    color: $mainThemeColor;
    text-decoration: underline;
    cursor: pointer;
  }
  p, span {
    line-height: 1.5em;
  }
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .menuWrap {
    flex: init;
  }
  .contentWrap{
    flex: 1;
    margin-bottom: $margin;
  }
  .footerWrap {
    flex: init;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }

  // button
  ::v-deep .el-button:hover {
    border-color: $buttonTransparent02;
    background-color: $buttonHover02;
  }
  ::v-deep .el-tree-node__content:hover {
    background: $buttonHover02;
  }
  ::v-deep .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    background: $buttonTransparent02;
  }
  ::v-deep .el-input__suffix {
    .el-input__suffix-inner {
      border-color: none;
      .el-icon-circle-close:before {
        content: "\e79d" !important;
        font-size: 18px;
      }
    }
  }

  .effect {
    text-align: center;
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: $mainTextColor;
    text-transform: capitalize;
    border-radius: $radius;
    overflow: hidden;
  }

  .effect.effectAnime {
    transition: all 0.2s linear 0s;
  }
  .effectAnime:hover {
    text-indent: 1em;
  }
  .effectAnime:hover:before {
    transform: scale(1,1);
    text-indent: 0;
  }
  .effectAnime:before {
    content: '\f054';
    font-family: FontAwesome;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0px;
    height: 100%;
    border-radius: 0 50% 50% 0;
    transform: scale(0, 1);
    transform-origin: left center;
    transition: all 0.2s linear 0s;
  }

  // font
  ::v-deep .title, .text {
    line-height: 1.2em;
  }
  .textTitle {
    font-size: 2em;
    line-height: 2em;
  }
  .langIcon {
    background-color: $mainThemeColor;
    color: $mainBgColor;
    font-weight: bolder;
    text-align: center;
    min-width: calc(1em + #{$iconFontSize});
    min-height: calc(1em + #{$iconFontSize});
    width: calc(1em + #{$iconFontSize});
    height: calc(1em + #{$iconFontSize});
    padding: calc(1em - #{$iconFontSize});
    font-size: $iconFontSize;
    float: left;
    margin-right: 1em;
  }
  .material-icons {
    font-size: 1em;
  }
  .material-icons-new {
    font-size: 1em;
  }
}
</style>
