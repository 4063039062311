<template>
  <li id="dictionaryRange">
    <span @click="drawer = true">
      <i class="el-icon-collection"></i>
      {{$t('dictionary.selectDic')}}
    </span>
    <el-drawer
      title=""
      :visible.sync="drawer"
      direction="btt"
      size="100%"
      :before-close="handleClose"
    >
      <div class="title">
        <span>{{$t('dictionary.selectDicRange')}}</span>
        <i class="el-icon-refresh" @click="handleCheckReset()"></i>
      </div>
      <div class="dicMainContent">
      <div class="filterCate">

<div class="dicts" v-if="renderDict" >
  <div class="catDictionary" v-for="(dictType, index) in dictionaryType" :key="index">
        <div class="dicTypeSelect" :class="{'clicked': !noShow[dictType]}">
        <button class="checkbox" type="checkbox" @click="handleCheckAllChange(dictType)">
          <span v-show="checkAll[dictType]">✓</span>
        </button>
        <button class="showDict"  @click="noShow[dictType] = !noShow[dictType]">
          {{$t(`about.dicRange${dictType}`)}}
          <span v-if="noShow[dictType]"> ◂</span>
          <span v-if="!noShow[dictType]"> ▾</span>
        </button>
        </div>
        <div class="checkboxSelect">
          <el-checkbox-group
            v-model="checkedDictaionary"
          >
            <div class="dictCategory" :class="{'noShow': noShow[dictType]}"
              v-if="dictionaryList&&dictionaryList.length"
            >
              <el-checkbox
    v-for="(dictionary, index) in dictionaryList.filter((item) => item.lang['type'] === dictType)"
                :key="index"
                :label="dictionary"
              >
              <span
                v-if="
                  $i18n.locale === 'zh-TW'||$i18n.locale === 'zh-HK'||
                  $i18n.locale === 'zh-CN'||$i18n.locale === 'bo'||
                  $i18n.locale === 'zh-tw'||$i18n.locale === 'zh-hk'||
                  $i18n.locale === 'zh-cn'
                "
              >
                ── {{dictionary.otherName[$i18n.locale] || dictionary.name}}
              </span>
              <span v-else>
                ── {{dictionary.otherName['en'] || dictionary.name}}
              </span>
              </el-checkbox>
            </div>

          </el-checkbox-group>
        </div>
      </div>
</div></div>
  </div>
    </el-drawer>
  </li>
</template>

<script>

export default {
  name: 'DictionaryRange',
  data() {
    return {
      dictionaryType: ['A', 'B', 'C', 'D', 'E'],
      resetAll: false,
      renderDict: (this.$store.state.dictionary.dicList ? 'true' : 'false'),
      drawer: false,
      state: '',
      timeout: null,
      checkAllType: false,
      checkAll: {
        A: false,
        B: false,
        C: false,
        D: false,
        E: false,
      },
      checkedDictaionary: [],
      loading: null,
      noShow: {
        A: false,
        B: false,
        C: false,
        D: false,
        E: false,
      },
    };
  },
  computed: {
    dictionaryList() {
      return this.$store.state.dictionary.dicList;
    },
    dictionaryListALength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'A').length;
      }
      return 0;
    },
    dictionaryListBLength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'B').length;
      }
      return 0;
    },
    dictionaryListCLength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'C').length;
      }
      return 0;
    },
    dictionaryListDLength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'D').length;
      }
      return 0;
    },
    dictionaryListELength() {
      if (this.dictionaryList) {
        return this.dictionaryList.filter((item) => item.lang.type === 'E').length;
      }
      return 0;
    },
    nowDictionaryRange() {
      return this.$store.state.dictionary.nowDicRange;
    },
  },
  watch: {
    checkedDictaionary: {
      handler(val) {
        this.updatedSelectDic();
        if (val) {
          this.checkAll.A = val.filter((item) => item.lang.type === 'A').length === this.dictionaryListALength;
          this.checkAll.B = val.filter((item) => item.lang.type === 'B').length === this.dictionaryListBLength;
          this.checkAll.C = val.filter((item) => item.lang.type === 'C').length === this.dictionaryListBLength;
          this.checkAll.D = val.filter((item) => item.lang.type === 'D').length === this.dictionaryListBLength;
          this.checkAll.E = val.filter((item) => item.lang.type === 'E').length === this.dictionaryListBLength;
          if (val.filter((item) => item.lang.type === 'C').length !== 0) {
            this.checkAll.C = val.filter((item) => item.lang.type === 'C').length === this.dictionaryListCLength;
          }
          if (val.filter((item) => item.lang.type === 'A').length !== 0) {
            this.checkAll.A = val.filter((item) => item.lang.type === 'A').length === this.dictionaryListALength;
          }
          if (val.filter((item) => item.lang.type === 'B').length !== 0) {
            this.checkAll.B = val.filter((item) => item.lang.type === 'B').length === this.dictionaryListBLength;
          }
          if (val.filter((item) => item.lang.type === 'D').length !== 0) {
            this.checkAll.D = val.filter((item) => item.lang.type === 'D').length === this.dictionaryListDLength;
          }
          if (val.filter((item) => item.lang.type === 'E').length !== 0) {
            this.checkAll.E = val.filter((item) => item.lang.type === 'E').length === this.dictionaryListELength;
          }
        }
      },
    },
    nowDictionaryRange: {
      handler() {
        this.renderSelectedDicCatList();
      },
    },
  },
  methods: {
    handleCheckAllChange(val) {
      this[`checkAll[${val}]`] = !this[`checkAll[${val}]`];
      if (this[`checkAll[${val}]`]) {
        const otherCheckDict = this.checkedDictaionary.filter((item) => item.lang.type !== val);
        const valDict = this.dictionaryList.filter((item) => item.lang.type === val);
        this.checkedDictaionary = otherCheckDict.concat(valDict);
      } else {
        const newArray = this.checkedDictaionary.filter((item) => item.lang.type !== val);
        this.checkedDictaionary = newArray;
      }
    },
    updatedSelectDic() {
      const filterID = this.checkedDictaionary.map((item) => item.id).toString();
      this.$store.dispatch('dictionary/setNowDicRange', filterID);
    },
    renderSelectedDicCatList() {
      this.$store.dispatch('category/setSelectedDicRangeCatList', this.$i18n.locale);
    },
    handleCheckReset() {
      this.checkedDictaionary = [];
    },
    handleCheckedDictaionary(value) {
      const checkedCount = value.length;
      this.checkAllType = checkedCount === this.dictionaryList.length;
    },
    handleClose(done) {
      done();
    },
    querySearchAsync(queryString, cb) {
      const { dictionaryList } = this;
      const results = queryString ? dictionaryList.filter(
        this.createStateFilter(queryString),
      ) : dictionaryList;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 300 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        const check = state.name.toLowerCase()
          .indexOf(queryString.toLowerCase()) >= 0;
        return check;
      };
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
#dictionaryRange {
  pointer-events: auto;
  text-align: left;
  .noShow {
    display: none;
  }
  .clicked {
    // background: $buttonTransparent02;
  }
  ::v-deep .el-drawer__body {
    overflow: scroll;
  }
  .dicMainContent {
    display: flex;
    flex-direction: row;
    .filterCate {
      display: block;
      width: 100%;
      flex: 3;
      padding: $padding;
    @media screen and (max-width: $mobileLandscape) {
      padding: 0px;
    }
    }
    .dicts {
      flex: 7;
      display: flex;
      padding: $padding;
      flex-direction: column;
      @media screen and (max-width: $mobileLandscape) {
        padding: 0px;
      }
      .catDictionary:hover {
      border-left: 5px solid $buttonTransparent01;
    }
      .catDictionary {
        border-left: 5px solid transparent;
        padding-left: 1em;
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-top: 2em;
        .dicTypeSelect {
        margin-bottom: 0.5em;
        margin-right: 1em;
        display: flex;
        margin-bottom: 8px;
        margin-bottom: 1em;
        border-bottom: 3px solid $buttonTransparent02;
        @media screen and (max-width: $mobileLandscape) {
          margin-top: 1em;
        }
        .checkbox {
          width: 2em;
          border: none;
          font-size: 0.9em;
          background: $buttonTransparent02;
          @media screen and (max-width: $mobileLandscape) {
            display: block;
            min-width: 2em;
          }
        }
        .showDict {
          border: none;
          font-size: 0.9em;
          padding: 0.1em 1em;
          background: none;
          width: 100%;
          text-align: left;
          font-size: 1.1em;
          font-weight: 500;
        }
      }
      .checkboxSelect {
        ::v-deep .el-checkbox__input {
          display: flex;
          .el-checkbox__inner {
            width: 1em;
            height: 1em;
            border: 1px solid #929192;
            font-size: 2em;
          }
          .el-checkbox__inner::after {
            height: 0.5em;
            width: 0.3em;
            margin-left: 0.15em;
          }
        }
      }
      }
    }
  }
  ::v-deep .el-drawer {
    padding: 0em 2em;
    color: #000;
    padding: $wrapWidth;
    width: 100%;
    .el-button {
      color: $mainTextColor;
      font-weight: normal;
      border-color: $buttonTransparent02;
      background-color: $buttonHover02;
    }
    .el-button:hover {
      border-color: $buttonTransparent02;
      background-color: $buttonHover02;
    }
    .el-drawer__header {
      .el-drawer__close-btn {
        i {
          font-size: 2em;
          font-weight: bolder;
        }
      }
    }
    .el-drawer__body {
      padding: $padding;
      font-size: $pageTitle;
      .title {
        margin: $lineHeight;
        font-size: $pageTitle;
        padding-bottom: 1em;
        @media screen and (max-width: $mobileLandscape) {
        }
        i {
          padding-left: 0.5em;
          cursor: pointer;
          color: $buttonTransparent01;
        }
        i:hover {
          color: $buttonTransparent02;
        }
      }
      .el-checkbox {
        display: flex;
        padding-bottom: 0.5em;
        @media screen and (max-width: $mobileLandscape) {
          display: flex;
          white-space: normal;
        }
      }
      .el-checkbox__label {
        font-size: $logoTitle;
        @media screen and (max-width: $mobileLandscape) {
          padding-bottom: 1em;
        }
        .dicLangs:after {
          content: '/',
        }
        .dicLangs:last-child:after {
          content: '',
        }
      }
    }
  }

}
</style>
