<template>
  <div id="entry">
    <div class="searchArea">
      <div class="searchBar">
        <SearchBar @searchClicked="afterSearch" />
      </div>
    </div>
    <div class="mainContent" :key="refreshResult">
      <div class="onlyMobileShow" :class="{'mobileDisplayChange': mobileChange}">
        <i class="el-icon-back" @click="letMobileChange(true)"></i>
      </div>
      <div class="entryResults" :class="{'mobileDisplayChange': !mobileChange}">

        <div class="tabs">

          <input type="radio" name="tabs" id="tabone"
          :checked="entryTotoalAmount > 0">
          <label for="tabone">{{$t('elements.mainEntry')}}<br/>({{entryTotoalAmount}})</label>
          <div class="tab">
            <EntryResult
              @entryTotal="entryTotal"
              @changeEntryMobile="letMobileChange(false)"
            />
          </div>

          <input type="radio" name="tabs" id="tabtwo"
          :checked="this.$route.params.apiEntryResult !== 1">
          <label for="tabtwo">{{$t('elements.transEntry')}}<br/>({{transTotoalAmount}})</label>
          <div class="tab">
            <EntryResultTrans
              @transTotal="transTotal"
              @changeEntryMobile="letMobileChange(false)"
            />
        </div>
      </div>
      </div>

      <div class="entryInfo" :class="{'mobileDisplayChange': mobileChange}">
        <EntryInfo />
      </div>
    </div>
  </div>
</template>

<script>
import EntryInfo from '@/components/EntryInfo.vue';
import EntryResult from '@/components/EntryResult.vue';
import EntryResultTrans from '@/components/EntryResultTrans.vue';
import SearchBar from '@/components/SearchBar.vue';

import searchAPI from '@/assets/api/search';

export default {
  name: 'Entry',
  data() {
    return {
      entryTabCheck: true,
      refreshResult: 0,
      entryList: null,
      mobileChange: true,
      entryTotoalAmount: 0,
      transTotoalAmount: 0,
    };
  },
  components: {
    EntryInfo,
    EntryResult,
    EntryResultTrans,
    SearchBar,
  },
  computed: {
    nowFolder() {
      return [this.$route.params.entryFolder];
    },
    nowText() {
      return [this.$route.params.entryText];
    },
  },
  mounted() {
    this.getEntryInfo(this.nowFolder, this.nowText);
    this.apiEntryResult = this.$route.params.apiEntryResult;
  },
  methods: {
    afterSearch(val) {
      this.refreshResult += 1;
      this.entryTabCheck = val;
      this.letMobileChange();
    },
    entryTotal(num) {
      if (typeof (num) !== 'undefined') {
        this.entryTotoalAmount = num;
      }
    },
    transTotal(num) {
      if (typeof (num) !== 'undefined') {
        this.transTotoalAmount = num;
      }
    },
    letMobileChange(timeout) {
      if (timeout) {
        this.mobileChange = !this.mobileChange;
      } else {
        setTimeout(() => {
          this.mobileChange = !this.mobileChange;
        }, 500);
      }
    },
    getEntryInfo(entryFolder, entryText) {
      searchAPI.postGetEntryInfo((res) => {
        this.entryList = res;
      }, entryFolder, entryText);
    },
  },
};
</script>

<style lang="scss" scoped>
  #entry {
    .el-icon-back {
      position: fixed;
      background: $buttonTransparent02;
      padding: 0.3em 0.5em;
      left: 0px;
    }
    .searchArea {
      background: #d6c6a3;
      min-height: 10em;
      display: flex;
      justify-content: center;
      background-image: url("./../assets/img/searchBarBG01.png");
      background-size: 100%;
      background-position: 0px -50px;
      position: -webkit-sticky;
      position: sticky;
      background-repeat: no-repeat;
      background-color: $searchBarColor;
      @media screen and (max-width: $mobileLandscape) {
        background-size: cover;
        background-position: 0px 0px;
        background-image: url("./../assets/img/searchBarBG01Mobile.png");
      }
      top: 0;
      .searchBar {
        flex: 1;
        align-self: center;
        max-width: 70%;
        @media screen and (max-width: $mobileLandscape) {
          max-width: 90%;
        }
      }
    }
    .mainContent {
      padding: $wrapWidth;
      margin: $space;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: $mobileLandscape) {
        flex-direction: column;
      }
      .entryResults {
        min-width: 100px;
        flex: 1;
        padding: 3em;
        border: 1px solid $border-color;
        border-radius: $radius;
        box-shadow: $boxShadow2;
        @media screen and (max-width: $mobileLandscape) {
          padding: 0px;
          box-shadow: none;
          border: none;
        }
        .tabs {
          display: flex;
          flex-wrap: wrap;
          background: $shadow;
          border: 1px solid $buttonHover;
        }
        .tabs label {
          order: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1em;
          width: calc(50% - 2em - 4px);
          cursor: pointer;
          transition: background ease 0.3s;
          text-align: center;
          margin: 2px;
        }
        .tabs .tab {
          border-top: $buttonHover solid 1px;
          order: 9;
          flex-grow: 1;
          width: 100%;
          height: 100%;
          display: none;
          padding: 1rem;
          background: #ffffff;
          margin: -1px;
        }
        .tabs input[type="radio"] {
          display: none;
        }
        .tabs input[type="radio"]:checked + label {
          background: $buttonTransparent02;
          border-top-left-radius: 1em;
          border-top-right-radius: 1em;
          background: #ffffff;
          font-weight: bold;
        }
        .tabs input[type="radio"]:checked + label + .tab {
          display: block;
        }
      }
      .entryInfo {
        font-size: 1.4em;
        margin: $contentWrap;
        margin-top: 0px;
        padding: $contentWrap;
        flex: 3;
        text-align: left;
        @media screen and (max-width: $mobileLandscape) {
          margin: 0px;
        }
      }
      .onlyMobileShow {
        @media screen and (min-width: $mobileLandscape) {
          display: none;
        }
      }
      .mobileDisplayChange {
         @media screen and (max-width: $mobileLandscape) {
          display: none;
        }
      }
    }
  }
</style>
