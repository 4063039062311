import dictionaryAPI from '@/assets/api/dictionary';

const category = {
  namespaced: true,
  state: {
    nowDicRange: '',
    dicList: null,
    allDictionaries: null,
  },
  mutations: {
    NOWDICRANGE: (state, data) => {
      if (data.length !== 0) {
        state.nowDicRange = data;
      } else {
        state.nowDicRange = state.allDictionaries;
      }
    },
    DICLIST: (state, data) => {
      state.dicList = data;
    },
    ALLDICTIONARIES: (state, data) => {
      state.allDictionaries = data;
    },
  },
  actions: {
    setNowDicRange({ commit }, data) {
      commit('NOWDICRANGE', data);
    },
    setDicList({ commit }) {
      dictionaryAPI.postDicList((res) => {
        commit('DICLIST', res);
        const idList = res.map((item) => item.id);
        commit('ALLDICTIONARIES', idList);
        commit('NOWDICRANGE', idList);
      });
    },
  },
};

export default category;
