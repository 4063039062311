<template>
  <div id="cnDonate">
    <h2>「桑泊佛学词典」数位化工作</h2>
    <div class="content">
<div>
  <img src="@/../public/img/sambo.jpeg"/>
图为: 吞弥·桑布扎 大师,藏文的主要创制者,翻译许多佛经..
</div>
<div>
  <h3>桑泊佛学电子词典：历时六年与化育汉藏佛学院义工，汇整20多部古今中外，藏、中、英佛学词典，经过分类及整理，
其中有多部稀有珍贵的古籍词典(日后仍会陆续增加)，法友们可以在古今字词解析中，相互比较、对照、进而了解。<br/><br/>
祈：法友在阅读经典时，遇到艰深字词与佛学名相，能够方便的随时随地搜寻查阅，进而了解佛经义理。<br/><br/>
愿：大众阅读佛经时，深入经藏，得到佛的加持与利益。<br/><br/>
～愿以此功德，普及于一切，我等与众生，皆共成佛道。</h3>
    <div>
      <img src="@/../public/img/donateQRcode.jpeg"/>
    </div>
</div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'cnDonate',
  components: {
  },
};
</script>

<style scoped lang="scss">
  #cnDonate {
    margin: 5em;
    h3 {
      font-weight: normal;
      line-height: 1.3em;
    }
    @media screen and (max-width: $mobilePortrait) {
      line-height: $wordLineHeight;
      margin: 1em;
    }
    .content {
      display: flex;
      @media screen and (max-width: $mobilePortrait) {
        margin: 0px;
        flex-direction: column;
      }
      div:first-child {
        flex: initial;
        width: 20%;
        padding-left: 0px;
        border: 1em solid #1f5d750e;
        padding: 1em;
        margin: 1em;
        img {
          width: 100%;
        }
        @media screen and (max-width: $mobilePortrait) {
          width: auto;
          flex-direction: column;
        }
      }
      div {
        flex: 1;
        padding-left: 5em;
        div {
          > img {
            width: 100%;
            max-width: 200px;
            float: right;
            @media screen and (max-width: $mobilePortrait) {
              float: none;
            }
          }
        }
        @media screen and (max-width: $mobilePortrait) {
          padding-left: 2em;
        }
      }
    }
  }
</style>
