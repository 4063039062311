<template>
  <div id="menu">
    <div class="mobile">
      <div class="toolBar">

      <div class="logoArea">
          <router-link to="/">
            <img class="logo" src="@/../public/img/logo/garchenLogo.png" />
          </router-link>
          <router-link to="/">{{$t('header.websiteName')}}</router-link>
      </div>
      <div class="top-icon toggleTopMenu" @click="showTopMenu = true">
        <div class="main-item menu" v-show="showTopMenu === false">
          <i class="material-icons">menu</i>
        </div>
      </div>
      </div>
        <div class="menu-content" v-if="showTopMenu === true">
          <div class="close-button" @click="showTopMenu = false">
            <div></div>
            <i class="material-icons">close</i>
          </div>
          <div class="list">
            <div class="links">
              <li @click="closeMenu()">
                <router-link to="/advanced-search" icon="el-icon-search">
                  {{$t('menu.advSearch')}}
                </router-link>
              </li>
              <li @click="closeMenu()">
                <router-link to="/category">
                  {{$t('menu.category')}}
                </router-link>
              </li>
              <li @click="closeMenu()">
                <router-link to="/alphabet">{{$t('menu.alphabet')}}</router-link>
              </li>
              <li @click="closeMenu()">
                <router-link to="/about">{{$t('menu.about')}}</router-link>
              </li>
              <li @click="closeMenu()">
                <router-link to="/support">{{$t('menu.support')}}</router-link>
              </li>
            </div>
            <div class="title">{{$t('lang.language')}}</div>
            <div class="langs">
              <li @click="handleSetLanguage('en'); closeMenu()">
                <span class="langIcon">en</span> English
              </li>
              <li @click="handleSetLanguage('zh-TW'); closeMenu()">
                <span class="langIcon">繁</span> 繁體中文
              </li>
              <li @click="handleSetLanguage('zh-CN'); closeMenu()">
                <span class="langIcon">简</span> 简体中文
              </li>
              <li @click="handleSetLanguage('bo'); closeMenu()">
                <span class="langIcon">བོད</span> བོད་སྐད།
              </li>
            </div>
        </div>
        </div>
      <div class="mainMenu">
        <div class="linkArea">
          <DictionaryRange />
        </div>
      </div>
    </div>

    <!-- desktop version -->
    <div class="wrap">
      <div class="toolBar">
        <div class="font">
          <button @click="resizeFont('+')">A+</button>
          <button @click="resizeFont('-')">A-</button>
          <button @click="resizeFont('=')">{{$t('elements.reset')}}</button>
        </div>
        <div class="list">
          <li class="effect effectAnime dictionaryRangePadding"><DictionaryRange /></li>
          <li class="effect effectAnime">
            <router-link to="/advanced-search" icon="el-icon-search">
              <i class="el-icon-search"></i>
              {{$t('menu.advSearch')}}
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/login">{{$t('menu.login')}}登入</router-link>
          </li>
          <li>
            <router-link to="/login">{{$t('menu.theme')}}主題</router-link>
          </li>
          <li>
            <router-link to="/login">{{$t('menu.logout')}}登出</router-link>
          </li> -->
          <li class="effect effect effectAnime">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <i class="material-icons">language</i>
                {{$t('menu.language')}}
                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleSetLanguage('bo')">
                  བོད་སྐད།
                </el-dropdown-item>
                <el-dropdown-item @click.native="handleSetLanguage('zh-TW')">
                  繁體中文
                </el-dropdown-item>
                <el-dropdown-item @click.native="handleSetLanguage('zh-CN')">
                  简体中文
                </el-dropdown-item>
                <el-dropdown-item @click.native="handleSetLanguage('en')">
                  English
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </div>
      </div>
      <div class="mainMenu">
        <div class="logoArea">
            <div>
              <router-link to="/">
                <img class="logo" src="@/../public/img/logo/garchenLogo.png" />
              </router-link>
            </div>
            <div>
              <router-link to="/">{{$t('header.websiteName')}}</router-link>
            </div>
        </div>
        <div class="linkArea">
          <ul>
            <li class="effect effectAnime">
              <router-link to="/category">{{$t('menu.category')}}</router-link>
            </li>
            <li class="effect effectAnime">
              <router-link to="/alphabet">{{$t('menu.alphabet')}}</router-link>
            </li>
            <li class="effect effectAnime">
              <router-link to="/about">{{$t('menu.about')}}</router-link>
            </li>
            <li class="effect effectAnime">
              <router-link to="/support">{{$t('menu.support')}}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DictionaryRange from './DictionaryRange.vue';

export default {
  name: 'Menu',
  data() {
    return {
      showTopMenu: false,
    };
  },
  components: {
    DictionaryRange,
  },
  methods: {
    closeMenu() {
      this.showTopMenu = false;
    },
    resizeFont(plus) {
      this.$emit('resizefont', plus);
    },
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('category/setCatList', lang);
      this.$store.dispatch('userSettings/setLanguage', lang);
    },
  },
};
</script>

<style scoped lang="scss">
#menu {
  > .mobile {
    @media screen and (min-width: $mobileLandscape) {
      display: none;
    }
    flex-direction: row;
    .toolBar {
      padding: $contentWrap;
      flex: 1;
      background: $reverseBgColor;
      display: flex;
      align-items: center;
      .top-icon {
        background: $psTextColor;
        flex: init;
        cursor: pointer;
        padding: $contentWrap;
        max-height: 1em;
        width: 1em;
        min-width: 1em;
        display: block;
        .main-item {
          min-width: 1em;
        }
      }
      .logoArea {
        flex: 1;
        font-size: $logoTitleM;
        display: flex;
        align-items: center;
        margin-right: $contentWrap;
        .logo {
          max-width: 2em;
          padding-right: 0.6em;
        }
      }
      a {
        line-height: 1em;
        color: $reverseTextColor
      }
      .list {
        text-align: right;
        list-style: none;
        margin: 0px;
        > li {
          list-style: none;
          display: inline-block;
          margin-left: 1em;
          margin-right: 1em;
          background: gree;
          font-size: 1em;
          cursor: pointer;
          .el-dropdown {
            color: $mainBgColor;
            font-size: 1em;
          }
        }
      }
    }
    .menu-content {
          background: $mainBgColor;
          position: absolute;
          width: 100%;
          min-height: 100%;
          z-index: 999;
          left: 0;
          top: 0;
          flex-direction: row;
          .close-button {
            display: flex;
            padding: $contentWrap;
            i {
              flex: init;
              cursor: pointer;
              padding: 14px;
              background: $psTextColor;
              flex: init;
              color: $mainBgColor;
              cursor: pointer;
            }
            div {
              flex: 1;
              font-size: $logoTitle;
              display: flex;
              align-items: center;
              .logo {
                max-width: 2em;
                padding-right: 0.6em;
              }
            }
          }
          .list {
            padding: $contentWrap;
            .title {
              text-align: left;
              background: $psBgColor;
              padding: 0.5em;
            }
            .links {
              display: block;
              color: $reverseBgColor;
              margin-bottom: $gapHeight;
              li {
                list-style: none;
                text-align: center;
                line-height: calc(#{$wordLineHeight} + #{$iconFontSize});
                border-bottom: $border-color 1px solid;
                a {
                  color: $reverseBgColor;
                }
              }
            }
            .langs {
              display: block;
              li {
                list-style: none;
                text-align: center;
                line-height: calc(#{$wordLineHeight} + #{$iconFontSize});
                min-height: calc(1em + #{$iconFontSize});
                border-bottom: $border-color 1px solid;
                span {
                  float: none;
                  background: $psTextColor;
                }
              }
            }
          }
        }
    .mainMenu {
      padding: $contentWrap;
      padding-bottom: 0px;
      flex: 1;
      background: $mainBgColorTrans;
      display: flex;
      .linkArea {
        flex: 1;
        display: flex;
        padding-bottom: 1em;
        li {
          list-style: none;
        }
        ul {
          display: flex;
          align-self: left;
          list-style: none;
          li {
            padding: $padding;
          }
        }
      }
    }
  }

  > .wrap {
    flex-direction: column;
    @media screen and (max-width: $mobileLandscapeMinus) {
      display: none;
    }
    .downAnime:hover {
      animation: downAnime 1s 1 linear;
    }
    @keyframes downAnime {
      50% {
      color: $mainThemeColor;
      }
      100% {
      color: $buttonTransparent02;
      }
    }
    .effectAnime:before {
      color: $mainBgColor;
    }
    .toolBar {
      padding: $contentWrap;
      flex: 1;
      background: $reverseBgColor;
      color: $reverseTextColor;
      .effect {
        color: $reverseTextColor;
      }
      a {
        color: $reverseTextColor;
      }
      .font {
        display: flex;
      }
      .dictionaryRangePadding {
        margin-right: 1em;
      }
      .list {
        text-align: right;
        list-style: none;
        margin: 0px;
        > li {
          list-style: none;
          display: inline-block;
          margin-left: 1em;
          margin-right: 1em;
          background: gree;
          font-size: 1em;
          cursor: pointer;
          vertical-align: middle;
          .el-dropdown {
            color: $mainBgColor;
            font-size: 1em;
            .material-icons {
              display: unset;
            }
          }
        }
      }
    }
    .mainMenu {
      padding: $contentWrap;
      flex: 1;
      background: $mainBgColorTrans;
      display: flex;
      .logoArea {
        flex: init;
        font-size: $logoTitle;
        display: flex;
        align-items: center;
        .logo {
          max-width: 2em;
          padding-right: 0.6em;
        }
      }
      .linkArea {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        line-height: 1.2em;
        .effectAnime:before {
          color: $buttonHover;
        }
        ul {
          display: flex;
          align-self: center;
          list-style: none;
          li {
            padding: $padding;
          }
        }
      }
    }
  }
}
</style>
