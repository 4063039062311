import qs from 'qs';
import axios from 'axios';
// import en from '../i18n/en';

export default {
  postEntrySearch(callback, tarType, inpKeyword, selFolder, selCategory, i18n,
    startNumber, sort, sizeNumber, sugNumber) {
    const start = startNumber || 0;
    const inpKeyWord = inpKeyword || '';
    const sortFormat = sort || 'text';
    const targetType = tarType || null;
    let selectFolder = null;
    if (selFolder !== undefined) {
      selectFolder = selFolder.toString();
    }

    let size = 15;
    if (sizeNumber !== null && sizeNumber !== undefined) {
      size = sizeNumber;
    }
    let suggestNumber = 0;
    if (sugNumber !== null && sugNumber !== undefined) {
      suggestNumber = sugNumber;
    }
    let i18nLang = i18n;
    if (i18n === 'zh-TW' || i18n === 'zh-CN') {
      i18nLang = 'zh';
    }
    const url = '/api/entrySearch';
    const requestBody = {
      keyword: inpKeyWord,
      folder: selectFolder,
      // category: selCategory,
      category: selCategory.toString(),
      sort: sortFormat,
      size,
      start,
      style: 1,
      lang: i18nLang,
      suggest: suggestNumber,
      target: targetType,
    };

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios.post(url, qs.stringify(requestBody), config)
      .then((res) => {
        this.categoryList = res.data;
        callback(res.data);
      })
      .catch((err) => {
        console.log('entry search err but post', err);
      });
  },
  postGetEntryInfo(callback, entryFolder, entryText) {
    const url = '/api/getEntryInfo';
    // console.log(entryFolder.toString(), entryText.toString());
    const requestBody = {
      folder: entryFolder.toString(),
      text: entryText.toString(),
      showNote: 1,
    };

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios.post(url, qs.stringify(requestBody), config)
      .then((res) => {
        this.categoryList = res.data;
        callback(res.data);
      })
      .catch((err) => {
        console.log('get entry info err but post', err);
      });
  },
  postGetHint(callback, inputKeyword, folder) {
    const url = '/api/getHint';
    const requestBody = {
      keyword: inputKeyword.toString(),
      folder,
      size: 100,
    };

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios.post(url, qs.stringify(requestBody), config)
      .then((res) => {
        this.categoryList = res.data;
        callback(res.data);
      })
      .catch((err) => {
        console.log('get entry info err but post', err);
      });
  },
};
