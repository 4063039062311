<template>
  <div id="memberInner">
    <h1>This is a member-inner page</h1>
  </div>
</template>

<script>
export default {
  name: 'MemberInner',
};
</script>
