<template>
  <div id="footer">
    <div class="mainInfo">
      <div class="logo">
        <img src="@/assets/img/Logo.png">
      </div>
      <div class="info">
        <li>{{$t('footer.eMail')}}</li>
        <li>{{$t('footer.tel')}}</li>
        <li>{{$t('footer.address')}}</li>
        <li>{{$t('footer.copyRight')}}</li>
        <li><router-link to="/policy">Privacy Policy</router-link></li>
        <h3>{{$t('footer.nowOnlineNumber')}}： {{onlineAmount}} </h3>
        <h3>{{$t('footer.visitedNumber')}}： {{sumirise}}</h3>
      </div>
    </div>
    <div class="otherSites">
      <div class="linkArea">
        <li @click="goOutside('https://dharma-treasure.org/')">
          {{$t('footer.webTreasure')}}
        </li>
        <li @click="goOutside('https://adarshah.org/')">
          {{$t('footer.webAdarsha')}}
        </li>
        <li @click="goOutside('https://digital-toolbox.dharma-treasure.org/')">
          {{$t('footer.webToolBox')}}
        </li>
        <li @click="goOutside('https://dharmaebooks.org/')">
          {{$t('footer.webEBook')}}
        </li>
        <!-- <li @click="goOutside('https://support.dharma-treasure.org/')">
          {{$t('footer.webSupport')}}
        </li> -->
      </div>
    </div>

  </div>
</template>

<script>
import staticsAPI from '@/assets/api/statics';

export default {
  name: 'Footer',
  data() {
    return {
      onlineAmount: null,
      sumirise: null,
    };
  },
  mounted() {
    this.peopleSumirise();
    this.nowOnline();
    setInterval(() => {
      this.nowOnline();
    }, 600000);
  },
  methods: {
    goOutside(src) {
      window.open(src, '_blank');
    },
    nowOnline() {
      staticsAPI.postOnlineCount((res) => {
        this.onlineAmount = res.total;
      });
    },
    peopleSumirise() {
      staticsAPI.postOnlineCount((res) => {
        this.sumirise = res.summary;
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.nowOnline());
  },
};
</script>

<style scoped lang="scss">
#footer {
  background: $reverseBgColor;
  color: $reverseTextColor;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: $contentWrap;
  a {
    color: $reverseTextColor;
  }
  li {
    list-style: none;
  }
  .mainInfo {
    flex: 1;
    color: $reverseTextColor;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: $space;
    @media screen and (max-width: $mobileLandscape) {
      flex-direction: column;
    }
    .logo {
      padding: $contentWrap;
      align-self: center;
      img {
        max-width: 8vw;
        @media screen and (max-width: $mobileLandscape) {
          max-width: 80%;
        }
      }
    }
    .info {
      align-self: center;
      padding: $contentWrap;
      text-align: center;
    }
  }
  .otherSites {
    flex: 1;
    display: flex;
    border-top: 1px solid $mainBgColorTrans;
    .linkArea {
      display: flex;
      flex: 1;
      justify-content: center;
      @media screen and (max-width: $mobileLandscape) {
        flex-direction: column;
      }
      li {
        padding: 1em;
        list-style: none;
        cursor: pointer;
      }
    }
  }
}
</style>
