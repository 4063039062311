const auth = {
  namespaced: true,
  state: {
    token: '',
    isLogin: false,
  },
  mutations: {
    SETAUTH: (state, options) => {
      state.token = options.token;
      state.isLogin = options.isLogin;
    },
  },
  actions: {
    setAuth({ commit }, options) {
      commit('SETAUTH', {
        token: options.token,
        isLogin: options.isLogin,
      });
    },
  },
};

export default auth;
