<template>
  <div id="pagination">
    <div class="pageArea">
      <div>
        <button @click="toPageNumber(1)" :class="{'disabledBtn': atFirstPage}">
          <i class="material-icons">skip_previous</i>
        </button>
        <button @click="toPageNumber(currentPage - 1)" :class="{'disabledBtn': atFirstPage}">
          <i class="material-icons">navigate_before</i>
        </button>
      </div>
      <div class="pages">
        <Paginate
          :page-count="Math.ceil(totalPage/15)"
          :page-range=3
          :margin-pages=0
          :first-last-button=false
          prev-class="noShow"
          next-class="noShow"
          :hide-prev-next=false
          active-class="activeBtn"
          v-model="currentPage"
        >
          <span slot="breakViewContent">
          <svg width="16" height="4" viewBox="0 0 16 4">
            <circle fill="#999999" cx="2" cy="2" r="2" />
            <circle fill="#999999" cx="8" cy="2" r="2" />
            <circle fill="#999999" cx="14" cy="2" r="2" />
          </svg>
          </span>
        </Paginate>
      </div>
      <div>
        <button @click="toPageNumber(currentPage + 1)" :class="{'disabledBtn': atLastPage}">
          <i class="material-icons">navigate_next</i>
        </button>
        <button @click="toPageNumber(Math.ceil(totalPage/15))" :class="{'disabledBtn': atLastPage}">
          <i class="material-icons">skip_next</i>
        </button>
      </div>
    </div>

      <div class="pageTotal">
        {{currentPage}}/{{Math.ceil(totalPage/15)}}
      </div>
    <div class="inputArea">
      <input
        v-model="inputCurrentPage"
        type="number"
        :placeholder="$t('elements.enterPage')"
        onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
        min="1"
        :max="Math.ceil(totalPage/15)"
        @keypress.enter="goPageNumber()"
      />
      <button slot="append" @click="goPageNumber()">
        {{$t('elements.comfirm')}}
      </button>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';

export default {
  name: 'Pagination',
  props: {
    totalPage: {
      type: Number,
      default() {
        return null;
      },
    },
    initialPage: {
      type: Number,
      default() {
        return 5;
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      inputCurrentPage: null,
    };
  },
  computed: {
    atFirstPage() {
      return this.currentPage === 1;
    },
    atLastPage() {
      return this.currentPage === (Math.ceil(this.totalPage / 15));
    },
  },
  watch: {
    currentPage(val) {
      this.$emit('goPage', val);
    },
    inputCurrentPage(val) {
      if (val > (Math.ceil(this.totalPage / 15))) {
        this.inputCurrentPage = (Math.ceil(this.totalPage / 15));
      } if (val < 1) {
        this.inputCurrentPage = 1;
      }
    },
  },
  components: {
    Paginate,
  },
  mounted() {
  },
  methods: {
    toPageNumber(number) {
      if (number < (Math.ceil(this.totalPage / 15) + 1) && number > 0) {
        this.currentPage = number;
      }
    },
    goPageNumber() {
      this.currentPage = parseInt(this.inputCurrentPage, 10);
    },
  },
};
</script>

<style scoped lang="scss">
#pagination {
  width: 100%;
  font-size: 1em;
  align-items: center;
  .inputArea {
    display: flex;
    justify-content: center;
    min-width: 6em;
    @media screen and (max-width: $mobileLandscape) {
      display: none;
    }
    input {
      border: none;
      border: 1px solid $psTextColor;
      border-right: none;
      max-width: 5em;
      background: $buttonHover02;
      padding-left: 1em;
      :focus {
        outline: none;
      }
    }
    input:focus {
      outline: none;
    }
    button {
      border: none;
      border: 1px solid $psTextColor;
      padding: 0px 1em;
      margin: 0px;
    }
    button:focus {
      outline: none;
    }
  }
  .pageTotal {
    clear: both;
    text-align: center;
  }
  .pageArea {
    display: flex;
    @media screen and (max-width: $mobileLandscape) {
      padding-top: 1em;
    }
    div {
      flex: initial;
      display: flex;
      justify-content: center;
      button {
        background: transparent;
        border: none;
        i {
          font-size: 1.5em;
        }
      }
      button:hover {
        background-color: $buttonHover02;
        cursor: pointer;
      }
    }
    .pages {
      flex: 1;
      text-align: center;
      ::v-deep ul {
        display: flex;
        justify-content: space-around;
        width: 100%;
        .noShow {
          display: none;
        }
        li {
          list-style: none;
          float: left;
          padding: 8px 8px;
          text-decoration: none;
          border: none;
          color: white;
          font-size: 1em;
          cursor: auto;
          a:focus {
            outline: none;
            cursor: auto;
          }
        }
        li.pagination-active {
        }
        li:hover:not(.activeBtn):not(.disabled) {
          background-color: $buttonHover02;
          cursor: pointer;
        }
        li:focus {
          outline: none;
        }
        .activeBtn{
          border-bottom: 3px solid $buttonTransparent02;
          background-color: $buttonHover02;
          cursor: default;
          a {
            cursor: default;
          }
        }
      }
    }
  }
  .disabledBtn {
    color: $border-color;
    a:focus {
      outline: none;
      cursor: default;
    }
    a {
      cursor: default;
    }
  }
  .disabledBtn:hover {
    background-color: transparent !important;
    cursor: default;
    a {
      cursor: default;
    }
  }
}
</style>
