// import qs from 'qs';
import axios from 'axios';

export default {
  getDownloadAudio(callback, id) {
    const url = '/apis/adarshaplugin/file_servlet/downloadAudio';
    // const lyricsWord = JSON.stringify(id);
    axios.get(url, {
      params: {
        id,
        apiKey: 'MTczMDgyMzE2NGMwNjU2YjIyOTM5ZTliNjllMDExNDc=',
      },
    })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getListAudioID(callback, words) {
    const url = '/apis/adarshaplugin/file_servlet/listAudioID';
    const lyricsWord = JSON.stringify(words);
    axios.get(url, {
      params: {
        lyrics: lyricsWord,
        apiKey: 'MTczMDgyMzE2NGMwNjU2YjIyOTM5ZTliNjllMDExNDc=',
      },
    })
      .then((response) => {
        callback(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
