<template>
  <div id="donate">
    <div class="area">
      <h2>{{$t('donate.introTitle')}}</h2>
      <div class="content">
        <div>
          <img src="@/../public/img/sambo.jpeg"/>
          {{$t('donate.imgPS')}}
        </div>
      <div>
        <h3 v-html="$t('donate.introContent')"></h3>
        <div class="pay">

          <!-- <div>
            <div class="iconImg">
              <a href="https://paypal.me/dharmatreasure63" target="_blank">
                <img src="@/../public/img/paypalpayIcon.jpg"/>
                <div class="title">
                  {{$t('donate.paypalPay')}}
                </div>
              </a>
            </div>
          </div> -->

          <div>
            <div class="iconImg">
            <a href="https://dict.dharma-treasure.org/taiwanAccount" target="_blank">
              <img src="@/../public/img/taiwanpayIcon.jpg"/>
              <div class="title">
                {{$t('donate.taiwanCurrency')}}
              </div>
            </a>
            </div>
          </div>

          <div>
            <div class="iconImg">
              <a href="https://dict.dharma-treasure.org/foreignCurrencyAccount" target="_blank">
                <img src="@/../public/img/foreignpayIcon.jpg"/>
                <div class="title">
                  {{$t('donate.foreignCurrency')}}
                </div>
              </a>
            </div>
          </div>

          <div>
            <div class="iconImg">
            <a href="https://karmapawish.neticrm.tw/civicrm/contribute/transact?reset=1&id=8" target="_blank">
              <img src="@/../public/img/creditpayIcon.jpg"/>
              <div class="title">
                {{$t('donate.creditPay')}}
              </div>
            </a>
            </div>
          </div>

          <!-- <div>
          <div class="iconImg">
            <a href="https://dict.dharma-treasure.org/img/donateQRcode.jpeg" target="_blank">
              <img src="@/../public/img/alipayIcon.jpeg"/>
              <div class="title">
                {{$t('donate.aliPay')}}
              </div>
            </a>
          </div>
        </div> -->

        </div>
      </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Donate',
  mounted() {
    const language = this.$route.params.lang;
    if (language === 'tw' || language === 'bo'
      || language === 'cn' || language === 'en') {
      console.log('language === ');
      switch (language) {
        case 'tw':
          this.handleSetLanguage('zh-TW');
          break;
        case 'cn':
          this.handleSetLanguage('zh-CN');
          break;
        case 'bo':
          this.handleSetLanguage('bo');
          break;
        default:
          this.handleSetLanguage('en');
          break;
      }
    }
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('category/setCatList', lang);
      this.$store.dispatch('userSettings/setLanguage', lang);
    },
  },
};
</script>

<style scoped lang="scss">
  #donate {
    margin: 5em;
    h3 {
      font-weight: normal;
      line-height: 1.3em;
    }
    @media screen and (max-width: $mobilePortrait) {
      line-height: $wordLineHeight;
      margin: 1em;
    }
    .area {
      padding: 1em;
      .content {
        display: flex;
        @media screen and (max-width: $mobilePortrait) {
          margin: 0px;
          flex-direction: column;
        }
        > div:first-child {
          flex: initial;
          width: 20%;
          padding-left: 0px;
          border: 1em solid #1f5d750e;
          padding: 1em;
          margin: 1em;
          > img {
            width: 100%;
          }
          @media screen and (max-width: $mobilePortrait) {
            width: auto;
            flex-direction: column;
          }
        }
        > div {
          flex: 1;
          padding-left: 5em;
          @media screen and (max-width: $mobilePortrait) {
            padding-left: 2em;
          }
        }
      }
    }
    .pay {
      display: flex;
      flex-direction: row;
      margin-top: 4em;
      @media screen and (max-width: $mobilePortrait) {
        flex-direction: column;
        margin-top: 2em;
      }
      div {
        flex: 1;
        text-align: center;
        @media screen and (max-width: $mobilePortrait) {
          padding-top: 1em;
        }
        .title {
          text-align: center;
        }
        .iconImg {
          img {
          width: 100px;
          background: red;
          @media screen and (max-width: $mobilePortrait) {
          }
          a {
            text-decoration: none;
          }
          }
        }
      }
    }
  }
</style>
