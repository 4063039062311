import { render, staticRenderFns } from "./EntryResultTrans.vue?vue&type=template&id=987d566e&scoped=true"
import script from "./EntryResultTrans.vue?vue&type=script&lang=js"
export * from "./EntryResultTrans.vue?vue&type=script&lang=js"
import style0 from "./EntryResultTrans.vue?vue&type=style&index=0&id=987d566e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "987d566e",
  null
  
)

export default component.exports