import { render, staticRenderFns } from "./NoResult.vue?vue&type=template&id=78d1b500&scoped=true"
import script from "./NoResult.vue?vue&type=script&lang=js"
export * from "./NoResult.vue?vue&type=script&lang=js"
import style0 from "./NoResult.vue?vue&type=style&index=0&id=78d1b500&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78d1b500",
  null
  
)

export default component.exports