import qs from 'qs';
import axios from 'axios';

export default {
  postOnlineCount(callback) {
    const url = '/api/onlineCount';
    const requestBody = {};

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios.post(url, qs.stringify(requestBody), config)
      .then((res) => {
        callback(res.data);
      })
      .catch((err) => {
        console.log('get entry info err but post', err);
      });
  },
};
