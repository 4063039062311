<template>
  <div id="alphabetResult">
    <div class="onlyMobileShow" :class="{'mobileDisplayChange': mobileChange}">
      <i class="el-icon-back" @click="letMobileChange(true)"></i>
    </div>
    <div class="result" :class="{'mobileDisplayChange': !mobileChange}">
    <Loading v-if="!postAPIData" marginTop="5em"/>
      <div v-if="postAPIData && postAPIData.length" class="mainContent">
      <div class="total" style="float: right; line-height: 2em;">
        {{$t('elements.totalNumber')}}：{{totalAmount}}
      </div>
        <li
          style="clear: both"
          v-for="(word, index) in postAPIData"
          :key="index"
          :class="{
            'hightLight': nowRouterFolder.toString() === word.folder.toString()
             && nowRouterText.toString() === word.text.toString()
          }"
          @click="getEntryInfo(word.folder, word.text);letMobileChange(true)"
        >
          <span class="caption">{{word.text}}</span>
          <!-- <div class="psTrans">
            <span v-if="word.en">{{word.en}}</span>
            <span v-if="word.bo">{{word.bo}}</span>
            <span v-if="word.sa">{{word.sa}}</span>
            <span v-if="word['zh-CN']">{{word['zh-CN']}}</span>
            <span v-if="word['zh-TW']">{{word['zh-TW']}}</span>
            <span v-if="word.ja">{{word.ja}}</span>
          </div> -->
        </li>
        <div class="pages">
          <Pagination :totalPage="totalAmount" @goPage="changePagination"/>
        </div>
      </div>
      <div v-if="postAPIData && !postAPIData.length" class="noResult">
        <span class="caption">{{$t('alphabet.noResult')}}</span><hr>
        {{$t('alphabet.plzChangeAlphabet')}}
      </div>
    </div>
    <div class="resultInfo" :class="{'mobileDisplayChange': mobileChange}">
      <div class="breadcrumb">
        <li><router-link to="/alphabet">{{$t('alphabet.alphabet')}}</router-link></li>
        <li v-for="(branch, index) in breadCrumb" :key="index">
          <router-link :to="`${branch.path}`">
            {{$t(branch.i18nName)}}
          </router-link>
        </li>
        <li>{{this.nowRouterAlphabet}}</li>
      </div>
      <EntryResultInfo :entryList="entryList"/>
      <div v-if="entryList && entryList.error" class="noResult">
        <NoResult />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import { mapGetters } from 'vuex';
import searchAPI from '@/assets/api/search';
import NoResult from '../components/NoResult.vue';
import EntryResultInfo from '../components/EntryResultInfo.vue';
import Loading from '../components/Loading.vue';
import Pagination from '../components/Pagination.vue';

export default {
  name: 'AlphabetResult',
  data() {
    return {
      postAPIData: null,
      getAPIData: null,
      nowClick: null,
      entryListJson: null,
      entryList: null,
      nowPageStart: null,
      islast: false,
      boFirstKey: null,
      totalAmount: null,
      mobileChange: true,
    };
  },
  components: {
    NoResult,
    EntryResultInfo,
    Loading,
    Pagination,
  },
  computed: {
    ...mapGetters([
      'nowDicRange',
    ]),
    nowDicRange: {
      get() {
        return this.$store.state.dictionary.nowDicRange;
      },
      set() {
      },
    },
    nowRouterAlphabetLang() {
      return this.$route.params.alphabetLang;
    },
    nowRouterAlphabet() {
      return this.$route.params.alphabet;
    },
    nowRouterFolder() {
      return this.$route.params.folder;
    },
    nowRouterText() {
      return this.$route.params.text;
    },
    breadCrumb: {
      get() {
        let nowAlphabetLang = '';
        const result = [];
        let firstLetter = '';
        switch (this.nowRouterAlphabetLang) {
          case 'zh-TW':
            nowAlphabetLang = 'alphabet.traditionalChinese';
            firstLetter = this.nowRouterAlphabet.substr(0, 1);
            result.push({
              path: `/alphabet/${this.nowRouterAlphabetLang}/${firstLetter}`,
              i18nName: firstLetter,
            });
            break;
          case 'zh-CN':
            nowAlphabetLang = 'alphabet.simpleChinese';
            firstLetter = this.nowRouterAlphabet.substr(0, 1);
            result.push({
              path: `/alphabet/${this.nowRouterAlphabetLang}/${firstLetter}`,
              i18nName: firstLetter,
            });
            break;
          case 'bo':
            nowAlphabetLang = 'alphabet.tibetan';
            firstLetter = this.boFirstKey;
            result.push({
              path: `/alphabet/${this.nowRouterAlphabetLang}/${firstLetter}`,
              i18nName: firstLetter,
            });
            break;
          default:
            nowAlphabetLang = 'alphabet.english';
        }
        result.unshift({
          path: `/alphabet/${this.nowRouterAlphabetLang}`,
          i18nName: nowAlphabetLang,
        });
        return result;
      },
      set() {
      },
    },
  },
  watch: {
    nowDicRange() {
      this.postAlphabetAPI(0, true);
    },
  },
  mounted() {
    const r = /^\+?[1-9][0-9]*$/;

    if (r.test(this.nowRouterFolder)) {
      this.postAlphabetAPI(0, true);
      this.$nextTick(() => {
        this.getEntryInfo(this.nowRouterFolder, this.nowRouterText);
      });
    } else {
      this.postAlphabetAPI(0, false);
    }
    if (this.nowRouterAlphabetLang === 'bo') {
      this.FindBoFirstLetter(this.nowRouterAlphabet);
    }
  },
  methods: {
    letMobileChange(timeout) {
      if (timeout) {
        this.mobileChange = !this.mobileChange;
      } else {
        setTimeout(() => {
          this.mobileChange = !this.mobileChange;
        }, 1500);
      }
    },
    FindBoFirstLetter(word) {
      axios.get('/alphabet/bo.json')
        .then((res) => {
          res.data.forEach((itemCat) => {
            const newItem = itemCat.list.find((item) => item === word);
            if (newItem) {
              this.boFirstKey = itemCat.key;
              return itemCat.key;
            }
            return itemCat.key;
          });
        }, (error) => {
          console.log('get-error', error);
          return null;
        });
    },
    changePagination(pageNo) {
      this.nowPageStart = pageNo - 1;
      this.postAlphabetAPI(this.nowPageStart * 15, true);
    },
    getEntryInfo(entryFolder, entryText) {
      searchAPI.postGetEntryInfo((res) => {
        this.entryList = res;
        this.entryListJson = JSON.stringify(res, null, 2);
      }, entryFolder, entryText);
      const changeFolder = entryFolder.toString() !== this.nowRouterFolder;
      const changeText = entryText.toString() !== this.nowRouterText;
      if (changeFolder || changeText) {
        this.$router.push({
          name: 'AlphabetResult', params: { folder: entryFolder, text: entryText },
        });
      }
    },
    postAlphabetAPI(startNum, changePage) {
      const start = startNum || 0;
      const url = '/api/entryList';
      const requestBody = {
        key: this.$route.params.alphabet,
        lang: this.$route.params.alphabetLang,
        folder: this.$store.state.dictionary.nowDicRange,
        sort: 'text',
        size: 15,
        start,
      };
      const config = {
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
        },
      };

      this.$axios.post(url, qs.stringify(requestBody), config)
        .then((res) => {
          const result = { ...res.data.list[0] };
          if (res.data.total) {
            this.totalAmount = res.data.total;
          }
          this.postAPIData = res.data.list;
          if (!changePage) {
            this.getEntryInfo(result.folder, result.text);
          }
        })
        .catch((err) => {
          console.log('work err but post', err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
  #alphabetResult {
    padding: $wrapWidth;
    margin: $space;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: $mobileLandscape) {
      flex-direction: column;
      padding-top: 0px;
    }
    .el-icon-back {
      position: fixed;
      background: $buttonTransparent02;
      padding: 0.3em 0.5em;
      left: 0px;
    }
    .caption {
      font-size: $pageTitle;
      margin-bottom: 1em;
    }
    .result {
      min-width: 100px;
      flex: 1;
      padding: 3em;
      border: 1px solid $border-color;
      border-radius: $radius;
      box-shadow: $boxShadow;
      @media screen and (max-width: $mobileLandscape) {
        padding: 0px;
        box-shadow: none;
        border: none;
      }
      li {
        list-style: none;
        cursor: pointer;
        padding: $padding;
        margin: $contentWrap;
        .caption {
          font-size: $logoTitle;
          padding: 0px;
        }
        .psTrans {
          color: $psTextColor;
          span::after {
            content: '/';
          }
          span:last-child::after {
            content: '';
          }
        }
      }
      li:hover {
        background: $buttonHover02;
      }
      .hightLight {
        background: $buttonTransparent02;
      }
    }
    .pages {
      display: flex;
      margin-top: $gapHeight;
      padding: $padding;
      .el-button {
        flex:  1;
      }
      .el-button:hover {
        border-color: $buttonTransparent02;
        background-color: $buttonHover02;
      }
    }
    .resultInfo {
      font-size: 1.4em;
      margin: $contentWrap;
      margin-top: 0px;
      padding: $contentWrap;
      flex: 3;
      text-align: left;
      @media screen and (max-width: $mobileLandscape) {
        margin: 0px;
      }
      .breadcrumb {
        background: $shadow;
        padding: $padding;
        line-height: 1em;
        margin-bottom: $gapHeight;
        margin-left: -1em;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        @media screen and (max-width: $mobileLandscape) {
          width: 100%;
          padding: 0.3em;
          margin: 0px;
        }
        li {
          list-style: none;
          font-size: $psFontSize;
          padding-right: $contentWrap;
          :after {
            padding-left: $contentWrap;
            content: '/';
          }
        }
        li:last-child {
          :after {
            content: '';
          }
        }
      }
    }
    .onlyMobileShow {
      @media screen and (min-width: $mobileLandscape) {
        display: none;
      }
    }
    .mobileDisplayChange {
        @media screen and (max-width: $mobileLandscape) {
        display: none;
      }
    }
  }
</style>
