<!-- eslint-disable -->
<template>
  <div id="foreignCurrency">
    <h3>台幣帳戶匯款：</h3>
    <strong>護持帳號：</strong> 812台新銀行20410100005638 建橋分行<br/>
    <strong>帳號名稱：</strong> 台灣滿願學會<br/>
    <strong>電話:</strong> 02-87860726<br/>
    <strong>地址:</strong> 110台北市信義區忠孝東路五段236巷10弄7號1樓<br/>
    <strong>email:</strong> karmapawish@gmail.com<br/>
    如需收據，請告知，掛號寄出！<br/>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'ForeignCurrency',
};
</script>

<style scoped lang="scss">
  #foreignCurrency {
    margin: 3em;
    font-size: 1.2em;
    line-height: 1.3em;
    @media screen and (max-width: $mobilePortrait) {
      margin: 1em;
    }    
  }
</style>
