import qs from 'qs';
import axios from 'axios';
// import en from '../i18n/en';

export default {
  postCatList(callback, languageSetting) {
    const url = '/api/categoryList';
    const requestBody = {
      lang: languageSetting,
    };

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios.post(url, qs.stringify(requestBody), config)
      .then((res) => {
        this.categoryList = res.data;
        callback(res.data);
      })
      .catch((err) => {
        console.log('post cat list err but post', err);
      });
  },
  postSelectDicCatList(callback, pathRouter, folderRange, startNumber, i18n) {
    const url = '/api/entrySearch';
    const start = startNumber || 0;
    let i18nLang = i18n;
    if (i18n === 'zh-TW' || i18n === 'zh-CN') {
      i18nLang = 'zh';
    }
    const requestBody = {
      folder: folderRange,
      category: pathRouter,
      size: 15,
      style: 1,
      start,
      lang: i18nLang,
    };

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios.post(url, qs.stringify(requestBody), config)
      .then((res) => {
        this.categoryList = res.data;
        callback(res.data);
      })
      .catch((err) => {
        console.log('post select dic cat list err but post', err);
      });
  },
};
