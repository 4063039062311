<template>
  <div id="result">
    <div class="searchArea">
      <div class="searchBar">
        <SearchBar/>
      </div>
    </div>
    <div class="mainContent">
      <p>
        result
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'Home',
  components: {
    SearchBar,
  },
};
</script>

<style lang="scss" scoped>
  #result {
    .searchArea {
      min-height: 15em;
      display: flex;
      justify-content: center;
      background-image: url("./../assets/img/searchBarBG01.png");
      background-size: 100%;
      background-position: 0px -50px;
      background-repeat: no-repeat;
      background-color: $searchBarColor;
      @media screen and (max-width: $mobileLandscape) {
        background-image: none;
        background-size: cover;
        background-image: url("./../assets/img/searchBarBG01Mobile.png");
        background-position: 0px 0px;
      }
      .searchBar {
        flex: 1;
        align-self: center;
        max-width: 70%;
        @media screen and (max-width: $mobileLandscape) {
          max-width: 90%;
        }
      }
    }
    .mainContent {
      margin: $space;
      text-align: center;
      padding: $contentWrap;
    }
  }
</style>
