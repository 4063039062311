<template>
  <div id="entryNoResult">
    <div class="mainContent">
      <div class="caption">
        {{$t('result.noInputResult')}}
      </div>
      <div class="otherEntry" v-if="resultList && resultList.length">
        <div class="subCaption">
          {{$t('result.orTryOtherWord')}}：
        </div>
        <div class="entryList">
          <li
            v-for="(result, index) in resultList"
            :key="index"
            @click="showResult(result.folder, result.text)"
          >
            {{result.text}}
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchAPI from '@/assets/api/search';

export default {
  name: 'EntryNoResult',
  data() {
    return {
      resultList: null,
      nowPageStart: 0,
      isLast: false,
    };
  },
  computed: {
    nowLang() {
      const word = this.avoidSqlInjection(this.$route.params.searchKWord);
      const juWord = word.substr(0, 1);
      return this.determineLang(juWord);
    },
    nowKeyword() {
      const word = this.avoidSqlInjection(this.$route.params.searchKWord);
      return word;
    },
    startNumber() {
      return this.nowPageStart * 15 + 1;
    },
  },
  watch: {
    nowKeyword(val) {
      this.getEntrySearch(val);
    },
  },
  mounted() {
    this.getEntrySearch(this.nowKeyword);
  },
  methods: {
    determineLang(str) {
      const engPattern = new RegExp('[A-Za-z]+');
      const tibetanPattern = new RegExp('[\u0F00-\u0FFF]+');
      if (engPattern.test(str)) {
        return ('eng');
      }
      if (tibetanPattern.test(str)) {
        return ('bo');
      }
      return ('other');
    },
    avoidSqlInjection(str) {
      let newString = '';
      if (str !== '') {
        newString = str.replace(/[`~!@#$^&?￥*%()=|{}:;,<>~！……/_（）——|'；："。，、？\s]/ig, '');
      }
      return newString;
    },
    showResult(entryFolder, entryText) {
      searchAPI.postEntrySearch(() => {
        this.$router.push({
          name: 'Entry',
          params: {
            entryFolder,
            entryText,
            searchKWord: entryText,
            apiEntryResult: 1,
          },
        }).catch(() => {});
      }, 'Entry', this.nowKeyword[0], this.$store.state.dictionary.nowDicRange,
      this.$store.state.category.nowCatRange, this.$i18n.locale);
    },
    getEntrySearch(nowKeyword) {
      searchAPI.postEntrySearch((res) => {
        if (res.suggest) {
          this.resultList = res.suggest;
        }
      }, null, nowKeyword, this.$store.state.dictionary.nowDicRange,
      this.$store.state.category.nowCatRange, this.$i18n.locale, this.startNumber,
      null, 0, 16);
    },
  },
};
</script>

<style lang="scss" scoped>
  #entryNoResult {
    .noResult {
      width: 100%;
      text-align: center;
      font-size: $contentCaption;
      i {
        font-size: $bigIconSize;
        color: $buttonHover;
      }
    }
    .mainContent {
      .otherEntry {
        border-left: 1em solid $buttonTransparent02;
        padding: 1em 0px;
      }
      .caption {
        font-size: $contentCaption;
        padding: $lineHeight;
        line-height: 1.3em;
        padding-top: 0px;
        padding-bottom: calc(#{$psPadding} * 2);
      }
      .subCaption {
        font-size: $pageTitle;
        padding-left: calc(#{$contentPadding} * 2);
        padding-bottom: calc(#{$contentPadding} * 2);
      }
      .entryList {
        font-size: $pageTitle;
        display: flex;
        flex-wrap: wrap;

        li {
          cursor: pointer;
          margin: $contentPadding;
          margin-top: 0px;
          margin-bottom: 0px;
          padding: $contentPadding;
          text-decoration: underline;
          text-decoration-color: $psBgColor;
          list-style: none;
        }
        li:hover {
          background: $buttonHover02;
          text-decoration-color: $buttonTransparent02
        }
      }
      .pages {
        display: flex;
        margin-top: $gapHeight;
        padding: $padding;
        .el-button {
          flex:  1;
        }
        .el-button:hover {
          border-color: $buttonTransparent02;
          background-color: $buttonHover02;
        }
      }
    }
  }
</style>
