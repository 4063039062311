<template>
  <div id="entryNo">
    <div class="searchArea">
      <div class="searchBar">
        <SearchBar @refresh="refreshIt" />
      </div>
    </div>
    <div class="mainContent">
      <div class="entryResults">
        <EntryNoResult :key="refresh" />
      </div>
    </div>
  </div>
</template>

<script>
import EntryNoResult from '@/components/EntryNoResult.vue';
import SearchBar from '@/components/SearchBar.vue';

import searchAPI from '@/assets/api/search';

export default {
  name: 'EntryNo',
  data() {
    return {
      entryList: null,
      refresh: 0,
    };
  },
  components: {
    EntryNoResult,
    SearchBar,
  },
  computed: {
    nowFolder() {
      return [this.$route.params.entryFolder];
    },
    nowText() {
      return [this.$route.params.entryText];
    },
  },
  mounted() {
    this.getEntryInfo(this.nowFolder, this.nowText);
  },
  methods: {
    refreshIt() {
      this.refresh += 1;
    },
    getEntryInfo(entryFolder, entryText) {
      searchAPI.postGetEntryInfo((res) => {
        this.entryList = res;
      }, entryFolder, entryText);
    },
  },
};
</script>

<style lang="scss" scoped>
  #entryNo {
    .searchArea {
      min-height: 10em;
      display: flex;
      justify-content: center;
      background-image: url("./../assets/img/searchBarBG01.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: -50px 50px;
      background-repeat: no-repeat;
      background-color: $searchBarColor;
      @media screen and (max-width: $mobileLandscape) {
        background-size: cover;
        background-position: 0px 0px;
        background-image: url("./../assets/img/searchBarBG01Mobile.png");
      }
      .searchBar {
        flex: 1;
        align-self: center;
        max-width: 70%;
        @media screen and (max-width: $mobileLandscape) {
          max-width: 90%;
        }
      }
    }
    .mainContent {
      padding: $wrapWidth;
      margin: $space;
      display: flex;
      flex-direction: row;
      .entryResults {
        min-width: 100px;
        flex: 1;
      }
      .entryInfo {
        font-size: 1.4em;
        margin: $contentWrap;
        margin-top: 0px;
        padding: $contentWrap;
        flex: 3;
        text-align: left;
      }
    }
  }
</style>
