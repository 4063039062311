import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
import category from './modules/category';
import dictionary from './modules/dictionary';
import userSettings from './modules/userSettings';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    category,
    dictionary,
    userSettings,
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        auth: val.auth,
      };
    },
  })],
});
