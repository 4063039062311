<template>
  <div id="alphabetTableLv2">
      <div class="breadcrumb">
        <li><router-link to="/alphabet">{{$t('menu.alphabet')}}</router-link></li>
        <li>
          <router-link :to="`/alphabet/${nowRouterAlphabetLang}`">
            {{$t(`${nowAlphabetLangI18n}`)}}
          </router-link>
        </li>
        <li>{{this.nowRouterAlphabet}}</li>
      </div>
    <div class="row">
      <div v-for="(item, index) in newTable" :key="index">
        <router-link :to="`/alphabet/${alphabetLang}/${item}/result/index/index`">
          <el-button>
            {{item}}
          </el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AlphabetTableLv2',
  data() {
    return {
      newTable: null,
      alphabetLang: this.$route.params.alphabetLang,
    };
  },
  components: {
  },
  computed: {
    nowRouterAlphabetLang() {
      return this.$route.params.alphabetLang;
    },
    nowRouterAlphabet() {
      return this.$route.params.alphabetLangLv2;
    },
    nowAlphabetLangI18n() {
      let nowAlphabetLang = '';
      switch (this.nowRouterAlphabetLang) {
        case 'zh-TW':
          nowAlphabetLang = 'alphabet.traditionalChinese';
          break;
        case 'zh-CN':
          nowAlphabetLang = 'alphabet.simpleChinese';
          break;
        case 'bo':
          nowAlphabetLang = 'alphabet.tibetan';
          break;
        default:
          nowAlphabetLang = 'alphabet.english';
      }
      return nowAlphabetLang;
    },
  },
  mounted() {
    this.getAlphabetTableLv2();
  },
  methods: {
    getAlphabetTableLv2() {
      let lang = this.$route.params.alphabetLang;
      const langLv2 = this.$route.params.alphabetLangLv2;

      lang = `../../alphabet/${lang}.json`;

      axios.get(lang)
        .then((res) => {
          this.newTable = res.data.filter((item) => item.key === langLv2);
          this.newTable = this.newTable[0].list;
        }, (error) => {
          console.log('get-error', error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
  #alphabetTableLv2 {
    padding: $wrapWidth;
    size: $logoTitle;
    ::v-deep .el-button:hover {
      border-color: $buttonTransparent02;
      background-color: $buttonHover02;
    }
    .row {
      display: flex;
      flex-wrap: wrap;
      margin: $margin;
      max-width: 100%;
      justify-content: center;
      @media screen and (max-width: $mobileLandscape) {
        margin: 0px;
        margin-top: $margin;
      }
      .el-button {
        margin: 0.5em;
        font-size: $logoTitle;
        @media screen and (max-width: $mobileLandscape) {
          margin: 0.3em;
        }
      }
    }
    .breadcrumb {
      background: $shadow;
      padding: $padding;
      margin: $margin;
      margin-top: 0px;
      margin-bottom: $gapHeight;
      display: flex;
      line-height: 1em;
      flex-wrap: wrap;
      flex-direction: row;
      @media screen and (max-width: $mobileLandscape) {
        width: 100%;
        padding: 0.3em;
        margin: 0px;
      }
      li {
        list-style: none;
        padding-right: $contentWrap;
        :after {
          padding-left: $contentWrap;
          content: '/';
        }
      }
      li:last-child {
        :after {
          content: '';
        }
      }
    }
  }
</style>
