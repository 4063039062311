<template>
  <div id="memberReader">
    <h1>This is a member-reader page</h1>
  </div>
</template>

<script>
export default {
  name: 'MemberReader',
};
</script>
