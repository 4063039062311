export default {
  header: {
    websiteName: '桑泊词典搜寻网站',
  },
  footer: {
    eMail: '电子邮件：support@dharma-treasure.org',
    tel: '电话：+886-2-27586828',
    address: '地址：111046 台北市士林区德行西路七号二楼之2B室',
    copyRight: 'Copyright © 2021 Dharma Treasure Corp. All rights reserved.',
    webTreasure: '正法宝藏官网',
    webAdarsha: '明镜',
    webToolBox: '澄水宝珠',
    webEBook: '正法电子书',
    webSupport: '支援网站',
    nowOnlineNumber: '目前在线人数',
    visitedNumber: '网站总浏览人次',
  },
  menu: {
    menu: '选单',
    language: '语系',
    category: '词条分类',
    alphabet: '字母排序',
    advSearch: '进阶搜寻',
    about: '关于',
    login: '登入',
    logout: '登出',
    theme: '主题',
    support: '支援',
  },
  button: {
    preLevelPage: '上一层',
    prePage: '上一页',
    nextPage: '下一页',
  },
  lang: {
    language: '语系',
    bo: '藏文',
    en: '英文',
    'zh-TW': '繁中',
    'zh-CN': '简中',
    sa: '梵文',
  },
  index: {
    index: '首页',
    theme: '主题',
    tagline: '藏典明义',
    slogan: '桑泊词典搜寻网站之名称取自藏文之创始人「图弥·桑泊札」以彰其功。由《正法宝藏藏经数位团队》以现代网路科技整合具有参考价值之古今藏文词典、藏汉词典、汉藏词典等二十余万词条及解释，并进行词条翻译及释义分类，为学习佛典及从事佛学研究工作者提供一个优质的参考工具。',
    download: '下载',
    bgBlack: '黑底',
    bgWhite: '白底',
  },
  dictionary: {
    dictionary: '词典',
    selectDic: '选择词典',
    selectDicRange: '选择词典',
    dicTypeA: '藏文词典',
    dicTypeB: '藏汉词典',
    dicTypeC: '汉藏词典',
    dicTypeD: '藏英词典',
    dicTypeE: '汉文词典',
  },
  category: {
    category: '分类',
    normaVocabulary: '一般词',
    civilization: '文明',
    terminology: '专业术语',
    religion: '宗教',
    miscellaneous: '杂',
    noThisCategory: '无此分类，请确认您输入的关键字',
    selectCatRange: '选择分类范围',
    nowCatRange: '目前选取范围',
    allCategory: '全部分类',
    noRelativeCat: '无相关类别',

  },
  alphabet: {
    alphabet: '字母排序',
    bo: '藏文 - ཀ་ཁ་ག་ང་',
    en: '英文 - ABCD',
    zhTW: '繁中 - ㄅㄆㄇㄈ',
    zhCN: '简中 - abcd',
    tibetan: '藏文',
    boAlphabet: 'ཀ་ཁ་ག་ང་ཅ་ཆ་ཇ་ཉ',
    english: '英文',
    enAlphabet: 'ABCDEFGH',
    traditionalChinese: '繁体中文',
    zhTWAlphabet: 'ㄅㄆㄇㄈㄉㄊㄋㄌ',
    simpleChinese: '简体中文',
    zhCNAlphabet: 'abcdefgh',
    noResult: '无相关结果',
    plzChangeAlphabet: '请至其他字母查询，或点选右上角“选择词典”更换您的搜寻范围',
  },
  search: {
    search: '搜寻',
    keywordPls: '请输入关键字',
    catRange: '选择分类',
    selectCatRange: '选择分类范围',
    keyword: '关键字',
  },
  advancedSearch: {
    advancedSearch: '进阶搜寻',
    noResult: '无相关搜寻结果',
    result: '搜寻结果',
    goForSearch: '进行搜寻',
    keyword: '关键字',
  },
  about: {
    mainTitle: '《桑泊词典》搜寻网站',
    mainIntro: '桑泊词典搜寻网站之名称取自藏文之创始人「图弥·桑泊札」以彰其功。由《正法宝藏藏经数位团队》以现代网路科技整合具有参考价值之古今藏文词典、藏汉词典、汉藏词典等二十余万词条及解释，并进行词条翻译及释义分类，为学习佛典及从事佛学研究工作者提供一个优质的参考工具。',
    area01Title: '现阶段我们提供已完成数位化的词典有三本：',
    area01Content: '',
    area02Title: '《藏汉大词典》',
    area02Content: '于1984年由张怡荪主编，中国民族出版社发行。收录藏语词汇约五万三千余条，是近代使用较广的藏汉词典。',
    area03Title: '《翻译名义集》',
    area03Content: '原典来自藏文丹珠尔中的典借《བྱེ་བྲག་ཏུ་རྟོགས་པར་བྱེད་པ།》（汉意：分别通达）。原典编纂自西元九世纪，是过去翻译佛经的主要参考书。到了十九世纪中叶传到西方，某些学者对原书的词汇加译汉文翻译，直到上世纪1950年代由日本学者荻原云来与榊亮三郞编辑印刷成书。',
    area04Title: '《御制五体清文鉴》',
    area04Content: '这本典借编于十八世纪，清干隆年间，这是一部满、藏、蒙、维、汉五种文字的分类词典。收词约一万八千余条，具有历史与实用价值。于1956年根据故宫重华宫的珍藏抄本重新勘印而成。',
    dicsIntro: '本网站为了搜寻方便，列出每个藏语词条的汉文翻，并列于网页词条下方，方便使用者阅读查询。',
    dicRangeA: '藏/藏词典类',
    bookNameA01: '《古藏文词典》',
    introA01: '古藏文词典，藏语，编者：赞拉·阿旺措成，中国北京民族出版社1992年出版，本词典收录近八千古藏文用词，具有参考及研究的价值。',
    bookNameA02: '《藏语新旧字辨异‧丁香宝帐》',
    introA02: '十五世纪时西藏译师觉顿蒙珠着。书中收有新旧词语一千多条，皆摘自藏文字形厘订前的部分佛书。',
    bookNameA03: '《东噶藏学大辞典》',
    introA03: '本词典主要收录了以下几个方面的藏学词汇：<br/>1)西藏历史人物与历史事件<br/>2)西藏历代中央王朝与西藏地方政府之间的关系<br/>3)西藏古代法律<br/>4)西藏地方政府机构及专用公文<br/>5)西藏ེ各地名胜古迹和重点寺庙;关于藏族民俗与宗教<br/>6)关于藏学基础知识等方面的词条。<br/>编选词汇丰富、内容解释详实、分类科学合理、便于检索使用，是一部较全 面的藏学百科性质的辞书。',
    bookNameA04: '《贡迪藏学大辞典》',
    introA04: '贡迪．拉仁巴编著<br/>色拉杰大知宝藏出版社于2016年出版<br/>色拉杰格西图滇桑竹是位贡迪拉仁巴，1958年生于西元藏，1959年抵达印度。<br/>1972年开始在色拉寺学习佛学经论，1993年获得拉仁巴学位，随后至上密院学习密续教理。毕业之后回到色拉寺担任敎师，这时也开始整理教法形成本部词典的基础，最终于2016年完成本部词典的编撰。',
    bookNameA05: '《数称辞释义大全》',
    introA05: '诺章·吴坚编撰，中国藏学出版社2008年出版，本词典收编三藏法数名相共一万余条，并列出出处，内容浩瀚，是佛学研究的好帮手。',
    bookNameA06: '《丹珠尔_翻译名义集梵藏对照词汇集》',
    introA06: '这部梵藏对照词汇集是依据德格版丹珠尔D4348整理而成，这部文献建立于公元九世纪赤松德赞时期，为规范梵藏佛经翻译而作，后收录于西藏丹珠尔中。因现今坊间出版的藏梵英中对照词典历经多方重编修订，已经与原典出入甚多，今由正法宝藏桑泊字典编辑组依据原经文重新对比校正后，放置于桑泊词典搜寻网站，期待提供更完整的研究参考。',
    bookNameA07: '《藏文大辞典》',
    introA07: '主编：昂仁罗桑丹达。<br/>编辑：索南巴尊<br/>出版日期：2010<br/>出版社：谢利文明出版社',
    dicRangeB: '藏/汉词典类',
    bookNameB01: '《藏汉大词典》',
    introB01: '藏语/汉语，于1984年由张怡荪主编，中国民族出版社发行。收录藏语词汇约五万三千余条，是近代使用较广的藏汉词典。本网站为了搜寻方便，列出每个藏语词条的汉文翻，并列于网页词条下方，方便使用者阅读查询。',
    bookNameB02: '《翻译名义集》（藏文版）',
    introB02: '藏语/梵语/英语/汉语，原典来自藏文丹珠尔中的典借《བྱེ་བྲག་ཏུ་རྟོགས་པར་བྱེད་པ།》（汉意：分别通达）。原典编纂自西元九世纪，是过去翻译佛经的主要参考书。到了十九世纪中叶传到西方，某些学者对原书的词汇加译汉文翻译，直到上世纪1950年代由日本学者荻原云来与榊亮三郞编辑印刷成书。',
    bookNameB03: '《藏汉常用合称词典》',
    introB03: '藏语/汉语，贺文宣、窦存琦编，青海民族出版社1987年出版。这是一部释译藏文典籍中所有专词的藏汉对照工具书。全书收录了古典藏文著作中包括政治、经济、文化、教育、文学、艺术、语言、文字、历史、宗教、医学、天文学等在内的词条共2113条。',
    bookNameB04: '《佛学词典》',
    introB04: '藏语/汉语，王沂暖主编，青海民族出版社出版，词条取自“藏汉大词典”、“青史”、“红史”、“土官宗教源流镜史”、“贤者喜筵”、“格西曲札藏文词典”、“印度佛教史”、“菩提道次第”、“密宗道次第”、“瑜珈师地论、“集论”及“具舍论”等论典中汇集而成，约一万二千个词条。本网站为了搜寻方便，列出每个藏语词条的汉文翻，并列于网页词条下方，方便使用者阅读查询。',
    bookNameB05: '《藏汉佛学词典》',
    introB05: '本词典由四川民族出版社于1992年由图登尼玛先生编撰而成。为藏汉佛学术语的对照词典。',
    bookNameB06: '《藏汉词典》',
    introB06: '本词典由西北民族学院藏文教研组于1979年由甘肃民族出版社出版。全书共计25400余词。',
    bookNameB07: '《梵藏汉对照词典》',
    introB07: '本词典编著为：安世兴先生，由民族出版社于1991年出版，主要依据「朵喀-次仁旺杰」所编的『常用藏梵词典—神奇宝珠项鍊』一书编撰而成。本词典的梵文主词条是以”藏文转写梵文对音词”的书写方式并对照各词条的藏文义译与汉文义译。共收录词条一万九千余。是一部提供藏学工作者学习、翻译和研究古藏文典籍的工具书。',
    bookNameB08: '《藏汉集论词汇》',
    introB08: '西陲文化院印<br/>张煦着<br/>张煦先生为二十世纪初人物（藏学家）,他将毗婆沙门集论（梵语：Mahāyānābhidharma-samuccaya）的藏文本与玄奘法师的汉译本对勘，以单字一义、数字一义及一句或数句者一义之藏文词条对比汉译本相应之词汇编而成，全书共列有4830余词。',
    bookNameB09: '《藏汉历算学词典》',
    introB09: '责任编辑：汤池安、央青拉姆。<br/>出版日期：1985<br/>出版社：四川民族出版社<br/>本书是阅读藏文历算典籍的辅助工具，其目的在于介紹藏历的传统知识，而不在于系统地介绍现代天文学知识。 例句完全出自藏文文献的原句。凡与现代天文学在原理、概念上相同或近似，只是名词术语不同之处，在汉文译文中简单地注明現代天文学形设，放在括弧里，不与传统的解释相混。原理概念不同之处本书不做评论。<br/>藏文注解主要是桑珠摄措写的,由黄明信补充并译为汉文，现代天文学的解释是陈久金撰写的。遗漏与错误一定不少,敬请读者指正。',
    dicRangeC: '汉藏词典类',
    bookNameC01: '《御制五体清文鉴》',
    introC01: '这本典借编于十八世纪，清干隆年间，这是一部满、藏、蒙、维、汉五种文字的分类词典。收词约一万八千余条，具有历史与实用价值。于1956年根据故宫重华宫的珍藏抄本重新勘印而成。',
    bookNameC02: '《汉藏对照词典》',
    introC02: '本词典收词八万余条，以现代汉语为主，词条除一般词语外，还收有一些常用的文言词语、成语、谚语、名言警句、方言词以及各学科的常用词汇。上述词语，相当一部分出自藏文的翻译出版物，译文具有一定的实践基础和群众基础。',
    bookNameC03: '《汉藏对照佛学词典》',
    introC03: '《汉藏对照佛学词典》由甘肃民族出版社出版，由马学仁先生主编，本词典词条主要从《具舍论》、《菩提道次第广论》、《青史》、《红史》、《巴协》、《土观宗教源流镜史》、《藏汉对照丹珠 尔佛学分类词典》、《藏传佛画度量经》、《甘青藏传佛教寺 院》、《藏汉大词典》、《格西曲扎藏文辞典》以及其他历史文 献、寺志和佛典等汉藏文资料中摘选出来。内容包括佛典、 教派、教法、教义、佛、菩萨、诸天、鬼神、高僧、仪轨、制度、节日、古迹、寺院等。由于藏传佛教吸收了不少原始苯教的内 容,因而本词典同时还收录了少量的苯教及相关宗教的基本词汇，共计二万三千余条。',
    dicRangeD: '藏英词典类',
    bookNameD01: 'The Combined English Dictionary',
    introD01: '这本词典是以藏汉大词典词条为基础，引用由Rangjung Yeshe, Richard Barron, Ives Waldo等人的解释并加上桑泊词典编辑小组对部分词条的解释汇编而成。',
    bookNameD02: 'Tibetan-English Dictionary of Buddhist Terminology',
    introD02: '这本词典是Tsepak Rigzin基于翻译名义大集编写而成，其包含了6,000个主词条及超过8,000个子词条。并在1986年由西藏档案文献馆出版。',
    bookNameD03: '《英藏汉对照词典》',
    introD03: '甘肃民族出版社于2005年首次出版《英藏汉对照词典》。该词典包含了将近4,000个词条，其主编为Yeshi Lamutso。',
    bookNameD04: '《藏语敬语 藏汉英对照手册》',
    introD04: '由多衮桑达多吉与丹津拉宗编撰，由西藏人民出版社出版。本词典收录了藏语常用口语及敬语的对照词汇并且加上英、汉语翻译以供使用者参考及应用。',
    dicRangeE: '汉文词典类',
    bookNameE01: '《丁福保佛学大词典》',
    introE01: '《丁福保佛学大词典》，是丁福保居士耗时八年编著而成的佛学词典，译自日本人织田德能的《佛学大词典》，是删除了日本佛教相关资料及文献出处之后所成的节译本。于1922年正式出版，收录条目三万多个，360多万字。',
    bookNameE02: '《一切经音义》',
    introE02: '又称《慧琳音义》，唐朝惠霖法师编撰，共100卷。是诸多佛经的音义集大成之作，由于释慧琳精通印度语言和汉语、音韵、训诂，故在为翻译佛经辨析字形、审定字音、解释字义时均备有详细引据。其可贵之处是所引用的书籍极为丰富，音韵以元庭坚的《韵英》、《韵诠》、张戬的《考声切韵》为准则，字义以《说文》、《尔雅》、《方言》、《释名》、《玉篇》、《文字集略》、《集训》、《古今正字》、《字统》、《文词典说》为准则，保存了《开元文字音义》等最古的传世词书，不少更是亡佚的古籍。无论从学术上还是从史料上看，都是非常有价值的宝藏。',
    bookNameE03: '《翻译名义集》(宋版)',
    introE03: '佛教词书，南宋平江（治所在今江苏苏州）景德寺僧法云编。7卷，64篇。系将散见于各经论中的梵文名字分类解释、编集而成，另有别行本在《阅藏知津》中作14卷，明代藏经作20卷。该书共收音译梵文2040余条。',
    bookNameE04: '《三藏法数》',
    introE04: '共五十卷。明代一如奉敕编纂。略称大明法数、三藏法数。本书系辑自大藏经典中诸法数之名词，依增数法类编撰而成。名目起自“一心”，终于“八万四千法门”计收词条一五五五条，每条并附有略解，凡出于某经某论或某某撰述者，皆详注于各条之下；若遇各家或各宗派说法不同，则征引诸家之异说，内容极为详备。民初黄理斋据该书另编通检一册，将一五五五条中所引之各类名词，一一析出，令初学者方便检阅。',
    bookNameE05: '慧琳《一切经音义》',
    introE05: '唐代比丘慧琳所著《一切经音义》（A.D. 807）是玄应所著《一切经音义》（A.D.649）的增补本。<br/>本书整合汇编了唐朝当时的所有经文词汇的音韵及意义，是「汉梵双语词典的原型」。<br/>此书也是佛经音义训诂的集成。',
    thanks: '感谢',
    thanksContent1: '《桑泊词典搜寻网站》在多年的努力下，承蒙各方的护持赞助，也包括编撰组义工们的文字输入、整理及校对，还有数位工程师们对词典编辑工具与网页的开发制作。现在免费提供给大众使用，在此致上诚敬的感谢！',
    thanksContent2: '',
    thanksContent3: '正法寳藏办公室  敬上',
  },
  login: {
    sign: '登入',
    register: '注册',
    account: '帐号',
    name: '名字',
    member: '会员',
    email: '电子邮件地址',
    password: '密码',
    confirmPsw: '确认密码',
  },
  result: {
    noResult: '无相关结果，请回上页或重新查询',
    noInputResult: '无相关结果，请重新输入关键字',
    orTryOtherWord: '或者您可试试以下其他词条',
    result: '搜寻结果',
    explanation: '解释',
    bookPage: '原书页码',
    pageNoF: '第',
    pageNoL: '页',
    entryNoF: '第',
    entryNoL: '个词条',
    partOfSpeech: '词性',
    expCategory: '类别',
    expSource: '解释来源',
    ps: '注解',
    combination: '组合字',
    word: '一般字',
    variant: '异体字',
    pinyin: '拼音',
    phonetic: '注音',
    note: '备注',
    originalImg: '原书图像',
  },
  pos: {
    pos1Aid: '成语',
    pos1Ano: '名词',
    pos1Ave: '动词',
    pos1Aad: '形容词',
    pos1Aadv: '副词',
    pos1Apr: '介词',
    pos1Aco: '连接词',
    pos1Apro: '代词',
    pos1Asy: '异词',
    pos1Aho: '敬语',
    pos1Asl: '俚语',
    pos1Adi: '方言',
    pos1Aques: '疑问句',
    pos1Aab: '缩写',
  },
  elements: {
    enterPage: '输入页码',
    comfirm: '确定',
    totalNumber: '总笔数',
    mainEntry: '主词条',
    transEntry: '关联搜寻',
    reset: '重设',
    copysuccess: '复制成功!',
    copyfailed: '无法复制内容，浏览器不支援。',
  },
  errorReport: {
    feedback: '问题回报',
    problem: '词条内容问题',
    unshownZh: '有些中文无法显示？',
    otherProblem: '其他问题',
    name: '姓名',
    contactWay: '联络方式',
    submitProblem: '请在此输入您想回报的问题',
    send: '传送',
    fixUnshownZh1: '如果您的网页无法显示罕见字，可',
    fixUnshownZh2: '点选此连结',
    fixUnshownZh3: '安装字体。',
    returnForm: '回到问题列表',
  },
  donate: {
    donateUs: '功德林',
    introTitle: '「桑泊佛学词典」数位化工作',
    introContent: '桑泊佛学电子词典：历时六年与化育汉藏佛学院义工，汇整20多部古今中外，藏、中、英佛学词典，经过分类及整理，其中有多部稀有珍贵的古籍词典(日后仍会陆续增加)，法友们可以在古今字词解析中，相互比较、对照、进而了解。<br/><br/>祈：法友在阅读经典时，遇到艰深字词与佛学名相，能够方便的随时随地搜寻查阅，进而了解佛经义理。<br/><br/>愿：大众阅读佛经时，深入经藏，得到佛的加持与利益。<br/><br/>～愿以此功德，普及于一切，我等与众生，皆共成佛道。',
    imgPS: '图为: 吞弥·桑布扎 大师,藏文的主要创制者,曾翻译许多佛经..',
    creditPay: '线上护持',
    aliPay: '支付宝护持',
    paypalPay: 'paypal护持',
    taiwanCurrency: '台币护持',
    foreignCurrency: '外币护持',
  },
  supportPage: {
    questions: '常见问题',
  },
};
