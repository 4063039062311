import Vue from 'vue';
import VueCookie from 'vue-cookies';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Element from 'element-ui';
import ElTreeSelect from 'el-tree-select';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import 'font-awesome/css/font-awesome.min.css';

Vue.use(Element);
Vue.use(ElTreeSelect);
Vue.use(VueCookie, VueAxios, axios);

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
