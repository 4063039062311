/* eslint-disable */
import qs from 'qs';
import axios from 'axios';

export default {
  errorReport(callback, reportName, reportContact, reportContent, entryID) {
    const url = '/api/errorReport';
    const url2 = '/api/categoryList';
    const avoidSqlInjection = (str) => {
      let newString = null;
      if (str !== null) {
        newString = str.replace(/[@]/ig, '＠').replace(/[(]/ig, '（').replace(/[)]/ig, '）').replace(/[?]/ig, '？').replace(/[&]/ig, '＆').replace(/[$]/ig, '＄').replace(/[!]/ig, '！')
          .replace(/[,]/ig, '，').replace(/[:]/ig, '：').replace(/[;]/ig, '；').replace(/[#]/ig, '＃')
          .replace(/[']/ig, '‘').replace(/["]/ig, '“')
          .replace(/[`^￥*%=|……/_——|\s]/ig, '');
      }
      return newString;
    };
    const name = avoidSqlInjection(reportName);
    const contact = avoidSqlInjection(reportContact);
    const content = avoidSqlInjection(reportContent);
    let reportID = null;
    if (entryID && entryID.length) {
      reportID = entryID.toString();
    }
    const requestBody = {
      id: reportID,
      content,
      contact,
      name,
      platform: 'web',
    };
    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    };
    axios.post(url2, qs.stringify(), config)
    .then((res) => {
      if (res.status === 200) {
        axios.post(url, qs.stringify(requestBody), config)
        .then((res) => {
          if (res.data.success === true) {
            callback(1);
          }
          if (res.data.success === false) {
            callback(-1);
          }
          if (res.code) {
            callback(res.code);
          }
        })
        .catch((err) => {
          console.log('folder list err but post', err);
        });
      } else {
        callback(-1);
      }
    })
    .catch((err) => {
      console.log('folder list err but post', err);
    });
  },
};
