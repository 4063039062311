<template>
  <div id="SearchBar">
    <el-autocomplete
      :placeholder="$t('search.keywordPls')"
      :trigger-on-focus="false"
      v-model="userInput"
      class="input-with-select"
      :fetch-suggestions="hintSearch"
      :select-when-unmatched="true"
      @select="hintSelected($event)"
      @compositionstart.native="isTyping(true)"
      @compositionend.native="isTyping(false)"
      ref="autocomplete"
    >
      <!-- <span slot="prepend" @click="drawer = true">
        <span>
          {{$t('search.catRange')}}
        </span>
        <i class="material-icons">more_vert</i>
        !-- <span v-if="!this.$store.state.category.nowCatLabels.length">
          全部分類範圍
        </span>
        <span v-if="this.$store.state.category.nowCatLabels.length">
          已選分類範圍
        </span> --
      </span> -->
      <i
        v-if="userInput !== ''"
        slot="suffix"
        @click="userInput = ''"
        class="el-input__icon el-icon-circle-close el-input__clear"
      >
      </i>
      <el-button class="searchBtn"
        @click="searchIt();closeSearchHint()"
        slot="append" icon="el-icon-search"
      >
      </el-button>
    </el-autocomplete>
    <el-drawer
      title=""
      :visible.sync="drawer"
      direction="btt"
      size="100%"
      :before-close="handleClose"
    >
    <div :key="refreshCat">
      <div v-if="treeParams.data">
        <CategoryRange
          popoverClass="fas"
          v-model="values"
          :selectParams="selectParams"
          :treeParams="treeParams"
          ref="treeSelect"
          @closeThisCatRange="closeCatRang"
        />
      </div>
    </div>
  </el-drawer>
  </div>
</template>

<script>
import searchAPI from '@/assets/api/search';
import CategoryRange from './CategoryRange.vue';

export default {
  name: 'SearchBar',
  components: {
    CategoryRange,
  },
  data() {
    return {
      typing: false,
      temptyping: '',
      apiEntryResult: 0,
      refreshCat: 0,
      treeParamsExist: false,
      entryList: null,
      drawer: false,
      keyword: '',
      userInput: this.$route.params.searchKWord || '',
      select: '',
      values: [],
      isNoResult: 0,
      selectParams: {
        clearable: true,
        placeholder: '',
      },
    };
  },
  computed: {
    treeParams() {
      return {
        clickParent: false,
        filterable: true,
        accordion: true,
        'check-strictly': false,
        'default-expand-all': true,
        'expand-on-click-node': false,
        'render-content': this.renderFun,
        data: this.$store.state.category.arrCatList,
        props: {
          children: 'children',
          label: 'name',
          path: 'path',
          disabled: 'disabled',
          value: 'path',
        },
      };
    },
    keyWordFiltered: {
      get() {
        return this.avoidSqlInjection(this.userInput);
      },
      set() {
      },
    },
  },
  mounted() {
  },
  watch: {
    userInput(val) {
      const str = this.avoidSqlInjection(val);
      this.keyWordFiltered = str;
    },
    isNoResult(val) {
      if (val >= 2) {
        this.$router.push({
          name: 'EntryNo',
          params: {
            searchKWord: this.keyWordFiltered,
          },
        }).catch((err) => err);
      }
    },
    typing(val) {
      if (val === true) {
        const dom = document.querySelectorAll('.el-autocomplete-suggestion__list > li');
        if (dom[0]) {
          const parent = dom[0].parentNode;
          parent.setAttribute('offsetTop', -10);
          parent.innerHTML = '';
        }
        if (dom && dom.length) {
          for (let i = 0; i < dom.length; i += 1) {
            dom[i].innerHTML = '';
          }
        }
      }
    },
  },
  methods: {
    testdown229(event) {
      console.log(event.keyCode);
    },
    changeStyle(status, className) {
      const dom = document.querySelectorAll(className);
      dom[0].style.display = status;
    },
    isTyping(val) {
      this.typing = val;
    },
    closeSearchHint() {
      this.$refs.autocomplete.activated = false;
    },
    hintSelected(event) {
      if (event.value) {
        this.searchIt();
      }
    },
    hintSearch(queryString, cb) {
      searchAPI.postGetHint((res) => {
        const hintArray = res.map((item) => ({ value: item }));
        cb(hintArray);
      }, this.keyWordFiltered, this.$store.state.dictionary.nowDicRange);
    },
    avoidSqlInjection(str) {
      let newString = '';
      if (str !== '') {
        newString = str.replace(/[`~!@#$^&?￥*%()=|{}:;,<>~！……/_（）——|【】'；："。，、？\s]/ig, '');
      }
      return newString;
    },
    searchIt() {
      this.isNoResult = 0;
      // change text (for yobi)
      // const changeBoText = this.keyWordFiltered.substr((this.keyWordFiltered.length - 1), 1);
      // if (changeBoText === '་') {
      //   this.userInput = `${this.keyWordFiltered.substr(0, (this.keyWordFiltered.length - 1))}།`;
      // }

      // if (this.keyWordFiltered === '') {
      //   this.$router.push({
      //     name: 'Home',
      //   }).catch((err) => err);
      //   return;
      // }
      // if (this.keyWordFiltered === '') {
      //   setTimeout(() => { alert('Hello'); }, 3000);
      // }
      searchAPI.postEntrySearch((res) => {
        if (res.list && res.list.length !== 0) {
          this.$emit('searchClicked', true);
          this.entryList = res;
          const entryFolder = res.list[0].folder;
          const entryText = res.list[0].text;
          this.apiEntryResult = 1;
          this.$router.push({
            name: 'Entry',
            params: {
              entryFolder,
              entryText,
              searchKWord: this.keyWordFiltered,
              apiEntryResult: 1,
            },
          }).catch((err) => err);
        } else {
          this.searchTrans();
          this.isNoResult += 1;
          this.apiEntryResult = 0;
        }
      }, 'Entry', this.keyWordFiltered, this.$store.state.dictionary.nowDicRange,
      this.$store.state.category.nowCatRange, this.$i18n.locale);

      // this.$emit('refresh');
    },
    searchTrans() {
      searchAPI.postEntrySearch((res) => {
        this.$emit('searchClicked', false);
        if (res.list && res.list.length !== 0) {
          this.entryList = res;
          const entryFolder = res.list[0].folder;
          const entryText = res.list[0].text;
          if (this.apiEntryResult === 0) {
            this.$router.push({
              name: 'Entry',
              params: {
                entryFolder,
                entryText,
                searchKWord: this.keyWordFiltered,
              },
            }).catch((err) => err);
          }
        } else {
          this.isNoResult += 1;
        }
      }, 'Translate', this.keyWordFiltered, this.$store.state.dictionary.nowDicRange,
      this.$store.state.category.nowCatRange, this.$i18n.locale);
    },
    handleClose(done) {
      // this.refreshCat += 1;
      done();
    },
    closeCatRang() {
      this.drawer = false;
      this.refreshCat += 1;
    },
  },
};
</script>

<style scoped lang="scss">
#SearchBar {
  text-align: left;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: $mobileLandscape) {
    width: 100%;
  }
  > .el-autocomplete {
    width: 100%;
    border: none;
    font-size: 1em;
    ::v-deep .el-input__inner {
      padding: 1.2em;
      font-size: 1.6em;
      font-weight: bolder;
      color: $mainTextColor02;
    }
    ::v-deep .el-input__suffix {
      line-height: 0px;
      i {
        padding-top: 0.3em;
        padding-right: 0.4em;
        font-size: 2em;
        height: 100%;
        color: $mainTextColor02;
      }
    }
    ::v-deep .el-input-group__prepend {
      border: none;
      background: $mainTextColor02;
      color: $mainBgColor;
      font-weight: bold;
      font-size: 1.4em;
      border: 1px solid $mainBgColor;
      span {
        span {
          @media screen and (max-width: $mobileLandscape) {
            display: none;
          }
        }
        i {
          display: none;
          @media screen and (max-width: $mobileLandscape) {
            display: unset;
          }
        }
      }
    }
    ::v-deep .el-input-group__append {
      background: $mainTextColor02;
      font-size: 1.4em;
      button.el-button {
        background: $mainTextColor02;
        color: $mainBgColor;
        font-weight: bold;
        border-radius: 0% 4px 4px 0%;
      }
    }
    span {
      cursor: pointer;
    }
    .el-scrollbar {
      background: purple;
    }
  }
  ::v-deep .el-drawer {
    // padding: 0em 2em;
    color: #000;
    width: 100%;
    min-height: 100%;
    overflow: scroll;
    padding: $wrapWidth;
    .el-drawer__header {
      .el-drawer__close-btn {
        i {
          font-size: 2em;
          font-weight: bolder;
        }
      }
    }
  }
}
</style>
